import { Component, OnInit } from '@angular/core';
import { LearnableSelectionService } from '../learnable-selection.service';

import { CategorySelector } from '../category-selector';


@Component({
  selector: 'category-selector-checkbox',
  templateUrl: './category-selector-checkbox.component.html',
  styleUrls: ['./category-selector-checkbox.component.scss']
})
export class CategorySelectorCheckboxComponent implements OnInit {
  allSelected: boolean = false;

	catList: CategorySelector[];

  constructor(
  	private learnableSelectionService: LearnableSelectionService) { }

  ngOnInit() {
  	this.getCategoryList();
  }

  getCategoryList(): void {
	this.learnableSelectionService.getCategorySelectionList()
    .subscribe(catList => {
        this.catList = catList;
    });
  }

  onSelectionChanged():void {
    this.learnableSelectionService.categorySelectionChanged();
  }

  toggleAllCategories() {
    this.allSelected = !this.allSelected;
    this.catList.forEach(catSelector => {
      if (catSelector.selected !== this.allSelected) {
        catSelector.selected = this.allSelected;
        this.onSelectionChanged();
      }
    });
  }

}
