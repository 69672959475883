import { UserProfile } from './../../data-model/user-profile';

import { Component, OnInit } from '@angular/core';
import { ServerService } from 'app/server.service';
import { AppConstants } from 'app-constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.scss']
})
export class UserProfileViewComponent implements OnInit {

    user: UserProfile;
    constructor(
      private serverService: ServerService,
      private titleService: Title
    ) { }

    get bypassCache(): boolean{
        return this.serverService.bypassCache;
    }

    set bypassCache(bypass: boolean) {
        this.serverService.bypassCache = bypass;
    }

    ngOnInit() {
        this.titleService.setTitle("Profile | " + AppConstants.appTitle)

        this.serverService.getUserProfile().subscribe(data =>{
            this.user = data;
        });
    }

}
