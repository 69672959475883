<div class="container">
    <div class="row">
        <div class="col-md-8">
            <h1> Edit your Profile </h1>
            <form #f="ngForm" (ngSubmit)="f.valid && updateProfile()" novalidate>

                <div class="row mt-2">
                  <div class="col">
                    <label for="email" class="font-weight-bold">Email</label>
                    <span 
                        class="disabled form-control" >{{user.email}}</span>
                    <!-- <div *ngIf="f.submitted && !username.valid" class="help-block">Email is required</div> -->
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col">
                    <label for="name" class="font-weight-bold">Name</label>
                    <input 
                        disabled
                        type="text" 
                        class="disabled form-control" 
                        name="name" 
                        [(ngModel)]="user.loginName" 
                        #name="ngModel" />
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col">
                    <button 
                        type="submit" 
                        [disabled]="!f.valid || waiting"
                        class="btn btn-sm btn-primary">
                        Save
                    </button>
                    <a [routerLink]="['../view']" class="btn btn-sm btn-danger ms-2">Cancel</a>
                  </div>
                </div>


                <div *ngIf="message" class="alert alert-secondary row mt-2">
                  <div class="col">{{message}}</div>
                </div>
                <div *ngIf="error" class="alert alert-danger row mt-2">
                  <div class="col">{{error}}</div>
                </div>
            </form>
        </div>
    </div>
</div>
