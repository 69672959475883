import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ServerService } from './server.service';

@Injectable()
export class AuthGuard  {

    constructor(private serverService: ServerService,
        private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const url: string = state.url;

        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        if (this.serverService.isLoggedIn()) {return true; }

  	    // store the attempted url for redirecting
        this.serverService.redirectUrl = url;

  	    // Navigate to the login page with extras
        this.router.navigate(['/login']);
        return false;
  }
}
