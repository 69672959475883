import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { AppConstants } from 'app-constants';
import { ServerService } from 'app/server.service';


@Component({
  selector: 'app-create-login-name',
  templateUrl: './create-login-name.component.html',
  styleUrl: './create-login-name.component.scss'
})
export class CreateLoginNameComponent implements OnInit{

  constructor(
    private server: ServerService,
    private router: Router
  ){}

  nameMinLength = AppConstants.nameMinLength;
  nameMaxLength = AppConstants.nameMaxLength;
  
  model:any = {}
  newLoginName: string;
  loading: boolean = false;
  error = '';

  ngOnInit() {}

  addLoginName(name) {
    this.loading = true;

    this.server.addLoginName(name)
    .subscribe(
      loginName => {
        this.newLoginName = loginName;
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
        this.error = "Something went wrong - contact support: " + error;
      }
    )    
  }

  returnToApp() {
    this.router.navigate(['/']);
  }

}
