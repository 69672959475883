import { ModSetSelection } from 'data-model/modset-selection';
import { Category } from '../data-model/category';


export class CategorySelector {
	selected: boolean;
	iconUrl:	string;
	id: 			string;
	name:			string;

	constructor(category: Category, modSetSelection?: ModSetSelection){
		this.id = category._id
		this.iconUrl = category.icon ? category.icon.url || "" : "";
		this.selected = modSetSelection && modSetSelection.getCategories().has(category._id);
        this.name = category.name;
	}
}