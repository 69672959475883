import { Title } from '@angular/platform-browser';
import { ServerService } from 'app/server.service';
import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'app-constants';
import { LoginComponent } from 'app/login/login.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    model: any          = {};
    loading             = false;
    error               = '';
    message: string;
    success: any        = '';
    passwordMinLength = AppConstants.passwordMinLength;
    passwordMaxLength = AppConstants.passwordMaxLength;
    nameMinLength = AppConstants.nameMinLength;
    nameMaxLength = AppConstants.nameMaxLength;
    confirmPassword: String = "";


    constructor(
        public serverService: ServerService,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.titleService.setTitle("Sign-Up | " + AppConstants.appTitle)
    }

    register(user){
        this.loading = true;
        this.error = '';
        this.message = 'Please Wait... Registering you';
        this.serverService.register(user)
        .subscribe((result: any) => {
          this.loading = false;
          this.message = ""
          this.success = {
            message: "Registration successful! Check your email to confirm your account and get started.",
            loginName: result.loginName,
          }
        },
        error => {
            this.loading = false;
            this.message = '';
            if (error.error && error.error.errorMessage){
                console.error(error.error.errorMessage,error);
                this.error = `An error occurred, please contact support: ${error.error.errorMessage}`;
            } else {
                console.error(error);
                this.error = `An error occurred, please contact support: ${error.message}`;
            }
           
        })
        
    }

}
