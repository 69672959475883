import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ServerService } from 'app/server.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppConstants } from 'app-constants';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {


    waiting: boolean = true;
    message: String = '';
    error: String = '';
    reSendButtonDisabled = true;

    constructor(
        private serverService: ServerService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.titleService.setTitle("Confirming | " + AppConstants.appTitle)
        // send token to server

        this.waiting = true;
        this.message = 'Checking with the Server.  Please wait...'
        this.reSendButtonDisabled = true;
        
        this.route.paramMap
        .pipe(
            map((params: ParamMap) => params.get('token'))
        ).subscribe(token => {
            this.serverService.confirmRegistration(token)
            .subscribe(successful => {
                if (successful){
                    //waiting = false
                    this.waiting = false;
                    
                    //message = success
                    this.message = 'Success! Please log into the App';


                } else {
                    // send a message to the user
                    //display a message on this screen with the option of requesting a re-send of the token
                    this.message = '';
                    this.error = 'Something is wrong with your Confirmation request.  Please try the link in your email again or click below to re-send the Confirmation Email';
                    this.reSendButtonDisabled = false;
                }
                
            }, error => {
                // Display an error on the screen with a button or link to direct to registration or Home pages
                this.waiting = false;
                this.message = '';
                this.error = error.toString();
            })
        });
    }

    login() {
      //redirect the session to the Main page as the user is now logged in
      this.router.navigate(['/login']) 
    }

}
