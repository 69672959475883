import { Grade } from "./grade";

export class Grading {
    description: String;    // short description of the grading ie: "Yellow Belt - Sept 29"
    date: Date              // date of the grading (last saved)
    grader: String;         // user ID of the grader
    student: String;        // user ID of the student being graded
    studentName: String;    // user Name of the Student being graded
    curriculum: String;     // curriculum ID of the curriculum this grading fits into
    result: String;         // Pass/Fail/Conditional Pass
    comments: String;       // grader's overall comments on the grading
    complete: boolean;      // Whether the grading is completed/final.  Complete gradings should not be editable
    shared: boolean;        // if the grading is shared with the student
    grades: Array<{         // Array of grades on specific learnables
        learnableId: String;// learnable ID of graded learnable
        results: Grade;     // grade entry for this learnable
    }>;
    _id: String;            // Unique ID set in the database for this Grading object
    
    constructor(
        description?: String, 
        date?: Date, 
        curriculum?: String, 
        grader?: String, 
        student?: String, 
        studentName?: String, 
        _id?: String)
        {

        this.description =  description ? description : "";
        this.date =         date ? date : null;
        this.curriculum =   curriculum? curriculum : "";
        this.grader =       grader ? grader : "";
        this.student =      student ? student : "";
        this.studentName =  studentName ? studentName : "";
        this.complete =     false;
        this.shared =       true;
        this.grades =       [];
        this._id =          _id ? _id : "";
 
    }
}
