<div *ngIf="!gradeableDisplayList">
    <div class="container">
        <div class="row">
            <h3 class="mt-3">
                Your Gradings
            </h3>
        </div>
        <p class="row mb-3">
            These are your gradings.  Review your In Progress grading to see how you're progressing. Review Completed gradings to 
            identify areas to continue to improve.
        </p>
        
        <div 
            *ngIf="!gradingList || gradingList.length ==0"
            class="mt-1 align-items-center">
            <div class="grading-list-entry row align-items-center border rounded bg-light text-danger">
                <b class="ms-auto me-auto">You have no online gradings</b>
            </div>

        </div>

        <div 
            *ngFor="let grading of gradingList; let i = index"
            class=" mt-1 align-items-center">
            <div
                class="grading-list-entry row align-items-center border rounded bg-light"
                (mouseover)="hover[i]=true" (mouseleave)="hover[i]=false"
                [class.hovering]="hover[i]"
                (click)="!!navigateToGradingById(gradingList[i]._id); hover[i]=false">
                <div class="col-6 me-auto">
                    <p class="my-2">
                        {{grading.studentName}} <br> 
                        <b>{{grading.description}}</b> 
                    </p>
                </div>
                <div class="col-3">
                    <em>
                        <p class="m-2">{{ grading.complete ? 'Complete' : 'In Progress' }}</p>
                    </em>
                </div>
                <div class="col-3">
                    <p class="my-2">{{ grading.date ? grading.date.toLocaleDateString() : '-' }}</p>
                </div>
    
            </div>

            <!-- <div class="col-3">

                <button class="btn btn-danger btn-sm float-right" (click)="!!deleteGrading(i)">
                    Del
                </button>
                <button class="btn btn-secondary btn-sm float-right" (click)="!!selectGrading(gradingList[i])">
                    Edit
                </button>
            </div> -->
        </div>
    </div>
</div>

<!-- Grading View -->


<div
    *ngIf="gradeableDisplayList">
    
    
    <div class="container pl-0 pr-0 sticky-top grading-header">    
        <div class="row">

            <div class="col-10 col-sm-11 mt-3">
                <div class="row">
                    <div class="col-4 col-md-3"><h5>Grading Report: </h5></div>

                    <div class="col-8 col-md-9"><h5>{{currentGrading.studentName}} <br> <b>{{currentGrading.description}}</b></h5></div>
                </div>
                <div class="row">
                    <div class="col-4 col-md-3"><span>Grading Status:</span></div>
                    <div class="col-8 col-md-9"><span><b><em>{{ currentGrading.complete ? "Complete" : "In Progress" }}</em></b></span></div>

                </div>
            </div>
            <div class="row"></div>
            <div class="col-2 col-sm-1 ms-auto">
                <button 
                    class="ms-2 me-2 btn btn-sm btn-primary" 
                    (click)="exitGrading()">
                    Exit
                </button>
            </div>
        </div>
        <div class="mt-3">
            <h5>Overall Grading Notes:</h5>
            <div class="row">
                <!-- <div class="col-sm-11 offset-sm-1"> -->
                    <!-- <textarea 
                        disabled
                        type="text" 
                        class="form-control form-control-sm"
                        rows="5"
                        cols="50"
                        placeholder="Comments on the overall grading"
                        [(ngModel)]="currentGrading.comments"></textarea>       -->
                        
                    <small
                        class="col-sm-10 offset-sm-1 border rounded"
                        style="white-space: pre-wrap; background-color: whitesmoke">{{currentGrading.comments || " "}}</small>
                <!-- </div> -->
            </div>
               
        </div>


        <div class="mt-3">
            <h5>Grading Details:</h5>
        </div>
        <div
            *ngFor='let module of gradeableDisplayList'>
            
            <div 
                *ngIf="module.length > 0" 
                class="row mb-1 p-1 pt-2 bg-secondary text-light sticky-top grading-divider" >
                <p class="col h5">{{module[0].module.name}}</p>
            </div>

            <div 
                *ngFor="let gradeable of module; let i = index;"
                class="row mb-1 border">
                
                <span class="col-2 align-self-center small">
                    {{gradeable.category.name}}
                </span>
                <!-- <div class="col-6 self-align-center">
                    <strong>
                        <div class="btn-group btn-group-toggle">
                            <label >
                                <input 
                                    type="checkbox" 
                                    disabled
                                    [(ngModel)]="gradeable.grade.complete">
                                <a target="_blank" 
                                    [routerLink]="['/learn']" 
                                    [queryParams]="{showExplore: true}" 
                                    [fragment]='"lid="+ gradeable.learnable._id'>
                                    {{gradeable.learnable.name}}
                                </a>
                            </label>
                        </div>
                    </strong>
                </div> -->
                <div
                    class="col-6 self-align-center">
                    
                    <i *ngIf="!gradeable.grade.complete" class="far fa-square"></i>
                    <i *ngIf="gradeable.grade.complete" class="fas fa-check-square"></i>
                    <strong>
                        <a  
                            [routerLink]="['/learn']" 
                            [queryParams]="{showExplore: true}" 
                            [fragment]='"lid="+ gradeable.learnable._id'>
                            {{gradeable.learnable.name}}
                        </a>
                    </strong>
                </div>

                <small
                    class="col-4 border rounded"
                    style="white-space: pre-wrap; background-color: whitesmoke;" >{{ 
                        (gradeable.grade.memory ? "Problem with Memory\n" : "") + 
                        (gradeable.grade.technique ? "Problem with Technique\n" : "") + 
                        (gradeable.grade.comments || "") 
                }}</small>

                <!-- <textarea 
                    disabled
                    type="text" 
                    class="col-4 form-control form-control-sm" 
                    rows="1"
                    placeholder="">{{ 
                        (gradeable.grade.memory ? "Problem with Memory\n" : "") + 
                        (gradeable.grade.technique ? "Problem with Technique\n" : "") + 
                        (gradeable.grade.comments || "") }}</textarea> -->
                
            </div>
        </div>
    </div>  
</div>