import { ModSet } from './mod-set';
import { Grade } from './grade';
import { Grading } from 'data-model/grading';
import { Gradeable } from "data-model/gradeable";
import { ReturnStatement } from '@angular/compiler';
import { Learnable } from './learnable';

export class GradingSession {
    gradeables: Gradeable[];
    grading: Grading;
    constructor(grading: Grading, modSet: ModSet) {
        this.grading = grading;
        this.gradeables = new Array<Gradeable>();
        if (grading) {
            grading.grades.forEach((grade) => {
                //get the learnable that matches the ID and load it into this.gradeables)
                let learnable = modSet.learnables.find(learnable => learnable._id == grade.learnableId);
                if (learnable === undefined) {
                    console.log("Problem processing graded Learnable: cannot find Learnable with id: " + grade.learnableId 
                        + "; Grade Object that caused the issue follows: ");
                    console.log(grade);
                    return;
                }
                let mod = modSet.modules.find(module => module._id == learnable.module);
                if (mod === undefined) {
                    console.log("Problem processing graded Learnable: cannot find Module with id: " + learnable.module
                        + "; Grade Object that caused the issue follows: ");
                    console.log(grade);
                    return;
                }
                let category = modSet.categories.find(category => category._id == learnable.category);
                if (category === undefined) {
                    console.log("Problem processing graded Learnable: cannot find Category with id: " + learnable.category 
                        + "; Grade Object that caused the issue follows: ");
                    console.log(grade);
                    return;
                }
                let gradeable = new Gradeable(grade.results, learnable, category, mod);
                this.gradeables.push(gradeable);
            });
        }
        this.sort(modSet);
    }
    /**
     * Sorts the GradingSession
     *
     * @param modSet the ModSet that includes ordered lists that will be used to sort the list
     */
    sort(modSet: ModSet) {
        this.gradeables.sort((a, b) => {
            //sort order needs to be by: Module then Category, then Learnable in order stored in ModSet)
            let aCatIndex = modSet.categories.findIndex(cat => a.learnable.category == cat._id);
            let bCatIndex = modSet.categories.findIndex(cat => b.learnable.category == cat._id);
            let aModIndex = modSet.modules.findIndex(mod => a.learnable.module == mod._id);
            let bModIndex = modSet.modules.findIndex(mod => b.learnable.module == mod._id);
            if (aModIndex == bModIndex) {
                //Modules are the same so sort by category
                if (aCatIndex == bCatIndex) {
                    //Categories are the same so sort by Learnable
                    return a.learnable.name.localeCompare(b.learnable.name);
                }
                else {
                    return aCatIndex - bCatIndex;
                }
            }
            else {
                return aModIndex - bModIndex;
            }
        });
        // this.gradeables.forEach((gradeable: Gradeable) => {
        //     console.log("Mod: " + gradeable.learnable.module + "; Cat: " + gradeable.learnable.category + "; Learnable: " + gradeable.learnable.name);
        // });
    }

    getDisplayList(): Array<Array<Gradeable>> {
        let batch: number = -1; // loop will increment this to 0 on first run
        let priorModule = null;
        let gradeableDisplayList = new Array<Array<Gradeable>>(); 
        if (this.gradeables){

            this.gradeables.forEach((gradeable: Gradeable) => {
                if (gradeable.module._id !== priorModule) {
                    batch++
                    gradeableDisplayList.push([]);
                }
                gradeableDisplayList[batch].push(gradeable);
                priorModule = gradeable.module._id;
            })
        } else {
            gradeableDisplayList = [];
        }
        return gradeableDisplayList

    }

}
