import { CategorySelector } from 'app/category-selector';
import { LevelSelector } from 'app/level-selector';
import { ModuleSelector } from 'app/module-selector';
import { CategorySelection } from './category-selection';
import { Learnable } from './learnable';
import { ModuleSelection } from './module-selection';

type Selectors = {
    moduleSelectors: Array<ModuleSelector>,
    categorySelectors?: Array<CategorySelector>        
}

export class ModSetSelection {

    private modules: Map<string, ModuleSelection>;
    
    private categories: Map<string, CategorySelection>;

    constructor(/* savedLevel?: any*/){
        // if (savedLevel && savedLevel.modules && savedLevel.categories){
        //     this.modules = new Map(Object.keys(savedLevel.modules).map(moduleId => [moduleId, savedLevel.modules[moduleId]]))
        //     this.categories = new Map(Object.keys(savedLevel.categories).map(catId => [catId, savedLevel.categories[catId]]))
        // } else {
            this.modules = new Map<string, ModuleSelection>();
            this.categories = new Map<string, CategorySelection>();
        // }
    }

    static fromJSON(json: any): ModSetSelection {
        let newMSS = new ModSetSelection();
        if (json.modules && json.modules.size > 0) {
            let newArray: Array<[string, ModuleSelection]> = Array.from(json.modules, ([key, data]) => 
                [key, new ModuleSelection(data._id, data.moduleID, data.levels)])
            newMSS.modules = new Map(newArray);
            // newMSS.modules = json.modules.map(module => 
            //     new ModuleSelection(module._id, module.moduleID, module.levels));
        }

        if (json.categories && json.categories.size > 0) {
            let newArray: Array<[string,  CategorySelection]> = Array.from(json.categories, ([key, data]) => 
                [key, new CategorySelection(data._id, data.name)])
            newMSS.categories = new Map(newArray);
            
            // newMSS.categories = json.categories.map(cat =>
            //     new CategorySelection(cat._id, cat.name));
        }
        
        return newMSS;
    }

    // static fromSelectors(selectors: Selectors ): ModSetSelection {
    //     var newModSetSelection: ModSetSelection = new ModSetSelection();

    //     selectors.moduleSelectors.forEach((modSelector: ModuleSelector) => {
    //         if (modSelector.selected){
    //             newModSetSelection.addModuleSelection(new ModuleSelection(
    //                 modSelector.id,
    //                 modSelector.name,
    //                 this.getLevelsListFromLevelSelectorList(modSelector.levels)
    //             ))

    //         }
    //     })

    //     if (selectors.categorySelectors) {
    //         selectors.categorySelectors.forEach((catSelector: CategorySelector) => {
    //             if (catSelector.selected){
    //                 newModSetSelection.addCategorySelection(new CategorySelection(
    //                     catSelector.id,
    //                     catSelector.name
    //                 ))
    //             }
    //         })
    //     }

    //     return newModSetSelection;
    // }

    static newModSetSelectorFromModuleSelections(moduleSelectionList: Array<ModuleSelector>): ModSetSelection{
        var newModSetSelection: ModSetSelection = new ModSetSelection();

        moduleSelectionList.forEach((modSelector: ModuleSelector) => {
            if (modSelector.selected){
                newModSetSelection.addModuleSelection(new ModuleSelection(
                    modSelector.id,
                    modSelector.name,
                    this.getLevelsListFromLevelSelectorList(modSelector.levels)
                ))

            }
        })
        
        return newModSetSelection;
    }

    private static getLevelsListFromLevelSelectorList(levels: Array<LevelSelector>): Array<string>{
        return levels.reduce(
            (list: string[], levelSelector: LevelSelector) => {
                if (levelSelector.selected) {
                    list.push(levelSelector.name)
                } 
                return list;
            },[]
        )
    }

    setModuleSelection(moduleSelectors: Array<ModuleSelector>): void {
        this.modules = new Map<string, ModuleSelection>();

        moduleSelectors.forEach((modSelector: ModuleSelector) => {
            if (modSelector.selected){
                this.addModuleSelection(new ModuleSelection(
                    modSelector.id,
                    modSelector.name,
                    ModSetSelection.getLevelsListFromLevelSelectorList(modSelector.levels)
                ))

            }
        })
    }

    setCategorySelection(categorySelectors: Array<CategorySelector>): void {
        this.categories = new Map<string, CategorySelection>();

        categorySelectors.forEach((catSelector: CategorySelector) => {
            if (catSelector.selected){
                this.addCategorySelection(new CategorySelection(
                    catSelector.id,
                    catSelector.name
                ))
            }
        })
    }


    getModules(): Map<string, ModuleSelection>{
        return this.modules;
    }

    getCategories(): Map<string, CategorySelection>{
        return this.categories;
    }

    addModuleSelection(modSelection: ModuleSelection) {
        this.modules.set(modSelection._id, modSelection);
    }

    addCategorySelection(catSelection: CategorySelection) {
        this.categories.set(catSelection._id, catSelection);
    }

    isLearnableInSelection(learnable: Learnable): boolean{
        // Check if the given learnable fits the current selection (module, level and category) 
        if (this.modules.has(learnable.module)){
            let module = this.modules.get(learnable.module);
            if (module.levels.includes(learnable.level) && this.categories.has(learnable.category)){
                return true;
            }
        }
        
        return false;
    }

    serialize() {
        let serialized = {
            modules: {},
            categories: {}
        }
        this.modules.forEach((module, modId: string) => {
            serialized.modules[modId] = module;
        })
        
        this.categories.forEach((category, catId: string) => {
            serialized.categories[catId] = category;
        })
        return serialized;
    }

    static deserialize(serializedSelection) {
        let newModsetSelection = new ModSetSelection();
        newModsetSelection.modules = 
            new Map<string, ModuleSelection>(
                Object.keys(serializedSelection.modules).map(modId => [modId, serializedSelection.modules[modId]])
            )
        newModsetSelection.categories = 
            new Map<string, CategorySelection>(
                Object.keys(serializedSelection.categories).map(catId => [catId, serializedSelection.categories[catId]])
            )
        return newModsetSelection;
    }
}