import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModSetSelection } from 'data-model/modset-selection';
import { ServerService } from './server.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MyLevelSelectionResolverService {

    constructor(
        private server: ServerService
    ) { }

    resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ModSetSelection> {
        return this.server.getUserLevel()
    }

}
