<!-- TOP Navbar -->
<nav id="topNavBar" class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/"> TrainingTool </a>
    
        <ul *ngIf="canInstall()"class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link text-warning" 
                    (click)="showInstallModal(installPwaModal)" 
                    style="cursor: pointer;">
                    Install
                </a>
            </li>
        </ul>

        <ul *ngIf="isUpdateAvailable()" class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link text-warning" 
                    (click)="updateApp()" 
                    style="cursor: pointer;">
                    Update
                </a>
            </li>
        </ul>
    
    <button 
        class="navbar-toggler" 
        type="button" 
        data-toggle="collapse" 
        data-target="#navbarSupportedContent" 
        aria-controls="navbarSupportedContent" 
        aria-expanded="false"
        [attr.aria-expanded]="!isNavbarCollapsed" 
        aria-label="Toggle navigation"
        (click)="isNavbarCollapsed = !isNavbarCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>
    
    <div 
        class="collapse navbar-collapse" 
        id="navbarSupportedContent"
        [ngbCollapse]="isNavbarCollapsed">
        
        <ul class="navbar-nav me-auto">
            <!-- <li 
                *ngIf="!isLoggedIn()" 
                class="nav-item" routerLinkActive="active">
                <a 
                    class="nav-link" 
                    (click)="isNavbarCollapsed = true" 
                    [routerLink]="['/learn']" 
                    [queryParams]="{initData: true}">
                    Learn
                </a>
            </li> -->
            <li 
                *ngIf="isLoggedIn() && modSet"
                class="nav-item" routerLinkActive="active" ngbDropdown 
                #myDrop="ngbDropdown"
                >
                <a ngbDropdownAnchor class="nav-link" id="dropdownManual" (click)="$event.stopPropagation(); myDrop.toggle()">
                    Learn
                </a>
                <ul
                    class="dropdown-menu multi-column columns-3"
                    ngbDropdownMenu 
                    aria-labelledby="dropdownManual">
    
                    <div 
                        (mouseleave)="$event.stopPropagation(); myDrop.close()"
                        class="row">
                        <div class="col-sm-4">
                            <ul class="multi-column-dropdown">
                                <li><h6>Learn by Belt</h6></li>
                                <span *ngFor="let module of modSet.modules">
                                    <li 
                                        *ngIf="module.authorized">
                                        <a  class="learn-link"
                                            [routerLink]="['/learn']"
                                            [queryParams]="{requestedMods:[module._id], showExplore: false, initData: true}"
                                            (click)="myDrop.close(); isNavbarCollapsed = true">
                                            {{module.name}}
                                        </a>
                                    </li>  
                                </span>                              
                            </ul>
                        </div>
                        <div class="col-sm-4">
                            <ul class="multi-column-dropdown divided-column">
                                <h6>Learn by Category</h6>
                                <span *ngFor="let category of modSet.categories">
                                    <li
                                        *ngIf="category.authorized">
                                        <a class="learn-link"
                                            [routerLink]="['/learn']"
                                            [queryParams]="{requestedCats:[category._id], showExplore: false, initData: true}"
                                            (click)="myDrop.close(); isNavbarCollapsed = true">
                                            {{category.name}}
                                        </a>
                                    </li>
                                </span>
                                
                            </ul>
                        </div> 
                        <div class="col-sm-4">
                            <ul class="multi-column-dropdown divided-column">
                                <h6>Explore</h6>

                                <li>
                                  <a class="learn-link"
                                    [routerLink]="['/learn']"
                                    [queryParams]="{requestedCats:[], requestedMods:[], showExplore: true, initData: true}"
                                    (click)="myDrop.close(); isNavbarCollapsed = true">
                                    Explore All
                                  </a>
                                </li>
                                <li><a class="learn-link"
                                    [routerLink]="['/learn']"
                                    [queryParams]="{selectMyLevel:true, initData: true}"

                                    (click)="myDrop.close(); isNavbarCollapsed = true">
                                    Explore My Level
                                </a></li>    

                                
                            </ul>
                        </div>    
                    </div>
                </ul>
            </li>
            <li 
                *ngIf="isLoggedIn()"
                class="nav-item" 
                routerLinkActive="active">
                <a class="nav-link" (click)="isNavbarCollapsed = true" [routerLink]="['/test']" >
                    Test
                </a>
            </li>
            <li 
                *ngIf="isLoggedIn() && isGrader()"
                class="nav-item" 
                routerLinkActive="active">
                <a class="nav-link" (click)="isNavbarCollapsed = true"  [routerLink]="['/grade']">
                    Grade
                </a>
            </li>
            <li 
                *ngIf="isLoggedIn()"
                class="nav-item" 
                routerLinkActive="active">
                <a class="nav-link" (click)="isNavbarCollapsed = true" [routerLink]="['/gradings']">
                    My Gradings
                </a>
            </li>
        </ul>
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="https://docs.google.com/forms/d/e/1FAIpQLSdTBvq412iHl-A9PocX7aZiX1fmbgDi0j2YhhynXM6pVCe4tw/viewform?usp=sf_link" (click)="isNavbarCollapsed = true" target="_blank">Feedback</a></li>
            <!-- User is Not Logged In -->
            <li *ngIf="!isLoggedIn()" class="nav-item"><a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/register">Sign-Up</a></li>
            <li *ngIf="!isLoggedIn()" class="nav-item"><a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/login">Log In</a></li>
            
            <!-- User is Logged In -->
            <li *ngIf="isLoggedIn()" class="nav-item"><a class="nav-link" (click)="isNavbarCollapsed = true; logout()" routerLink="/login">Log Out</a></li>
            <li *ngIf="isLoggedIn()" class="nav-item"><a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/profile/view">Profile</a></li>
            
            <ng-template #cacheModal let-modal>
                <div class="modal-body">
                    <div>
                        <p class="col text-center">{{cacheMessage}}</p>
                    </div>
                    <div>
                        <span>Getting: <img [src]="imageCacheUrl" height="5" width="5"> {{fileCacheName}}</span>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-auto">
                            <button type="button" class="btn btn-sm btn-outline-dark" (click)="cacheAllFiles()" [disabled]="loadingContent">Download</button>
                            <button type="button" class="btn btn-sm btn-outline-dark" (click)="modal.close()" [disabled]="loadingContent">Close</button>
                            <button type="button" class="btn btn-sm btn-outline-dark" (click)="imageCacheUrl = imageTestUrl" >Cache One Image</button>
                            
                        </div>
                    </div>
                </div>
            </ng-template>
        </ul>
    </div>
  </div>
</nav>

<!-- BODY Content -->
<div class="main-body container-fluid">
	<router-outlet></router-outlet>
    
</div>
<updates-notification></updates-notification>
<ng-template #installPwaModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title col text-center">Install the app</h4>
    </div>
    <div class="modal-body">
        <div>
            <p>Install to add an App icon on your homepage or app list</p>
        </div>
        <div *ngIf="installPwaData.os=='android'">
            <p>To install, click the Install button below to install on your Android device</p>
        </div>
        <div *ngIf="installPwaData.os=='ios'">
            <p>To install, tap the Menu button and then 'Add to Home screen' to install on your iOS device</p>
            <div class="col text-center">
                <img class="mx-3" style="display: inline;" src="./assets/ios-menu-btn.png"> 
                <i class="fas fa-arrow-right"></i> 
                <img class="mx-3" style="display: inline;" src="./assets/ios-add-btn.png">
            </div>
        </div>

    </div>
    <div class="modal-body">
        <div class="row justify-content-end">
            <div class="col-6">
                <button 
                    *ngIf="installPwaData.os=='android'" 
                    type="button" 
                    class="btn btn-sm btn-outline-primary " 
                    (click)="installPwa(); modal.close()">
                        Install
                    </button>
            </div>
            <div class="col-6">
                <button 
                    type="button" 
                    class="btn btn-sm btn-outline-danger float-right" 
                    (click)="modal.close()">
                        Close
                    </button>
                
            </div>
        </div>
    </div>
</ng-template>