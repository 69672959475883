import { Module } from '../data-model/module';
import { LevelSelector } from './level-selector';
import { ModSetSelection } from 'data-model/modset-selection';

export class ModuleSelector {
	selected: 	boolean;
	levels: 		LevelSelector[];
	iconUrl:		string;
	name:				string;
	id:					string;

	constructor(module: Module, modSetSelection?: ModSetSelection) {
    let selectedModules = modSetSelection ? modSetSelection.getModules() : null;

    this.selected = modSetSelection && selectedModules.has(module._id) ? true : false;
    this.iconUrl = module.icon && module.icon.url ? module.icon.url : '';
    this.name = module.name;
    this.id = module._id;
    this.levels = [];
    
    module.levels.forEach((level,index) => {
      let selected = 
        modSetSelection && 
        selectedModules.has(module._id) && 
        selectedModules.get(module._id).levels.indexOf(level) >= 0 ? true : false
      this.levels.push({
        name: level,
        selected: selected,
        sortOrder: index
      })
    })
	}
}