/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppConstants as constants } from 'app-constants';
import { AppModule } from './app/app.module';

if (constants.production) {
  enableProdMode();
}



platformBrowserDynamic().bootstrapModule(AppModule);
