import { ModSetSelection } from 'data-model/modset-selection';
import { TestConfiguration } from './test-configuration';
import { ModSet } from './mod-set';
import { TestResult } from './test-result';
import { TestQuestion } from './test-question';

export class TestSession {

    private _testResult: TestResult;
    private _modSet: ModSet;

    static generateCandidates(modSetSelection: ModSetSelection, modSet: ModSet): Array<TestQuestion> {
        let modules = modSetSelection.getModules
        
        let candidates: Array<TestQuestion> = []
        modSet.learnables.forEach(learnable => {
            if (learnable.questionList.length > 0 && modSetSelection.isLearnableInSelection(learnable)) {
                //add question list to candidates
                learnable.questionList.forEach(question => {
                    candidates.push( new TestQuestion(question, learnable._id, []))
                })
            }
        })
        return []
    }

    constructor(testConfig: TestConfiguration, modSet: ModSet, candidateQuestions?: Array<TestQuestion>) {
        this._modSet = modSet;
        
        let candidates: Array<TestQuestion> = candidateQuestions || TestSession.generateCandidates(testConfig.getModSetSelection(), modSet);
        
        let questions = this.selectQuestionsFromCandidates(testConfig.getNumQuestions(), candidates)

        this._testResult = new TestResult(testConfig, questions)
    }

    getTestResults(): TestResult {
        return this._testResult;
    }
    
    getQuestionList(): TestQuestion[] {
        return this._testResult.getQuestionList();
    }

    selectQuestionsFromCandidates(numQuestions: number, candidateQuestions: TestQuestion[]): Array<TestQuestion> {
        
        let numQuestionsToSelect = Math.min(numQuestions, candidateQuestions.length)
        
        let questions: Array<TestQuestion> = []

        while (questions.length < numQuestionsToSelect) {
            questions.push(this.extractRandomQuestion(candidateQuestions))
        }

        return questions
    }

    extractRandomQuestion(candidates: Array<TestQuestion>): TestQuestion {
        let index = Math.floor(Math.random() * (candidates.length - 1));
        return candidates.splice(index, 1)[0]
    }

    endTestSession() {
        this._testResult.completeTest()
    }

}