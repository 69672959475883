<div class="row mb-2">
	<div class="col"><h5 class="mb-0">Belt Levels</h5><small>(Pick all that apply)</small></div>
	<div class="col-auto container align-self-center">
        <button 
            class="btn btn-sm" 
            [class.btn-secondary]="!allSelected" 
            [class.btn-primary]="allSelected" 
            (click)="toggleAllModules()">
            All
        </button>
    </div>
</div>

<div class="checklist">
    <div 
        *ngFor="let mod of modList"
        class="border-top">
        <div class="row">
            <div class="col align-self-center">
                <h6 class="mb-0">{{mod.name}}</h6>
            </div>
            <div class="col-auto">
                <button 
                    class="btn btn-sm py-0"
                    [class.btn-secondary]="!mod.selected" 
                    [class.btn-primary]="mod.selected"
                    (click)="mod.selected = !mod.selected; onModuleSelectionChanged(mod,true)">                   
                    All
                </button>
            </div>
        </div>

        <div
            *ngFor="let level of mod.levels"
            class="custom-control custom-checkbox">

            <input 
                [(ngModel)]="level.selected"
                (change)="onLevelSelectionChanged(mod,$event);"
                type="checkbox" class="custom-control-input" id="levelCheckbox-{{mod.name}}-{{level.name}}">
            <label class="custom-control-label" for="levelCheckbox-{{mod.name}}-{{level.name}}"><small>{{level.name}}</small></label>
        </div>
    </div>
</div>

