import { MainComponent } from '../main/main.component';
import { Component, OnInit } from '@angular/core';
import { TestConfiguration } from '../../data-model/test-configuration';
import { TestResult } from '../../data-model/test-result';
import { TestService } from '../services/test.service'

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
    providers: [TestService]
})
export class TestComponent implements OnInit {

    initialized = false;

    constructor(private testService: TestService) { }

    ngOnInit(): void {
        this.testService.initialize().subscribe(() => {
            this.initialized = true;
        })
    }

    landingVisible(): boolean {
        return this.initialized &&
            !this.testService.hasTestResultsSelected() 
            && !this.testService.hasNewTestConfig()
            && !this.testService.hasActiveTestConfig()
            && !this.testService.hasStudyLearnableSelected();
    }

    testResultsVisible(): boolean {
        return this.initialized &&
            this.testService.hasTestResultsSelected() &&
            ! this.studyLearnableVisible()
    }

    newTestVisible(): boolean {
        return this.initialized &&
            this.testService.hasNewTestConfig()
    }

    testSessionActive(): boolean {
        return this.initialized &&
            this.testService.hasActiveTestConfig();
    }

    studyLearnableVisible(): boolean {
        return this.initialized &&
            this.testService.hasStudyLearnableSelected();
    }


}
