<div class="container">
    <div class="row">
        <div class="col-md-8">
            <h1> Your Profile </h1>
    
            <div class="row mt-2">
              <div class="col">
                <label for="email" class="font-weight-bold">Email</label>
                <span class="form-control disabled"  name="email">{{user.email}}</span>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <label for="loginNale" class="font-weight-bold">Login Name</label>
                <span class="form-control disabled"  name="loginName">{{user.loginName}}</span>
              </div>
            </div>
            <!-- <div class="row mt-2">
              <div class="col">
                <a 
                    [routerLink]="['../edit']"
                    class="btn btn-sm btn-primary">
                    Edit
                </a>
              </div>
            </div> -->
            <div class="row mt-2">
              <div class="col">
                <div class="checkbox mt-4">
                  <label>
                    <input
                      type="checkbox"
                      [checked]="bypassCache"
                      (change)="bypassCache = !bypassCache"/>
                    Bypass ModSet Cache (Advanced - use only if told to)
                  </label>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

