<div class="container">
    <div class="row">
        <div class="col-sm-8">
            <h1> Please sign in </h1>

            <form #f="ngForm" [hidden]="emailLogin" (ngSubmit)="f.valid && login(null, f.value.loginName, f.value.password)" novalidate>

              <div class="row mt-2">
                <div class="alert alert-primary">
                  <span>
                    Use your <b>Login Name</b> to sign in here.  <b>If you need to log in with an email address, <a class="link" (click)="emailLogin = !emailLogin">switch to email sign-in</a></b>
                  </span>
                </div>
              </div>
              <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !loginName.valid }">
                <div class="col">
                  <label for="loginName">Login Name (firstname dot last initial like "john.s")</label>
                  <input type="text" class="form-control" name="loginName" [(ngModel)]="model.loginName" #loginName="ngModel" required />
                  <div *ngIf="f.submitted && !loginName.valid" class="help-block">Login Name is required</div>
                </div>
              </div>
                  
              <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                <div class="col-12">
                  <label for="password">Password</label>
                  <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
                  <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
                </div>
              </div>

              <div class="row mt-2">
                  <div class="col-3">
                    <button [disabled]="loading" class="btn btn-primary">Login</button>
                  </div>
                  <div class="col-3">
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                  <div class="col-6 text-end">
                    <a [routerLink]="['/forgot']">Forgot Password?</a>
                  </div>
              </div>

              <div class="row mt-2">
                <p>Still using email to login? <a class="link" (click)="emailLogin = !emailLogin">Switch to email sign-in</a></p>
              </div>

              <div class="row mt-2">
                  <p>Need an account? <a routerLink="/register">Sign-up here</a></p>
              </div>
              
              <div *ngIf="message" class="alert alert-secondary">{{message}}</div>
              <div *ngIf="error" class="alert alert-danger">{{error}}</div>
          </form>


            <form #e="ngForm" [hidden]="!emailLogin" (ngSubmit)="e.valid && login(e.value.username, null, e.value.password)" novalidate>

              <div class="row mt-2">
                <div class="alert alert-danger">
                  <span>
                    Email logins will be removed soon.  After you login you will need to create a Login Name which will replace emails when you login.
                  </span>
                </div>
              </div>

                <div class="row mt-2" [ngClass]="{ 'has-error': e.submitted && !username.valid }">
                  <div class="col">
                    <label for="username">Email</label>
                    <input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required />
                    <div *ngIf="e.submitted && !username.valid" class="help-block">Username is required</div>
                  </div>
                </div>
                    
                <div class="row mt-2" [ngClass]="{ 'has-error': e.submitted && !password.valid }">
                  <div class="col-12">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
                    <div *ngIf="e.submitted && !password.valid" class="help-block">Password is required</div>
                  </div>
                </div>

                <div class="row mt-2">
                    <div class="col-3">
                      <button [disabled]="loading" class="btn btn-primary">Login</button>
                    </div>
                    <div class="col-3">
                      <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                    <div class="col-6 text-end">
                      <a [routerLink]="['/forgot']">Forgot Password?</a>
                    </div>
                </div>
                <div class="row mt-2">
                  <p>Do you have a Login Name (like "john.s")? <a class="link" (click)="emailLogin = !emailLogin">Switch to Login Name sign-in</a></p>
                </div>
                <div class="row mt-2">
                    <p>Need an account? <a routerLink="/register">Sign-up here</a></p>
                </div>
                
                <div *ngIf="message" class="alert alert-secondary">{{message}}</div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
        </div>
    </div>
</div>