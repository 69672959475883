import { Component, OnInit } from '@angular/core';
import { LearnableSelectionService } from '../learnable-selection.service';

import { LearnableSelector } from 'app/learnable-selector';
import { CategoryLearnableSelector } from '../category-learnable-selector';
import { Router } from '@angular/router';

@Component({
  selector: 'learnable-selector-grouped',
  templateUrl: './learnable-selector-grouped.component.html',
  styleUrls: ['./learnable-selector-grouped.component.scss']
})
export class LearnableSelectorGroupedComponent implements OnInit {
  learnableList: LearnableSelector[];
  categoryLearnableList: CategoryLearnableSelector[];

  constructor(
      private learnableSelectionService: LearnableSelectionService,
      private router: Router) { }

  ngOnInit() {
      // this.getLearnableList();
      this.getCategoryLearnableList();
  }

    // getLearnableList(): void {
	// 	this.learnableSelectionService.getLearnableSelectionList()
    //     .subscribe(learnableList => {
    //         this.learnableList = learnableList;
    //     });
    // }

    getCategoryLearnableList(): void {
        this.learnableSelectionService.getCategoryLearnableSelectionList()
        .subscribe(categoryLearnableList => {
            this.categoryLearnableList = Object.keys(categoryLearnableList).map(key => categoryLearnableList[key]);
        })
    }

  onLearnableSelected(learnable: LearnableSelector):void {
    this.router.navigate(['/learn'],{fragment:"lid="+learnable.id, queryParamsHandling: 'preserve'});
  	//this.learnableSelectionService.selectLearnable(learnable.id);
  }

    isEmptyObject(obj) {
        return (obj && (Object.keys(obj).length === 0));
    }

}
