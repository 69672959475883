import { ServerService } from './server.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class GradeGuardService  {
    
  constructor(
      private serverService: ServerService,
      private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
        if ( this.serverService.isGrader()) return true;

        this.router.navigate(['/']);
        return false;
  }
}
