import { ScheduleItem } from './../../data-model/schedule-item';
import { ScheduleDataService } from './../schedule-data.service';
import { Component, OnInit } from '@angular/core';
import { ServerService } from '../server.service';
import { Title } from '@angular/platform-browser';
import { AppConstants as constants } from 'app-constants';


@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    schedule: Array<Array<ScheduleItem>> = []
    weekday: number = 0;
    version: string = "0.0";
    envName: String = "";
    envIsProd: boolean;
    curriculumName:string = constants.curriculumName;
    appTitle: string = constants.appTitle;



    constructor(
        public serverService: ServerService,
        public scheduleService: ScheduleDataService,
        public titleService: Title) { 
        }

    ngOnInit() {
        this.version = constants.version;
        this.envName = constants.envName;
        this.envIsProd = constants.production;
        
        this.titleService.setTitle(constants.appTitle)
        this.scheduleService.getSchedule().subscribe(scheduleData =>{
          this.schedule = scheduleData;
        })
        
        this.weekday = new Date().getDay()
    }

    isLoggedIn(): boolean{
        return this.serverService.isLoggedIn();
    }

    logout() {
        this.serverService.logout();
    }

    decreaseWeekday(){
        this.weekday = (this.weekday? this.weekday : 7) - 1;
        console.log(this.weekday)
    }

    increaseWeekday(){
        this.weekday = (this.weekday + 1) % 7 
        console.log(this.weekday)
    }


}
