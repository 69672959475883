import { TestService } from './../services/test.service';
import { Component, OnInit } from '@angular/core';
import { TestResult } from 'data-model/test-result';
import { TestConfiguration } from 'data-model/test-configuration';
import { TestQuestion } from 'data-model/test-question';
import { Question } from 'data-model/question';
import { Answer } from 'data-model/answer';

@Component({
    selector: 'test-landing',
    templateUrl: './test-landing.component.html',
    styleUrls: ['./test-landing.component.scss']
})
export class TestLandingComponent implements OnInit {
    
    // TODO: Load and persist values from/to storage (sample values below for development)
    resultsList: Array<TestResult> = 
    [
        // new TestResult(new TestConfiguration(null, 10), []),
        // Object.assign(new TestResult(), {
        //     questions: [{
        //         question: new Question("what is the question",[new Answer("1a2b3c","what is the answer",true)]),
        //         learnableId: "abc123",
        //         answerIdsSelected: ["123abc"]
        //     }],
        //     configuration: {
        //         _modSetSelection: {
        //             modules: new Map(), 
        //             categories: new Map()
        //         },
        //         numQuestions: 10
        //     },
        //     dateCompleted: Date.now()
        // })  
    ]

    constructor(private testService: TestService) { }

    ngOnInit(): void {
    }

    getTestResultsList() {
        let resultsList = this.testService.getTestResultsList();
        // resultsList.forEach((result: TestResult) => {
        //     console.log("Categories loaded: ", result.getTestConfiguration().getModSetSelection().getCategories())
        // })
        return resultsList;
    }

    selectTestResult(testResult: TestResult) {
        this.testService.selectTestResults(testResult)
    }

    startNewTest(): void {
        this.testService.startNewTest();
    }

    formatDateCompleted(result: TestResult): string {
        let date: Date = result.getDateCompleted();
        return date.toDateString();
    }

    formatPercentCompleted(result: TestResult): string {
        return Math.round(result.getPercentCorrect()).toString()
    }

}
