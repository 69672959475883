export class ModuleSelection {
    "_id": string;
    "moduleID": string; // include this mostly for debugging to know which module is included by name
    "levels": Array<string>;

    constructor(_id: string, moduleID: string, levels: Array<string>) {
        this._id = _id;
        this.moduleID = moduleID;
        this.levels = levels;
    }
}
