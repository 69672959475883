import { Learnable } from 'data-model/learnable';
import { forEachChild } from 'typescript';
import { Answer } from './answer';
import { Question } from './question';

export class TestQuestion {
    question: Question;
    learnableId: String;
    answerIdsSelected: Array<String>;

    constructor(question: Question, learnableId: String, answerIdsSelected: Array<String>) {
        this.question = question;
        this.learnableId = learnableId;
        this.answerIdsSelected = answerIdsSelected;
    }

    static fromJSON(json: any): TestQuestion {
        let newQuestion = Question.fromJSON(json.question);
        let newTQ = new TestQuestion(newQuestion, json.learnableId, json.answerIdsSelected)
        return newTQ;
    }

    getQuestionText(): String {
        return this.question.question;
    }

    hasQuestionImage(): Boolean {
        let image = this.question.image;
        return image !== undefined && image !== null 
            && image.url !== null && image.url.length > 0;
    }

    getQuestionImageUrl(): String {
        return this.question.image.url;
    }

    getAnswerList(): Array<Answer> {
        return this.question.answerList;
    }

    getNumCorrectAnswers(): number {
        return this.question.answerList.reduce((count, answer) => {
            return count + (answer.correct ? 1 : 0)
        }, 0)
    }

    isAnsweredCorrectly(): boolean {
        // if the right number of answers is not selected, return false
        if (this.answerIdsSelected.length !== this.getNumCorrectAnswers()){
            return false;
        }

        // if a correct answer is not selected, return false
        return this.getAnswerList().reduce((result, answer) => {
           if (! result){
               return false;
           }

            if (answer.correct) {
                 return this.answerIdsSelected.includes(answer._id)
            }
            
            // nothing has invalidated to answer so it is correct
            return true;
        }, true)

    }

    putAnswerIdsSelected(answers: Array<String>): void{
        this.answerIdsSelected = answers;
    }
}
