import { Directive, Input } from '@angular/core';

@Directive({
  selector: 'img[default]',
  host: {
    '(error)':'updateUrl()',
    '[src]':'src'
   }
})
export class ImagePreloadDirective {
    @Input() src:string;
    @Input() default:string;
  
    updateUrl() {
        console.log("Image load failed for src=" + this.src + " Replacing with default=" + this.default)
        this.src = this.default;
    }

}
