import { Title } from '@angular/platform-browser';
import { ServerService } from 'app/server.service';
import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppConstants } from 'app-constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    waiting: boolean = false;

    constructor(
        public serverService: ServerService,
        private titleService: Title
        ) { }

    error               = '';
    message: string;
    email: string;

    ngOnInit() {
        this.titleService.setTitle("Forgot Password | " + AppConstants.appTitle)
    }

    requestReset(email: String){
        this.waiting = true;
        this.error = '';
        this.message = '';
        this.serverService.requestPasswordReset(email)
        .pipe(
            catchError((error) => {
                let message = 'Unknown Error';
                this.waiting = false;

                if (error.error instanceof ErrorEvent){
                    // A Client Side or Network error occurred
                    this.error = 'Sorry, something is broken on our end.  Please try again later or contact support.';
                    console.error("Error processing Password Reset: ",error);
                } else if (error.error.errorCode && error.error.errorMessage){
                    // Error Message generated by the app so display it
                    this.error = error.error.errorMessage;
                    console.error("API Error processing Password Reset: "+ error.error.errorCode +': '+ error.error.errorMessage)
                } else if (error.status == 404){
                    this.error = 'Email not found'
                } else {
                    // The backend returned an unsuccessful response code.
                    // The response body may contain clues as to what went wrong,

                    this.error = 'Sorry, something is broken on our end.  Please try again later or contact support.';
                    console.error("Error processing Password Reset: ",error);
                }
                
                return of(false)

                
            })
        )
        .subscribe(successful => {
            if (successful){
                this.message = "An email has been sent to "+email+" with instructions for resetting your password"
            }
            this.waiting = false;
        })
    }

}
