import { AppConstants as constants } from 'app-constants';
import { UserProfile } from 'data-model/user-profile';
import { throwError as observableThrowError,  Observable ,  of, ReplaySubject, throwError, config, pipe, Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap, map, catchError, take } from 'rxjs/operators';

// import { Http, Response, ResponseType, RequestOptions, Headers } from '@angular/http';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { ModSet } from 'data-model/mod-set';
import { ModSetSelection } from 'data-model/modset-selection';
import { Module } from 'data-model/module';
import { Learnable } from 'data-model/learnable';
import { Category } from 'data-model/category';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ScheduleData } from 'data-model/schedule-data';
import { Grading } from 'data-model/grading';
import { Activity } from 'data-model/activity';


const BYPASS_CACHE_KEY: string = "bypassCache"

export interface AuthData{
    newJwt: String;
}

@Injectable()
export class ServerService  {
    private saveCacheSetting(bypassCache: boolean) {
        localStorage.setItem(BYPASS_CACHE_KEY, JSON.stringify(bypassCache))
    }

    private loadCacheSetting(): boolean {
        let bcValue: boolean = false;
        var bcString = localStorage.getItem(BYPASS_CACHE_KEY);
        if (bcString !== null) {
            bcValue = JSON.parse(bcString)
        }
        return bcValue
    }

    // Subject to notify services when cacheing is enabled or disabled
    private _bypassCache$ = new BehaviorSubject<boolean>(this.loadCacheSetting());

    observeBypassCache(): Observable<boolean> {
        return this._bypassCache$.asObservable()
    }

    get bypassCache(): boolean {
        return this._bypassCache$.value;
    }

    set bypassCache(bypass: boolean) {
        this._bypassCache$.next(bypass);
        this.saveCacheSetting(bypass);
    }

    private backendUrl = constants.apiUrl;
    private curriculumId = constants.curriculum;

    // place to store a redirect URL so that we can redirect after login
    redirectUrl = '';

    // current JSON Web Token.  if blank, then no current session
    jwt: String = '';
    jwtDecoded;

    userLevelSelection: ModSetSelection;
    userLevelSelectionSubject: ReplaySubject<ModSetSelection>;
    loginStateSubject: ReplaySubject<boolean>;

    instructorGradingListSubject: ReplaySubject<Array<Grading>>;
    studentGradingListSubject: ReplaySubject<Array<Grading>>;

    constructor(
        private httpClient: HttpClient,
        public jwtHelper: JwtHelperService) {

        this.userLevelSelectionSubject = new ReplaySubject<ModSetSelection>(1);
        this.userLevelSelection = new ModSetSelection();
        this.loginStateSubject = new ReplaySubject<boolean>(1)
        this.loginStateSubject.next(false);
        this.instructorGradingListSubject = new ReplaySubject<Array<Grading>>(1);
        this.studentGradingListSubject = new ReplaySubject<Array<Grading>>(1);

        if (this.isLoggedIn()){
            this.processAuthenticationData({newJwt: localStorage.getItem('access_token')});

        }
        console.log('Server Service has been initialized');
    }

    isLoggedIn(): boolean {
        // if the jwt is set and non-blank and valid then return true
        let token = localStorage.getItem('access_token');

        if (token === null) return false;

        if (this.jwtHelper.isTokenExpired(token)){
            //clean up expired token
            localStorage.removeItem('access_token');
            return false;
        } else {
            return true;
        }
    }

    isUserAdmin(): boolean {
         // if the jwt is set and non-blank and contains an appropriate authorization then return true
         if (!this.isLoggedIn()) return false;

         // a token must exist for loggedIn to be true.
         let token = localStorage.getItem('access_token');
         
         let decoded = this.decodeJwt(token);
         
         //allow access if the user is an App Admin or a Curriculum Admin for the current Curriculum
         return decoded.authorizations && 
             (
                 decoded.authorizations.appAdmin || 
                 decoded.authorizations.admin == constants.curriculum
             )
    }

    decodeJwt(token){
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    }

    login(email: String, loginName: String, userPassword: String): Observable<boolean> {

        const requestBody = {
            userPassword: userPassword
        };

        if (loginName){
          requestBody['loginName'] = loginName;
        } else if (email){
          requestBody['email'] = email;
        } else {
          // error: no login credentials provided.
          return of(false)
        }


        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // 'User-Agent': 'TrainingToolWebApp'
            })
        }

        let processAuthCallback: {(AuthData): boolean}= this.processAuthenticationData.bind(this);

        return this.httpClient.post<AuthData>(this.backendUrl + '/authentications', requestBody, opts)
        .pipe(
            map(processAuthCallback),
            catchError(this.catchHttpError)
        );
    }

    sendActivity(activity: Activity): Observable<Activity> {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }
        return this.httpClient.post(this.backendUrl + '/activities', activity, opts)
        .pipe(
            map(() => activity)
        );
    }

    processAuthenticationData(authData: AuthData): boolean {
        this.jwt = authData.newJwt;
        this.jwtDecoded = this.decodeJwt(this.jwt);
        localStorage.setItem('access_token',this.jwt.toString());

        // get User Level from server after getting a successful JwT
        this.updateUserLevel();
        
        this.loginStateSubject.next(true);
        

        return this.isLoggedIn();
    }

    updateUserLevel(callback?){
        if (callback) callback = callback.bind(this);

        // added the take(1) clause because this should be a single action
        this.getUserLevel()
        .pipe(take(1))
        .subscribe(newLevel => {
            // if an invalid modSetSelection is returned then return a new empty ModSetSelection
            this.userLevelSelection = newLevel;
            this.userLevelSelectionSubject.next(this.userLevelSelection);
            if (callback) callback();
        }, err => {
            // if we still have an error, then there's no user level selection avaialble.
            this.userLevelSelection = undefined;
            this.userLevelSelectionSubject.next(this.userLevelSelection);
            if (callback) callback();
        })
    }

    logout(): void {
        this.jwt = '';
        this.jwtDecoded = {};
        localStorage.removeItem('access_token');
        this.loginStateSubject.next(false);
    }


    // if bypassCache is true then this request bypasses all caches
    getModSet(bypassCache?: boolean): Observable<ModSet> {

        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp'//,
                //'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        if (bypassCache || this.bypassCache) {
            opts.headers = opts.headers.append("ngsw-bypass", "true");
        }

        return this.httpClient.get<ModSet>(this.backendUrl + '/curricula/' + this.curriculumId + '/modsets', opts)
        .pipe(
            map(this.cleanModSet()),
            catchError(this.catchHttpError)
        );
    }



    cleanModSet(){
      let jwtDecoded = this.jwtDecoded;
      let curriculumId = this.curriculumId;

      function sortByCategories(a,b) {
        //if sort Orders are both populated, use them, otherwise return the order they were passed in
        if (typeof a.sortOrder == 'number' && typeof b.sortOrder == 'number'){
          return a.sortOrder - b.sortOrder
        } else {
          return 0 //leave a and b unchanged in respect to each other.
        }
      }
  
      function sortModSet(modSet: ModSet): ModSet{
        modSet.categories.sort(sortByCategories);
        return modSet;
      }

      return function (newModSet: ModSet): ModSet {

        sortModSet(newModSet);

        let authorizations = [];

        let haveCurriculum = jwtDecoded 
          && jwtDecoded.curriculumAuthorized 
          && jwtDecoded.curriculumAuthorized[curriculumId]
          && jwtDecoded.curriculumAuthorized[curriculumId].length > 0;

        if (haveCurriculum){
          authorizations = jwtDecoded.curriculumAuthorized[curriculumId].reduce(
            (newList, moduleId) => {
              newList[moduleId] = true;
              return newList;
            },
            {}
          )

        }

        // Created for efficiency so we only iterate over the module and category lists once and use 
        //  these mappings to help update category authorized state
        var tempModList = {};
        var categoryCounters = {};

        //fix blank levels list for easier display
        newModSet.modules.forEach((module: Module) => {
            if (module.levels.length === 0){
                module.levels = ['Other'];
            }
            module.authorized = authorizations[module.moduleID] ? true : false;
            tempModList[module._id] = module;
        });

        newModSet.learnables.forEach((learnable: Learnable) => {
            //fix blank learnable Level by setting it to "Other"
            learnable.level = (learnable.level === '' || learnable.level === undefined) ? 'Other' : learnable.level;
            
            // update the category counter with this learnable
            categoryCounters[learnable.category] 
                = (categoryCounters[learnable.category] ? categoryCounters[learnable.category] : 0 )
                + (tempModList[learnable.module].authorized ? 1 : 0);
        });

        // Populate categories with an additional field "authorized".
        //  A category is authorized if all the learnables for it are in modules marked as authorized
        newModSet.categories.forEach((category: Category) => {
            category.authorized = (categoryCounters[category._id] && categoryCounters[category._id] > 0);
        })

        return newModSet;
      }
    }

    // returns the userId from the JWT if a decoded JWT exists.  Throws error if userId is not present in JWT, or no decoded JWT Exists
    getUserId(): String {
        if (this.jwtDecoded && this.jwtDecoded.userID){
            return this.jwtDecoded.userID;
        } else {
            throw new Error("JWT is not avilable or did not contain userID")
        }
    }

    getUserLevel(): Observable<ModSetSelection>{
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp'//,
                //'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        return this.httpClient.get(this.backendUrl + '/users/' + this.getUserId() + '?myLevel=1', opts)
        .pipe(
            //tap(data=>console.log("Received myLevel from server:",data)),
            map((data: any) => {
                if (data.myLevel && data.myLevel[this.curriculumId]){
                    if (data.myLevel[this.curriculumId] instanceof ModSetSelection) {
                        return Object.assign(new ModSetSelection(), data.myLevel[this.curriculumId])
                    } else {
                        return ModSetSelection.deserialize(data.myLevel[this.curriculumId]);
                    }
                }   else {
                    return new ModSetSelection();
                }
                //return data.myLevel ? Object.assign(new ModSetSelection, data.myLevel[this.curriculumId]) : new ModSetSelection();
            }),
            catchError(this.handleError('getUserLevel', new ModSetSelection()))
        );
    }

    getUserLevelObservable(){

        return this.userLevelSelectionSubject.asObservable();
    }


    putUserLevel(newSelection: ModSetSelection){
        //console.log("Server Service putting UserLevel")
        this.userLevelSelection = newSelection;

        let payload = {
            myLevel:{
            }
        }
        payload.myLevel[this.curriculumId] = newSelection.serialize();

        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp'//,
                //'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        let targetUrl = this.backendUrl + '/users/' + this.getUserId() + "?_method=PUT";

        return this.httpClient.post<ModSetSelection>(targetUrl, payload, opts)
        .pipe(
             
            tap((data)=>{
                //console.log("results of myLevel Put operation",data)
                
                //emit updated value now that it is successfully stored on the server
                this.userLevelSelectionSubject.next(this.userLevelSelection);
            }),
            catchError(this.catchHttpError)
        );
    }

    getUserProfile(): Observable<UserProfile> {
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp'//,
                //'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }
        var urlToGet = this.backendUrl + '/users/' + this.getUserId() + "?loginName=1&email=1";
        return this.httpClient.get<UserProfile>(urlToGet, opts)
        .pipe(
            catchError(this.catchHttpError)
        );
    }

    putUserProfile(newProfile: UserProfile){
        let payload = newProfile;

        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp'//,
                //'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        let targetUrl = this.backendUrl + '/users/' + this.getUserId() + "?_method=PUT";

        return this.httpClient.post(targetUrl, payload, opts)
        .pipe(
            catchError(this.catchHttpError)
        );


        
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(`${operation} failed: ${error.message}`); // log to console instead
        
            // // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);
        
            // Let the app keep running by returning an empty result.
            return of(result as T);
          };
    }

    //utility method to make HTTP Error handling more DRY
    catchHttpError(errorResponse: HttpErrorResponse){
        let message = 'Unknown Error';

        if (errorResponse.error instanceof ErrorEvent){
            // A Client Side or Network error occurred
            message = 'Sorry, something is broken on our end.  Please try again later or contact support.';
            console.error(errorResponse.error);
        } else if (errorResponse.error && (errorResponse.error.errorCode && errorResponse.error.errorMessage)){
            // Error Message generated by the app so display it
            message = errorResponse.error.errorMessage;
            console.error('Error from Api: '+ errorResponse.error.errorCode +': '+ errorResponse.error.errorMessage)
            console.error(errorResponse.error)
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            message = 'Sorry, something is broken on our end.  Please try again later or contact support.';
            console.error(errorResponse);            
        }
        return observableThrowError(message);
    }

    loginStateObservable(): Observable<boolean> {
        return this.loginStateSubject.asObservable();
    }

    requestPasswordReset(email: String): any {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // 'User-Agent': 'TrainingToolWebApp'
            })
        }

        let resetBody = {
            email: email,
            resetUrl: location.origin
        }

        return this.httpClient.post(this.backendUrl + '/users/forgot', resetBody, opts)
        .pipe(
            map((data: any) => true)
        );
    }


    verifyResetToken(token: String): Observable<{email: string, loginName: string}> {
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp'
            })
        }
        // send the token to the API and expect back an object with user options.
        return this.httpClient.get<{email: string, loginName: string}>(
          this.backendUrl + '/users/reset/'+token, opts
        )
    }

    setPassword(token: string, newPassword: any): Observable<Object> {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // 'User-Agent': 'TrainingToolWebApp'
            })
        }

        let resetBody = {
            password: newPassword
        }

        return this.httpClient.post(this.backendUrl + '/users/reset/' + token, resetBody, opts)
        .pipe(
            map((data: any) => true)
        );
    }

    register(user: {user: String, password: String, name: String}): Observable<Object> {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // 'User-Agent': 'TrainingToolWebApp'
            })
        }

        let body = {
            user: user,
            confirmUrl: location.origin + '/confirm',
            curriculumId: this.curriculumId
        }

        return this.httpClient.post(this.backendUrl + '/users/register/', body, opts);
    }

    /**
     * Takes a Registration token as input and confirms it with the server.  if successful, log in the user  
     * @param {String} token the user's provided confirmation token
     * @returns {Observable<boolean>} Observable of the logged-in status of the user
     */
    confirmRegistration(token: String) {
        const opts = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // 'User-Agent': 'TrainingToolWebApp'
            })
        }

        return this.httpClient.get<AuthData>(this.backendUrl + '/users/confirm/' + token, opts)
        .pipe(
            map(() => {
                return true
            }),
            catchError(error => {
                if (error.error instanceof ErrorEvent){
                    //Client or network error.  throw error back to requester with more friendly message
                    console.error("confirmRegistration request failed with an error", error.error);
                    return throwError("Sorry, something is broken on our end.  Please try again later or contact support.")
                } else if (error.status == 404){
                    // request rejected, so return failure 
                    return of(false)
                } else if (error.status >= 400) {
                    //server failure or request failure
                    console.error("confirmRegistration request failed with status code "+ error.status, error.error);
                    return throwError("Sorry, something is broken on our end.  Please try again later or contact support.")
                } else {
                    // some other unexpected error type occurred so handle it and return a user-friendly message
                    //Client or network error.  throw error back to requester with more friendly message
                    console.error("confirmRegistration request failed with an error", error.error);
                    return throwError("Sorry, something is broken on our end.  Please try again later or contact support.")
                }
                                
            })
        );
        
    }

    getSchedule(): Observable<ScheduleData[][]> {
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp'//,
                'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        //if (! this.jwt) return throwError("Error: Cannot request ModSet - no session");
        return this.httpClient.get<ScheduleData[][]>(this.backendUrl + '/curricula/' + this.curriculumId + '/scheduleData', opts)
        .pipe(
            catchError(this.catchHttpError)
        );

    }

    isGrader(): boolean{
        // if the jwt is set and non-blank and contains an appropriate authorization then return true
        if (!this.isLoggedIn()) return false;

        // a token must exist for loggedIn to be true.
        let token = localStorage.getItem('access_token');
        
        let decoded = this.decodeJwt(token);
        
        //TODO: Change this to use an "Instructor" authorization on the curriculum instead.
        
        //allow access if the user is an App Admin or a Curriculum Admin for the current Curriculum
        return decoded.authorizations && 
            (
                decoded.authorizations.appAdmin || 
                decoded.authorizations.admin == constants.curriculum ||
                decoded.authorizations.adminList && decoded.authorizations.adminList.includes(constants.curriculum) ||
                (
                    decoded.authorizations.instructor && 
                    decoded.authorizations.instructor instanceof Array &&
                    decoded.authorizations.instructor.includes(constants.curriculum)
                )

            );
    }

    getGradingListSubjectForInstructor(): Observable<Array<Grading>>{  
        return this.instructorGradingListSubject.asObservable();
    }

    /**
     * gets gradings for which the current user is the Instructor
     */
    updateGradingListForInstructor() {
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp',
                // 'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        this.httpClient.get<any[]>(this.backendUrl + '/curricula/' + this.curriculumId + '/gradings/?grader=' + this.getUserId(), opts)

        .subscribe((gradingList: Grading[]) => {

            
            //parse the date string as a date object
            gradingList.forEach(grading => {
                let newDate = new Date(grading.date);
                grading.date = newDate;
            
            })
            
            this.instructorGradingListSubject.next(gradingList)
        },this.catchHttpError);
        

    }

    getGradingListSubjectForStudent(): Observable<Array<Grading>>{  
        return this.studentGradingListSubject.asObservable();
    }

    /**
     * gets gradings for which the current user is the Instructor
     */
    updateGradingListForStudent() {
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp',
                // 'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        this.httpClient.get<any[]>(this.backendUrl + '/curricula/' + this.curriculumId + '/gradings/?student=' + this.getUserId(), opts)

        .subscribe((gradingList: Grading[]) => {

            
            //parse the date string as a date object
            gradingList.forEach(grading => {
                let newDate = new Date(grading.date);
                grading.date = newDate;
            
            })
            
            this.studentGradingListSubject.next(gradingList)
        },this.catchHttpError);
        

    }

    deleteGrading(gradingId: String) {
        
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp',
                // 'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        return this.httpClient.delete<any[]>(this.backendUrl + '/curricula/' + this.curriculumId + '/gradings/' + gradingId + '/?_method=DELETE', opts)
        .pipe(
            tap(() => {
                this.updateGradingListForInstructor();
            }),
            catchError(this.catchHttpError)
        );
        

    }

    saveGrading(grading: Grading){
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp',
                // 'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        // If grading has an _id then it is an update, otherwise it is an insert
        let saveObservable = grading._id
            ? this.httpClient.put(this.backendUrl + '/curricula/' + this.curriculumId + '/gradings/' + grading._id + '/?_method=PUT', grading, opts)
                .pipe(
                    map(() => {
                        this.updateGradingListForInstructor();
                        // console.log("** ServerService.saveGrading() Put grading update: ", grading)
                        return grading
                    }),
                    catchError(this.catchHttpError)
                )
            : this.httpClient.post(this.backendUrl + '/curricula/' + this.curriculumId + '/gradings/', {grading: grading}, opts)
                .pipe(
                    map((savedGrading: Grading) => {
                        this.updateGradingListForInstructor();
                        return savedGrading;
                    }),
                    catchError(this.catchHttpError)
                );
        
        return saveObservable
    }
   
    getUserList(activeOnly: boolean = false): Observable<Array<any>> {
        const opts = {
            headers: new HttpHeaders({
                // 'User-Agent': 'TrainingToolWebApp',
                // 'Authorization': 'Bearer ' + (localStorage.getItem('access_token') || '')
            })
        }

        let url = this.backendUrl + '/users/?curriculumId=' + this.curriculumId;

        if (activeOnly) {
            url += "&activeOnly=true";
        }

        return this.httpClient.get<any[]>(url, opts)
        .pipe(
            map(userList => {
                return userList.map(user => {
                    return {
                        id: user._id,
                        name: user.name,
                        email: user.email
                    }
                })
            }),
            catchError(this.catchHttpError)
        );
    }

    getFileList(): Observable<any> {
        return this.httpClient.get<any[]>(this.backendUrl + '/files')
        .pipe( catchError(this.catchHttpError))
    }

    getFile(file: any): Observable<any> {
        let url = file.url;
            return this.httpClient.get(url, {responseType: 'blob'})
            .pipe(
                catchError(this.catchHttpError)
            );
        return of({})

    }
  
    addLoginName(name: {fName: string, lName: string}): Observable<string> {
      const opts = {
        headers: new HttpHeaders({
            // 'User-Agent': 'TrainingToolWebApp',
          'Authorization': `Bearer ${(localStorage.getItem('access_token') || '')}`
        })
      }
      
      return this.httpClient.put<{loginName: string}>(`${this.backendUrl}/users/${this.getUserId()}/addloginname?_method=PUT`, name, opts)
      .pipe(
        map(result => {
          return result.loginName
        }),
        catchError(this.catchHttpError)
      )
    }

}
