import { TestQuestion } from './test-question';
import { TestConfiguration } from './test-configuration';

export class TestResult {

    questions: Array<TestQuestion>;
    configuration: TestConfiguration;
    dateCompleted: Date = null;

    constructor(configuration?: TestConfiguration, questions?: Array<TestQuestion>){
        this.configuration = configuration || new TestConfiguration();
        this.questions = questions || [];
        
    }

    static fromJSON(json): TestResult {
        let newConfiguration = TestConfiguration.fromJSON(json.configuration);
        let newQuestions = json.questions.map(question => TestQuestion.fromJSON(question));
        let newTR = new TestResult(newConfiguration, newQuestions);
        newTR.dateCompleted = new Date(json.dateCompleted);
        return newTR;

    }

    completeTest(): void {
        this.dateCompleted = new Date();
    }

    getQuestionList(): Array<TestQuestion> {
        return this.questions;
    }

    getDateCompleted(): Date {
        return this.dateCompleted;
    }

    getNumQuestions(): number {
        return this.questions.length;
    }

    getNumCorrect() {
        return this.questions.reduce((count, question: TestQuestion) => {
            count += question.isAnsweredCorrectly() ? 1 : 0 
            return count;
        }, 0)
    }

    getPercentCorrect(): number {
        return this.getNumCorrect() / this.getNumQuestions() * 100;
    }

    getIncorrectQuestionList(): Array<TestQuestion> {
        return this.questions.reduce((incorrect, question) => {
            if (!question.isAnsweredCorrectly()){
                incorrect.push(question)
            } 
            return incorrect;
        }, [])
    }

    getTestConfiguration(): TestConfiguration{
        return this.configuration;
    }
}
