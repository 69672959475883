
<div class="container bg-light mt-2 border rounded mb-2">
    <h3 class="text-center">Previous 5 Test Results</h3>
    
    <div *ngIf="getTestResultsList().length < 1"
        class="mx-md-5 bg-white pl-0 border rounded mb-2">
        <p class="text-center mx-auto my-auto text-muted">No test results to show</p>
    </div>

    <ul *ngIf="getTestResultsList().length > 0"
        class="mx-md-5 bg-white pl-0 border rounded mb-2">
        <li *ngFor="let result of getTestResultsList()"
            class="row my-1 mx-1">
            <span class="col-6 text-left px-0">{{formatDateCompleted(result)}}</span> 
            <span class="col-3 text-center px-0"><b>{{formatPercentCompleted(result)}}%</b>  </span>
            <span class="col-3 text-right px-0">
                <button
                    class="btn btn-sm btn-primary"
                    (click)="selectTestResult(result)">
                    View
                </button>
            </span>
                    
        </li>
    </ul>
</div>

<!-- Mobile "New Test" button -->
<div
    class="newtest-button-mobile d-sm-none min-vw-100 fixed-bottom p-2">
    <button
        class=" btn btn-success  btn-block"
        (click)="startNewTest()">
        Start a New Test
    </button>
</div>


<!-- Desktop "New Test" button -->
<button
    class="btn btn-success mx-auto d-none d-sm-block"
    (click)="startNewTest()">
    Start a New Test
</button>
