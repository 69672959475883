import { ServerService } from 'app/server.service';
import { Activity } from './../../data-model/activity';
import { Injectable } from '@angular/core';
import { catchError, toArray } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { concat, Observable, of } from 'rxjs';

const ACTIVITY_QUEUE_KEY: string = "ActivityQueue"

@Injectable({
  providedIn: 'root'
})
export class ActivityService {    
    localStorage: Storage;

    constructor(private serverService: ServerService) { 
        this.localStorage = window.localStorage;
    }

    private sendActivity(activity): Observable<Activity> {
        return this.serverService.sendActivity(activity)
    }

    submitActivity(activity: Activity){
        //convert to json string and send.  if send fails, add to queue
        this.sendActivity(activity)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                console.log("Error sending activity: ",  error)
                if (!this.addActivityToQueue(activity)) {
                    console.error("Unable to store activity to Queue")
                }
                return of(undefined);
            })
        ).subscribe(sentActivity => {
            // if (sentActivity) {
            //     console.log("Activity Sent for user: " + sentActivity.userID)
            // }
        })
    }

    private addActivityToQueue(a: Activity): boolean{
        if (this.isLocalStorageSupported) {
            let aList: Activity[] = this.getActivityQueue()
            if (aList === null) {
                aList = []
            }
            aList.push(a)
            if (this.putActivityQueue(aList)){
                return true;
            }
            return false
        }
        return false;
    }

    private getActivityQueue(): Activity[] {
        if (this.isLocalStorageSupported) {
            let aList = JSON.parse(this.localStorage.getItem(ACTIVITY_QUEUE_KEY))
            return aList;
        }
        return null;
    }

    private putActivityQueue(aList: Activity[]): boolean {
        if (this.isLocalStorageSupported) {
            this.localStorage.setItem(ACTIVITY_QUEUE_KEY, JSON.stringify(aList));
            return true;
        }
        return false;
    }

    processQueue(){
        // console.log("Starting processing of queue")
        
        let aList: Activity[] = this.getActivityQueue();

        if (!aList || aList.length == 0) {
            // console.log("Activity Queue was empty at processing time");
            return;
        }

        let newList: Activity[] = []

        concat(...aList.map((a, i) => 
            this.sendActivity(a).pipe(
                catchError(err => {
                    console.log("error procesisng queued Activity: ", err)
                    newList.push(aList[i])
                    return of({})
                })
            )
        ))
        .pipe(toArray())
        .subscribe(a => {
            //if timestamp of an activity is older than 3 days, do not re-add it
            newList = newList.filter(a => {
                let expiry = new Date();
                let aDate = new Date(a.timestamp);
                expiry.setDate(aDate.getDate() + 3);
                // expiry.setMinutes(aDate.getMinutes() + 3);
                let now = new Date();
                return now < expiry;
            })
            this.putActivityQueue(newList);
        })
    }

    private get isLocalStorageSupported(): boolean {
        return !!this.localStorage
    }
}
