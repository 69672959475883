import { Learnable } from '../data-model/learnable';
import { Module } from '../data-model/module'

export class LearnableSelector {
    selected:   boolean;
    id:         string;
    level:      string;
    module:     string;
    category:   string;
    name:       string;
    modIconUrl: string;

    constructor(learnable: Learnable, module: Module) {
        this.selected = false;
        this.id = learnable._id;
        this.level = learnable.level;
        this.module = learnable.module;
        this.category = learnable.category;
        this.name = learnable.name;
        this.modIconUrl = module.icon ? module.icon.url : '';
    }
}

