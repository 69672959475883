import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';

class States {
    learn: boolean;
    test: boolean;
    train: boolean;
    grade: boolean;
    gradings: boolean;
}

@Injectable()
export class NavbarService {
    states: States;

    constructor() {
        this.states = {
            learn: false,
            test: false,
            train: false,
            grade: false,
            gradings: false
        }
    }

    getNavbarStates(): Observable<States> {
        return of(this.states)
    }

}
