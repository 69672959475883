import { ServerService } from 'app/server.service';
import { ActivityService } from './../services/activity.service';
import { LearnActionActivity } from './../../data-model/activity';
import { AppConstants as constants } from '../../app-constants';
import { Component, OnInit } from '@angular/core';
import { Learnable, Video } from '../../data-model/learnable';
import { ModSet } from '../../data-model/mod-set';


import { ModSetService } from '../mod-set.service';
import { LearnableSelectionService } from '../learnable-selection.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const CONST_VIDEO_PLACEHOLDER_URL = "assets/videoDefault.jpg";

class ExtraData {
    moduleName: string;
    categoryName: string;
}


@Component({
    selector: 'learnable-detail',
    templateUrl: './learnable-detail.component.html',
    styleUrls: ['./learnable-detail.component.scss']
})
export class LearnableDetailComponent implements OnInit {
    selectedLearnable: Learnable;
    modSet: ModSet;
    modalVideo: Video;

    extraData: ExtraData;

    readonly VIDEO_PLACEHOLDER_URL = CONST_VIDEO_PLACEHOLDER_URL;
    readonly apiUrl = constants.apiUrl;

    constructor(
        private modSetService: ModSetService,
        private learnableSelectionService: LearnableSelectionService,
        private modalService: NgbModal,
        private activityService: ActivityService,
        private serverService: ServerService
        ) { }

    ngOnInit() {
        this.getModSet()
            .then(result => {
                return this.getSelectedLearnable()
            })
        //  	this.updateExtraData();
    }

    getModSet(): Promise<{}> {
        return new Promise((resolve, reject) => {
            this.modSetService.getModSet().subscribe(
                newModSet => {
                    this.modSet = newModSet;
                    resolve(newModSet);
                }
            )
        })
    }

    getSelectedLearnable(): Promise<{}> {
        return new Promise((resolve, reject) => {
            this.learnableSelectionService.getLearnableSubject().subscribe(
                newLearnable => {
                    this.selectedLearnable = newLearnable;
                    // console.log(this.selectedLearnable)
                    this.updateExtraData();
                    // console.log(newLearnable.gifImages)
                    resolve(newLearnable);
                }
            )
        })
    }

    updateExtraData(): void {
        this.extraData = {
            moduleName: this.modSet.modules.reduce((prev, module) => {
                return (module._id == this.selectedLearnable.module ? module.name : prev)
            }, "Not Found"),
            categoryName: this.modSet.categories.reduce((prev, category) => {
                return (category._id == this.selectedLearnable.category ? category.name : prev)
            }, "Not Found")
        }

        if (this.selectedLearnable.videoURL != undefined &&
            this.selectedLearnable.videoURL != "") {
            this.selectedLearnable.videos.push({
                caption: "",
                _id: "",
                url: this.selectedLearnable.videoURL,
                imgUrl: ""
            })

            //after moving the URL to the urls list, remove the url so it is not added again
            this.selectedLearnable.videoURL = "";
        }

        this.selectedLearnable.videos.forEach(video => {
            // if the video is a youtube video
            // if we can get a youtube video ID from it
            // generate a thumbnail URL of the format https://img.youtube.com/vi/<insert-youtube-video-id-here>/mqdefault.jpg
            video.imgUrl = this.getImgUrlFromVideoUrl(video.url);
        })

    }

    getImgUrlFromVideoUrl(url){
        let match = /.*embed\/([^?]*)/.exec(url);

        let result = ""

        if (match && match[1]){
            result = "https://img.youtube.com/vi/" + match[1] + "/mqdefault.jpg";
        }

        return result;
    }

    playAudio(): void {
        new Audio(this.selectedLearnable.sound.url).play();
    }

    openVideoModal(content, video): void{
        this.modalVideo = video;
        this.modalService.open(content, {centered: true, size: "lg"});
        this.sendVideoWatchActivity(video.caption);
    }

    sendVideoWatchActivity(vidCaption: string){
        this.activityService.submitActivity(
            new LearnActionActivity(
                this.serverService.getUserId().toString(),
                this.extraData.moduleName + "; "
                  + this.extraData.categoryName + "; "
                  + this.selectedLearnable.name,
                "playVideo",
                vidCaption
            )
        )
    }

    addAutoplay(url){
        var newUrl = url;
        if (/.+youtube.+/.test(newUrl)){
            if (/.+\?.+/.test(url)){
                newUrl = newUrl + "&autoplay=1";
            } else {
                newUrl = newUrl + "?autoplay=1";
            }
        }
        return newUrl;
    }
}
