import { CreateLoginNameComponent } from './../create-login-name/create-login-name.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from '../main/main.component';
import { LearnFormCheckboxComponent} from '../learn-form-checkbox/learn-form-checkbox.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';

import { AuthGuard } from '../auth-guard.service';
import { SelectionResolverService } from 'app/selection-resolver.service';
import { MyLevelSelectionResolverService } from 'app/my-level-selection-resolver.service';
import { UserProfileComponent } from './../user-profile/user-profile.component';
import { UserProfileViewComponent } from './../user-profile-view/user-profile-view.component';
import { UserProfileEditComponent } from 'app/user-profile-edit/user-profile-edit.component';
import { GradingMenuComponent } from './../grading-menu/grading-menu.component';
import { GradeGuardService } from 'app/grade-guard.service';
import { ViewGradingsComponent } from 'app/view-gradings/view-gradings.component';
import { TestComponent } from 'app/test/test.component';
import { AppConstants as constants } from 'app-constants';
import { LoginNameGuard } from 'app/login-name-guard.service';

const routes: Routes = [
    { 
        path: 'main',     
        component: MainComponent,
        canActivate: [LoginNameGuard ]
    },
    { 
        path: 'learn',    
        component: LearnFormCheckboxComponent, 
        title: 'Learn | ' + constants.appTitle,
        canActivate: [ AuthGuard, LoginNameGuard ],
        resolve: { 
            modSetSelection: SelectionResolverService,
            myLevelSelection: MyLevelSelectionResolverService
        },
        runGuardsAndResolvers: "always"
    },
    {
        path: 'grade',    
        component: GradingMenuComponent, 
        title: 'Grade | ' + constants.appTitle,
        canActivate: [ AuthGuard, GradeGuardService, LoginNameGuard ], // Guard to ensure the user is an instructor 
        resolve: { 
            modSetSelection: SelectionResolverService
        },
        runGuardsAndResolvers: "always"
    },
    {
        path: 'gradings',    
        component: ViewGradingsComponent, 
        title: 'View Gradings | ' + constants.appTitle,
        canActivate: [ AuthGuard, LoginNameGuard ],  
        resolve: { 
            modSetSelection: SelectionResolverService
        },
        runGuardsAndResolvers: "always"
    },
    {
        path: 'profile',
        component: UserProfileComponent,
        title: 'Profile | ' + constants.appTitle,
        canActivate: [ AuthGuard, LoginNameGuard ],
        children: [
            {path: '', redirectTo: 'view', pathMatch: 'prefix'},
            {path: 'edit', component: UserProfileEditComponent},
            {path: 'view', component: UserProfileViewComponent}
        ]
    },
    { 
        path: 'test',    
        component: TestComponent, 
        title: 'Test | ' + constants.appTitle,
        canActivate: [ AuthGuard, LoginNameGuard ],
        resolve: { 
            modSetSelection: SelectionResolverService,
            myLevelSelection: MyLevelSelectionResolverService
        },
        runGuardsAndResolvers: "always"
    },
    { 
      path: 'createloginname',    
      component: CreateLoginNameComponent, 
      title: 'Create Login Name | ' + constants.appTitle,
      canActivate: [ AuthGuard ],
      runGuardsAndResolvers: "always"
    },
    { 
        path: '',
        redirectTo: '/main', 
        pathMatch: 'full'
    },

    //all others redirect to page not found
    { 
        path: '**', 
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [ 
        RouterModule.forRoot(routes, { /*enableTracing: true ,*/ onSameUrlNavigation: "reload" })
    ],
    exports: [ 
        RouterModule 
    ]
})

export class AppRoutingModule {
}
