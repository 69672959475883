<div>
    <!-- ** Navigation Buttons ** -->
    
    <!-- Mobile Layout -->
    <div 
        class="float-right back-button-mobile d-block d-sm-none">
        <button 
            class="btn btn-primary btn-sm" 
            [class.invisible]="!learnableDetailsActive"
            (click)="onBackButtonClick()">
            <p class="mb-0">&lt; Back</p>
        </button>
    </div>

    <div class="btn-group float-left nav-buttons-container-mobile d-block d-sm-none">
        <button 
            class="btn btn-primary btn-sm" 
            [class.invisible]="!learnableDetailsActive"
            (click)="onPreviousButtonClick()">
            <p class="mb-0">&lt; Previous</p>
        </button>
        <button 
            class="btn btn-primary btn-sm" 
            [class.invisible]="!learnableDetailsActive"
            (click)="onNextButtonClick()">
            <p class="mb-0">Next &gt;</p>
        </button>
    </div>
        

    <!-- Web Layout -->
    <div class="float-right back-button-container d-none d-sm-block mt-5">
        <div class="mt-2">
            <button 
                class="btn btn-primary btn-sm" 
                [class.invisible]="!learnableDetailsActive"
                (click)="onBackButtonClick()">
                <p class="mb-0">&lt; Back</p>
            </button>
        </div>
    </div>

    <div class="float-left nav-buttons-container d-none d-sm-block mt-5">
        <div class="btn-group mt-2">
            <button 
                class="btn btn-primary btn-sm" 
                [class.invisible]="!learnableDetailsActive"
                (click)="onPreviousButtonClick()">
                <p class="mb-0">&lt; Previous</p>
            </button>
            <button 
                class="btn btn-primary btn-sm" 
                [class.invisible]="!learnableDetailsActive"
                (click)="onNextButtonClick()">
                <p class="mb-0">Next &gt;</p>
            </button>
        </div>
    </div>

    <div 
        *ngIf="!learnableDetailsActive"
        id="dismiss" 
        class="float-left"
        [class.active]="showExplore"
        (click)="showExplore = !showExplore"> 
        <i
            class="btn btn-primary fas" 
            [class.fa-arrow-left]="showExplore"
            [class.fa-arrow-right]="!showExplore"></i>
    </div>
    
    <!-- Content -->
    <div 
        class="row wrapper">
        
        <!-- Left Nav -->
        <nav 
            *ngIf="!learnableDetailsActive" 
            class="leftnav border-right border-secondary col col-auto pb-2"
            [class.active]="showExplore">
            
            <div class="text-center"><h5 class="mt-1">Explore </h5></div>
            <div 
                class="btn-group mb-2 mt-1" display="inline">
                
                <button 
                    class="btn btn-success btn-sm " 
                    disabled>
                    <h6 class="mb-0">MyLevel: </h6>
                </button>               
                <button 
                    class="btn btn-success btn-sm" 
                    [attr.disabled]="isMyLevelSelected ? '' : null"
                    (click)="saveMyLevel()">
                    <h6 class="mb-0">Save</h6>
                </button>

                <button 
                    class="btn btn-success btn-sm" 
                    [attr.disabled]="isMyLevelSelected ? '' : null"
                    (click)="loadMyLevel()">
                    <h6 class="mb-0">Load</h6>
                </button>
            </div>
            
            <div

                class="selectorrow">
                <module-selector-checkbox2></module-selector-checkbox2>
            </div>


            <div 
                class="selectorrow border-top border-secondary mt-2 pt-2">
                <category-selector-checkbox></category-selector-checkbox>
            </div>

            
        </nav>
        
        <!-- Left Nav Topics List -->

        <!-- TODO: Convert to right-floating links menu with next/prev buttons... -->
        <!-- <div 
            *ngIf="learnableDetailsActive" 
            class="leftnav border-right border-secondary col col-auto mt-5 mb-2 d-none d-md-block">
            <div
                class="selectorrowmt-2">
                <learnable-selector></learnable-selector>
            </div>
            
        </div> -->

        <!-- Learnable Selector -->
        <div 
            class="col learnable-content pb-2"
            [class.static]="showExplore">
            <div
                *ngIf="!haveSomeLearnables && !learnableDetailsActive"
                class="container">
                <div class="jumbotron">
                    <h3>No Topics to show...</h3>
                    <p class="lead">Please select some Belts and Levels to show a list of related Categories </p>
                    <p class="lead">Then, select some Categories and you will see a list of Topics</p>
                </div>
            </div>
            <div 
                *ngIf="haveSomeLearnables && !learnableDetailsActive"
                class="col-sm-11 container">
                <learnable-selector-grouped></learnable-selector-grouped>
            </div>
            <div 
                *ngIf="learnableDetailsActive"
                class="col-sm-11 pl-0 pr-0 container">
                <!-- <a href="#" (click)="learnableDetailsActive = !learnableDetailsActive; $event.preventDefault();">&lt; Return to Topics List</a> -->
                <learnable-detail></learnable-detail>
            </div>
        </div>
    </div>

    <!-- Overlay -->
    <div 
        class="overlay"
        [class.active]="showExplore"
        (click)="showExplore = false;"
        ></div>

</div>