import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ServerService } from './server.service';

@Injectable()
export class LoginNameGuard {

  constructor(
    private server: ServerService, 
    private router: Router
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // if user is not logged in, allow access.
    if (!this.server.isLoggedIn()){
      return Promise.resolve(true);
    }
    
    const url: string = state.url;
    
    //if user has a Login Name return true
    return this.server.getUserProfile()
    .toPromise()
    .then(user => {
      if (user.loginName) {
        return true;
      } else {
        this.server.redirectUrl = url;
        this.router.navigate(['/createloginname']);
        return false;
      }
    })
    
  }
}
