import { TestStartActivity, TestEndActivity } from './../../data-model/activity';
import { ActivityService } from './activity.service';
import { ModSetSelection } from 'data-model/modset-selection';
import { TestSession } from './../../data-model/test-session';
import { IndexedModSet } from './../../data-model/indexedModSet';
import { ServerService } from 'app/server.service';
import { ModSetService } from './../mod-set.service';
import { Injectable } from '@angular/core';
import { TestConfiguration } from 'data-model/test-configuration';
import { TestResult } from 'data-model/test-result';
import { map, tap } from 'rxjs/operators';
import { TestQuestion } from 'data-model/test-question';
import { Observable, observable } from 'rxjs';
import { Question } from 'data-model/question';
import { ModSet } from 'data-model/mod-set';
import { Answer } from 'data-model/answer';
import { Learnable } from 'data-model/learnable';
import { LearnableSelector } from 'app/learnable-selector';
import { ModuleSelector } from 'app/module-selector';
import { CategorySelector } from 'app/category-selector';
import { CategorySelection } from 'data-model/category-selection';

@Injectable({
    providedIn: 'root'
})
export class TestService {


    private _selectedTestResults: TestResult = null;
    private _newTestConfig: TestConfiguration = null;
    private _activeTestConfig: TestConfiguration = null;
    private _testSessionResults: TestResult = null;
    private _modSet: ModSet;
    private _candidateQuestions: Array<TestQuestion> = [];
    private _indexedModSet: IndexedModSet;
    private _testSession: TestSession;
    private _testResultsList: Array<TestResult> = []
    private _studyLearnable: string = null;

    TEST_RESULTS_KEY = "TRAINING_TOOL_TEST_RESULTS"
    MAX_TEST_RESULTS_STORED = 5;


    constructor(
        private modSetService: ModSetService,
        private activityService: ActivityService,
        private serverService: ServerService
        ) { }


    initialize(): Observable<any> {
        return this.modSetService.getModSet().pipe(tap(modSet => {
            this._modSet = modSet;
            this._indexedModSet = new IndexedModSet(modSet);
        }));
    }

    public get selectedTestResults(): TestResult {
        return this._selectedTestResults;
    }

    public set selectedTestResults(value: TestResult) {
        this._selectedTestResults = value;
    }

    public get newTestConfig(): TestConfiguration {
        return this._newTestConfig;
    }

    public set newTestConfig(value: TestConfiguration) {
        this._newTestConfig = value;
    }

    // public get activeTestConfig(): TestConfiguration {
    //     return this._activeTestConfig;
    // }

    hasTestResultsSelected(): boolean {
        return this._selectedTestResults !== null
    }

    hasNewTestConfig(): boolean {
        return this._newTestConfig !== null
    }

    hasActiveTestConfig(): boolean {
        return this._activeTestConfig !== null;
    }

    hasStudyLearnableSelected(): boolean {
        return this._studyLearnable !== null;
    }

    initialized(): boolean {
        return this._modSet !== undefined && this._modSet !== null;
    }


    startNewTest(config?: TestConfiguration): void {
        this._newTestConfig = config || new TestConfiguration();
    }

    getNewTestModSetSelection(): ModSetSelection {
        return this._newTestConfig.getModSetSelection();
    }

    getNewTestNumQuestions(): number {
        return this._newTestConfig.getNumQuestions();
    }

    updateNewTestModules(moduleSelectors: Array<ModuleSelector>){
        this._newTestConfig.getModSetSelection().setModuleSelection(moduleSelectors);
    }

    updateNewTestCategories(categorySelectors: Array<CategorySelector>){
        this._newTestConfig.getModSetSelection().setCategorySelection(categorySelectors);
    }

    updateNewTestModSetSelection(updatedModSetSelection: ModSetSelection) {
        this._newTestConfig.setModSetSelection(updatedModSetSelection);
    }

    startNewTestWithSelectedResult() {
        let config: TestConfiguration = this._selectedTestResults.getTestConfiguration();
        this._selectedTestResults = null;
        this.startNewTest(config)
    }

    setNumQuestions(numQuestions: number) {
        this.newTestConfig.setNumQuestions(numQuestions);
    }

    startTestSession(): void {
        this._activeTestConfig = this._newTestConfig;
        this.sendTestStartActivity(this._activeTestConfig);
        this._testSession = new TestSession(this._activeTestConfig, this._modSet, this._candidateQuestions)
        this.cleanNewTest();
    }

    cleanNewTest(): void {
        this._newTestConfig = null;
    }

    updateCandidateQuestionsFromLearnableList(learnableList: LearnableSelector[]): Array<TestQuestion> {
        this._candidateQuestions = [];
        learnableList.forEach(learnableSelector => {
            let learnable = this._indexedModSet.getLearnable(learnableSelector.id)
            learnable.questionList.forEach(question => {
                this._candidateQuestions.push(new TestQuestion(question, learnable._id, []))
            })
        })
        return this._candidateQuestions;
    }

    availableQuestions(): number {
        return this._candidateQuestions.length;
    }

    getSessionQuestions(): Array<TestQuestion> {
        return this._testSession.getQuestionList()
    }

    endTestSession() {
        this._testSession.endTestSession();
        let results = this._testSession.getTestResults();
        this.addNewTestResults(results)
        this.sendTestEndActivity(results)
        this._selectedTestResults = results;
        this._activeTestConfig = null
    }



    selectTestResults(testResults: TestResult): void {
        this._selectedTestResults = testResults;
    }

    getTestResultsList() {
        let storedList = localStorage.getItem(this.TEST_RESULTS_KEY);

        if (storedList === null || storedList === undefined) { storedList = "[]" };

        let parsedList = JSON.parse(storedList, this.reviver)
        let newList = parsedList.map(result => {
            let newTR: TestResult = TestResult.fromJSON(result);
            return newTR
        });

        return newList;
    }


    addNewTestResults(result: TestResult): void {
        let newList: Array<TestResult> = this.getTestResultsList();
        newList.unshift(result)
        while (newList.length > this.MAX_TEST_RESULTS_STORED) {
            newList.pop()
        }
        let listToStore: string = JSON.stringify(newList, this.replacer)
        localStorage.setItem(this.TEST_RESULTS_KEY, listToStore)
    }

    replacer(key, value) {
        if (value instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(value.entries()), // or with spread: value: [...value]
            };
        } else {
            return value;
        }
    }

    reviver(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value);
            }
        }
        return value;
    }

    getSelectedTestResultDateCompleted(): Date {
        return this._selectedTestResults.getDateCompleted();
    }

    getSelectedTestResultsNumQuestions() {
        return this._selectedTestResults.getNumQuestions();
    }

    getSelectedTestResultsNumCorrect() {
        return this._selectedTestResults.getNumCorrect();
    }

    getSelectedTestResultsPercentCorrect(): number {
        return this._selectedTestResults.getPercentCorrect();
    }

    getSelectedTestResultsQuestions(): TestQuestion[] {
        return this._selectedTestResults.getQuestionList();
    }

    setStudyLearnable(id: string): void {
        this._studyLearnable = id;
    }

    getStudyLearnable(): string {
        return this._studyLearnable;
    }

    endStudy(): void {
        this._studyLearnable = null;
    }

    ensureCategoriesAreSelected(modSetSelection: ModSetSelection) {
        if (modSetSelection.getCategories().size < 1) {
            this._modSet.categories.forEach(category => {
                modSetSelection.addCategorySelection( new CategorySelection(category._id, category.name))
            })
        }
    }

    private getCatList(tc: TestConfiguration): string[] {
        const mss = tc.getModSetSelection();
        if (mss) {
            let cl = mss.getCategories()
            if (cl.size > 0){
                return Array.from(cl.keys())
            }
        }
        return []
    }

    private getModList(tc: TestConfiguration): string[] {
        const mss = tc.getModSetSelection();
        if (mss) {
            let ml = mss.getModules()
            if (ml.size > 0){
                return Array.from(ml.keys())
            }
        }
        return []
    }

    private sendTestStartActivity(tc: TestConfiguration) {
        this.activityService.submitActivity(
            new TestStartActivity(
                this.serverService.getUserId().toString(),
                this.getCatList(tc),
                this.getModList(tc),
                tc.getNumQuestions()
            )
        )
    }

    private sendTestEndActivity(tr: TestResult) {
        const tc = tr.getTestConfiguration();
        const numIncorrect: number = tr.getNumQuestions() - tr.getNumCorrect();

        const incorrectQuestions: string[] = 
            tr.getIncorrectQuestionList().map(q => q.getQuestionText().toString())

        this.activityService.submitActivity(
            new TestEndActivity(
                this.serverService.getUserId().toString(),
                this.getCatList(tc),
                this.getModList(tc),
                tc.getNumQuestions(),
                numIncorrect,
                incorrectQuestions
            )
        )
    }

}
