import { TestQuestion } from './../../data-model/test-question';
import { TestService } from './../services/test.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Answer } from 'data-model/answer';

@Component({
    selector: 'test-session',
    templateUrl: './test-session.component.html',
    styleUrls: ['./test-session.component.scss']
})
export class TestSessionComponent implements OnInit {

    questions: Array<TestQuestion>
    currentQuestion: TestQuestion = null;
    questionIndex: number = 0;
    selectedRadioAnswer: String;
    cbArray: Array<String> = []
    contentDebug: boolean = false;

    constructor(private testService: TestService) { }

    ngOnInit(): void {
        this.questions = this.testService.getSessionQuestions()
        this.currentQuestion = this.questions[this.questionIndex]
    }

    haveMoreQuestions(): boolean {
        return this.questionIndex < (this.questions.length - 1)
    }

    nextQuestion(): void {

        let answers = this.collectAnswers();

        this.currentQuestion.putAnswerIdsSelected(answers)
        console.log("Question was answered " + this.currentQuestion.isAnsweredCorrectly() ? "CORRECT" : "INCORRECT")
        
        
        if (this.haveMoreQuestions()){
            this.questionIndex += 1;
        }
        this.currentQuestion = this.questions[this.questionIndex];
    }

    answerHasAnImage(answer: Answer): boolean {
        return answer.image ? true : false;
    }

    getAnswerImageUrl(answer: Answer): String {
        return answer.image.url
    }

    isCurrentQuestionMultiAnswer(): boolean {
        return this.currentQuestion.getNumCorrectAnswers() > 1
    }

    collectAnswers(): Array<String>{
        if (this.isCurrentQuestionMultiAnswer()){
            return this.cbArray.reduce((answerList, answer, index) => {
                if (answer){
                    answerList.push(this.currentQuestion.getAnswerList()[index]._id)
                }
                return answerList
            }, [])
        } else {
            return [this.selectedRadioAnswer]
        }

    }

    endTest(): void {
        //capture the last question's answers
        let answers = this.collectAnswers();
        this.currentQuestion.putAnswerIdsSelected(answers)
        console.log("Question was answered " + this.currentQuestion.isAnsweredCorrectly())
        this.testService.endTestSession();
    }

}
