
<!-- Content -->
<div class="scrolling">
	<div 
		*ngIf="categoryLearnableList !== undefined && !isEmptyObject(categoryLearnableList)"
		class="learnablecontainer row">
        
        <div 
            class="col-12">
            <div 
                *ngFor="let category of categoryLearnableList"
                class="card">
                <div class="card-header">
                    <h6 class="mb-0">{{category.categorySelector.name}}</h6>
                </div>
                <div class="card-body row">
                    <div 
                        *ngFor="let learnable of category.learnableList"
                        class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-2">
                        <div 
                            class="learnable card"
                            (click)="onLearnableSelected(learnable)"
                            [class.active]="learnable.selected">
                            
                            <!-- <img class="card-img-top" src="{{learnable.modIconUrl}}"> -->
                            
                            <div class="card-body p-2 row">
                                <div *ngIf="learnable.modIconUrl" class="mod-icon-container col-2">
                                    <img class="mod-icon" src="{{learnable.modIconUrl}}">
                                </div>
                                
                                <div [ngClass]="{'col-12': !learnable.modIconUrl, 'col-10': learnable.modIconUrl}"> <small>{{ learnable.name }}</small> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		

	</div>
	<div *ngIf="categoryLearnableList == undefined || isEmptyObject(categoryLearnableList)">
        <b style="color: red;">Select some Belts and Categories</b>
	</div>
</div>
