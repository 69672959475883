<div class="container">
        <div class="row">
            <div class="col-sm-8">
                <h1> Sign-Up for TrainingTool </h1>                
                <form #f="ngForm" [hidden]="message || success.message" (ngSubmit)="f.valid && register(model)" novalidate>
    
                    <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                      <div class="col">
                        <label for="email">Email</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            name="email" 
                            [(ngModel)]="model.email" 
                            #email="ngModel" 
                            required />
                      </div>  
                    </div>

                    <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !fName.valid }">
                      <div class="col">
                        <label for="fName">First Name</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            name="fName" 
                            [(ngModel)]="model.fName" 
                            #fName="ngModel" 
                            required 
                            minlength="{{nameMinLength}}"
                            maxlength="{{nameMaxLength}}"/>
                      </div>
                    </div>

                    <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !lName.valid }">
                      <div class="col">
                        <label for="lName">Last Name (or initial)</label>
                        <input 
                            type="text" 
                            class="form-control" 
                            name="lName" 
                            [(ngModel)]="model.lName" 
                            #lName="ngModel" 
                            required 
                            minlength="1"
                            maxlength="{{nameMaxLength}}"/>
                      </div>
                    </div>


                    <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                      <div class="col">
                        <label for="password">Password</label>
                        <input 
                            type="password" password 
                            class="form-control" 
                            name="password" 
                            [(ngModel)]="model.password" 
                            #password="ngModel" 
                            required 
                            minlength="{{passwordMinLength}}"
                            maxlength="{{passwordMaxLength}}"/>
                      </div>                        
                    </div>

                    <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !inputConfirmPassword.valid }">
                      <div class="col">
                        <label for="confirmPassword">Confirm Password</label>
                        <input 
                            type="password" password 
                            class="form-control" 
                            name="confirmPassword" 
                            [(ngModel)]="confirmPassword" 
                            #inputConfirmPassword="ngModel" 
                            required 
                            maxlength="{{passwordMaxLength}}"/>
                      </div>
                    </div>
    
                    <div class="row mt-2">
                      <div class="col">
                        <button [disabled]="loading || !(f.valid && inputConfirmPassword.value == password.value)" class="btn btn-primary">Sign-Up</button>
                        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                      </div>
                    </div>

                    <div *ngIf="f.submitted && !email.valid" class="form-text text-muted row mt-2">
                      <div class="col">
                        Email is required
                      </div>
                    </div>
                    <div *ngIf="f.submitted && !(fName.valid || lName.valid)" class="form-text text-muted row mt-2">
                      <div class="col">
                        Name is required
                      </div>
                    </div>
                    <div *ngIf="f.submitted && !password.valid" class="form-text text-muted row mt-2">
                      <div class="col">
                        Password is required
                      </div>
                    </div>
                    <div *ngIf="f.submitted && !inputConfirmPassword.valid" class="form-text text-muted row mt-2">
                      <div class="col">
                        Password Confirmation is required
                      </div>
                    </div>
                    <div class="form-text text-muted row mt-2"
                      [ngClass]="{'has-error': fName.errors && fName.errors.minlength}">
                      
                      <div class="col">
                        First name must be between {{nameMinLength}} and {{nameMaxLength}} characters long.
                      </div>
                    </div>
                    <div class="form-text text-muted row mt-2"
                      [ngClass]="{'has-error': lName.errors && lName.errors.minlength}">
                      
                      <div class="col">
                        Last name must be between 1 and {{nameMaxLength}} characters long.
                      </div>
                    </div>
                    <div class="form-text text-muted row mt-2"
                      [ngClass]="{'has-error': password.errors && password.errors.minlength}">

                      <div class="col">
                        Password must be between {{passwordMinLength}} and {{passwordMaxLength}} characters long.
                      </div>
                    </div>
                    <div class="form-text text-muted row mt-2"
                      [ngClass]="{'has-error': inputConfirmPassword.value != password.value}">

                      <div class="col">Passwords must match</div>
                    </div>
                    <div *ngIf="message" class="alert alert-secondary row mt-2">
                      <div class="col">{{message}}</div>
                    </div>
                </form>
                <div *ngIf="success.message" class="alert alert-success row mt-2">
                  <div class="row">
                    <span>{{success.message}}</span></div>
                  <div class="row mt-2">
                    <span>When you log in to the app, you will use the Login Name "<b>{{success.loginName}}</b>" with your password to sign in.</span>
                  </div>
                </div>
                <div *ngIf="error" class="alert alert-danger row mt-2">
                  <div class="col">{{error}}</div>
                </div>
            </div>
        </div>
    </div>
