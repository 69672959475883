import { TestService } from './../services/test.service';
import { LearnableSelectionService } from './../learnable-selection.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'test-study',
  templateUrl: './test-study.component.html',
  styleUrls: ['./test-study.component.scss'],
  providers: [ LearnableSelectionService ]
})
export class TestStudyComponent implements OnInit {

  constructor(private testService: TestService, private learnableSelection: LearnableSelectionService) { }

  ngOnInit(): void {
      //push the learnable selection service to the selected learnable with selectLearnable(id)
      this.learnableSelection.selectLearnable(this.testService.getStudyLearnable())
  }

  goBack(): void {
      this.testService.endStudy();
  }

}
