import { LearnableSelector } from 'app/learnable-selector';
import { CategorySelector } from 'app/category-selector';
export class CategoryLearnableSelector {
    categorySelector: CategorySelector;
    learnableList: LearnableSelector[];

    constructor(){
        this.learnableList = new Array();
    }
}
