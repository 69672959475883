import { ModSet } from 'data-model/mod-set';
import { ServerService } from './server.service';
import { ModSetService } from 'app/mod-set.service';
import { Injectable } from '@angular/core';
import { Grading } from 'data-model/grading';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GradingSession } from '../data-model/gradingSession';
import { tap, take } from 'rxjs/operators';


/**
 * maintains a current Grading Session that will interface with the server service to persist the changes
 * Dependencies: ServerService, ModSetService
 * creates a grading session that associates the 
 */
@Injectable()
export class GradingSessionService {
    

    grading: Grading;
    gradingSession: GradingSession;
    gradingSessionSubject: BehaviorSubject<GradingSession>;
    savingSubject: BehaviorSubject<boolean>;
    modSet: ModSet;

    constructor(
        private modSetService: ModSetService,
        private serverService: ServerService) {

        //initialize the savingSubject to false
        this.savingSubject = new BehaviorSubject(false);
        this.gradingSessionSubject = new BehaviorSubject<GradingSession>(undefined);
        // this.modSetService.getModSet()
        // .pipe(take(1))
        // .subscribe(modSet => {
        //     this.modSet = modSet;
        // })

    }

    // called externally, this needs to happen first
    init(grading: Grading) {
        this.modSetService.getModSet()
        .pipe(
            take(1),
            tap(modSet => { this.modSet = modSet; }))
        .subscribe(() =>  { this.updateGrading(grading); })
    }

    updateGrading(grading){
        this.grading = grading;
        this.gradingSession = new GradingSession(this.grading, this.modSet);
        this.gradingSessionSubject.next(this.gradingSession);
    }

    getSavingObservable(): Observable<boolean>{
        return this.savingSubject;
    }

    getGradingSessionObservable() {
        return this.gradingSessionSubject.asObservable();
    }

    // included only for demo purposes to make it seem like it's being saved with a delay.
    async delay(ms: number) {
        await new Promise<void>(resolve => setTimeout(()=>resolve(), ms));
    }    

    saveGrading(){
        //TODO: Implement Saving of Grading!!!!
        this.savingSubject.next(true);
        //console.log("** GradingSessionService.saveGrading():",this.grading.grades[0])
        return this.serverService.saveGrading(this.grading)
        .pipe(
            take(1),
            tap(grading => {
                this.init(grading)}
            ),
            tap(() => { this.savingSubject.next(false); })
        )
        
    }

    getGrading(){
        return this.grading;
    }

    endSession(){
        this.gradingSession = null;
        this.gradingSessionSubject.next(this.gradingSession)   
    }
}
