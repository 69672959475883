import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError, shareReplay, finalize } from 'rxjs/operators';

import { ModSet } from '../data-model/mod-set';

import { ServerService } from './server.service';

// milliseconds to wait before refresing cache (1 hour defualt: 60*60*1000)
const MAX_CACHE_AGE: number = 60 * 60 * 1000;

@Injectable()
export class ModSetService  {

    // modset cached when it was originally received
    private cachedModSet: ModSet;

    // Timestamp when modset was cached to determine when it should be refreshed
    private cachedTime = 0;

    private serverRequest: Observable<ModSet>;

    constructor(
        private serverService: ServerService
    ) {

        // TODO: Subscribe to serverService LoginState and when it changes to true from false, load the modSet. 
        //  when it changes to false from true, delete the local modset details
    }

    onLogout() {
        this.cachedModSet = undefined;
        this.cachedTime = 0;
        this.serverRequest = null;
    }

    private isCacheExpired(){
        //add cached time and return true
        return this.cachedTime + MAX_CACHE_AGE < Date.now();
    }


    /**
     * Get an Observable of the currently Cached modset or a new copy of the modset retrieved from the server
     * @param {boolean} force Force a server request (ignore the cached copy if available)
     * @returns {Observable<ModSet>} Observable of the returned or cached ModSet
     */
    getModSet(force?: boolean): Observable<ModSet> {
        if (!this.serverService.bypassCache) {
            // if I have a cached copy, don't go to the server
            if (!force && this.cachedModSet && !this.isCacheExpired()) {
                return of(this.cachedModSet);
            }
        }
        
        // if a request is already in progress, return an observable for it
        if (this.serverRequest) {
            return this.serverRequest;
        }

        this.serverRequest =  this.serverService.getModSet(force)
        .pipe(
            map(newModSet => {
                this.cachedModSet = newModSet;
                this.cachedTime = Date.now();
                return this.cachedModSet;
            }),
            catchError((error: ModSet | any) => {
                // if we have a cached ModSet even if it's not still valid, return it
                if (this.cachedModSet){
                    return of(this.cachedModSet);
                } else {
                    // pass the error back up to the requestor
                    return observableThrowError(error);
                }
            }),
            finalize(() => {
                // This request is complete
                this.serverRequest = null;
            }),
            shareReplay(1),
            
        );
        return this.serverRequest;
    }

}
