import { ServerService } from 'app/server.service';
import { ActivityService } from './services/activity.service';
import { LearnActionActivity } from './../data-model/activity';
import { ModSetService } from 'app/mod-set.service';
import { Injectable, OnInit } from '@angular/core';

import { Observable,  ReplaySubject } from 'rxjs';

import { Learnable } from '../data-model/learnable';
import { ModSet } from '../data-model/mod-set';
import { ModSetSelection } from '../data-model/modset-selection';

import { ModuleSelector } from './module-selector';
import { LevelSelector } from './level-selector';
import { CategorySelector } from './category-selector';
import { LearnableSelector } from './learnable-selector';
import { CategoryLearnableSelector } from './category-learnable-selector';



@Injectable()
export class LearnableSelectionService {

    modSet: ModSet;

    moduleIndex;
    categoryIndex;
    learnableIndex;
	// {
	// 	learnableId: {
	// 		learnable: Learnable;
	// 		selector:	LearnableSelector;
	// 	}
	// }

	// list to display in Module Selector
    moduleSelectionList: ModuleSelector[]; // format of object is {id: selectedModule}
    moduleSelectionListSubject: ReplaySubject<ModuleSelector[]>;

    categorySelectionList: CategorySelector[]; // format of object is {id: Category}
    categorySelectionListSubject: ReplaySubject<CategorySelector[]>;

    learnableSelectionList: LearnableSelector[];
    learnableSelectionListSubject: ReplaySubject<LearnableSelector[]>;
 
    categoryLearnableSelectorList: any;
    categoryLearnableSelectionListSubject: ReplaySubject<any>;

    selectedLearnable: Learnable;

    learnableSubject: ReplaySubject<Learnable>;

    hierarchy = {
		/*  [modid1]:{
				module: Module
				categories:{
					[catid1]: {
                        category: Category
                        learnables: [
                            "[learnid1]"
                        ]
			   	    }
			   	    [catid2]: {
                        category: Category
                        learnables: [
                            "[learnid2]",
                            "[learnid3]",
                        ]
			   	    }
			    }
            },
			[modid2]: {...}
		*/
    };

    modSetSelection: ModSetSelection;

    constructor(
        private modSetService:      ModSetService,
        private activityService:    ActivityService,
        private serverService:      ServerService
        ) {
        this.moduleSelectionList = new Array();
        this.moduleSelectionListSubject = new ReplaySubject<ModuleSelector[]>(1);

        this.categorySelectionList = new Array();
        this.categorySelectionListSubject = new ReplaySubject<CategorySelector[]>(1);
        
        this.learnableSelectionList = new Array();
        this.learnableSelectionListSubject = new ReplaySubject<LearnableSelector[]>(1);
        
        this.categoryLearnableSelectorList = new Array();
        this.categoryLearnableSelectionListSubject = new ReplaySubject<CategoryLearnableSelector[]>(1);
        this.learnableSubject = new ReplaySubject<Learnable>(1);
        
        this.modSetService.getModSet()
        .subscribe(newModSet => {
            this.modSet = newModSet;
            this.generateHierarchy();
            this.populateModuleSelectionList();
            this.moduleSelectionChanged();
//            this.putModSet(newModSet)
        })

    }

    // initializes the selection with some pre-selected modules and levels.
    setSelectedMods(modSetSelection: ModSetSelection){
        if (modSetSelection){
            this.modSetSelection = modSetSelection;

            // If the modset has already been provided, regenerate the module selection list
            if (this.modSet){
                this.generateHierarchy();
                this.populateModuleSelectionList();
                
            }

            // Update the Category and Learnable Subjects with new data
            this.moduleSelectionChanged();
            // no additional action required since a new modset arriving will use selected modules to display
        }
    }


    // run this only when the modset changes (or if a pre-selected set of modules were provided after the modset)
    populateModuleSelectionList(): void {

        // empty the list
        this.moduleSelectionList.length = 0;

        // only add modules for which there are actual learnables.
        Object.keys(this.hierarchy).forEach(moduleId => {
            
            //TODO: consider adding a "authorized" specifier to the selector instead of excluding the module
            //  would allow the view to choose to display the module selector or not
            if (this.hierarchy[moduleId] && this.hierarchy[moduleId].moduleSummary.module.authorized){
                this.moduleSelectionList.push(this.moduleIndex[moduleId].selector);
            } 
            // else {
            //     console.log("module was not added because it is not authorized:",this.hierarchy[moduleId].module)
            // }
        });

        this.moduleSelectionList.sort((modA: ModuleSelector, modB: ModuleSelector) => {
            return this.moduleIndex[modA.id].sortIndex - this.moduleIndex[modB.id].sortIndex;
        });

        this.moduleSelectionListSubject.next(this.moduleSelectionList);
    }

    generateHierarchy(): void {
        this.moduleIndex = {};
        this.categoryIndex = {};
        this.learnableIndex = {};
        this.hierarchy = {};

        // generate a set of modules indexed by key for convenient lookup
        this.modSet.modules.forEach((module, index) => {
            this.moduleIndex[module._id] = {
                module: module,
                selector: new ModuleSelector(module, this.modSetSelection),
                sortIndex: index
            };
        });

        this.modSet.categories.forEach((category, index) => {
            this.categoryIndex[category._id] = {
                category: category,
                selector: new CategorySelector(category, this.modSetSelection),
                sortIndex: index
            };
        });

        this.modSet.learnables.forEach((learnable, index) => {
            this.learnableIndex[learnable._id] = {
                learnable: learnable,
                selector: new LearnableSelector(learnable, this.moduleIndex[learnable.module].module),
                sortIndex: index
            };

            // this.modSet.learnables.forEach((learnable: Learnable,index) => {
            // if module isn't there, add it
            if (!this.hierarchy[learnable.module]) {
              this.hierarchy[learnable.module] = {
                moduleSummary: this.moduleIndex[learnable.module],
                levels: {}
              };
            }

            // if level isn't there, add it
            if (!this.hierarchy[learnable.module].levels[learnable.level]) this.hierarchy[learnable.module].levels[learnable.level] = {
                levelName: learnable.level,
                categories: {}
            };

            // if category isn't there, add it
            if (!this.hierarchy[learnable.module]
                .levels[learnable.level]
                .categories[learnable.category])

                this.hierarchy[learnable.module]
                    .levels[learnable.level]
                    .categories[learnable.category] = {
                        categorySummary: this.categoryIndex[learnable.category],
                        learnables: []
                    };
            // category array must exist so add learnable to it
            this.hierarchy[learnable.module].levels[learnable.level].categories[learnable.category].learnables.push(learnable);
        });
    }

    updateCategorySelectors(): void {
        const uniqueCategories =
            this.moduleSelectionList.reduce((categories, moduleSelector) => {
                // console.log(moduleSelector)
                // console.log(this.hierarchy[moduleSelector.id]);
                if (moduleSelector.selected
                    && moduleSelector.levels !== undefined
                    && moduleSelector.levels.length > 0
                    && this.hierarchy[moduleSelector.id]
                    && this.hierarchy[moduleSelector.id].levels !== undefined
                    && Object.keys(this.hierarchy[moduleSelector.id].levels).length > 0) {
                    // console.log("module selected with levels");

                    moduleSelector.levels.forEach((levelSelector: LevelSelector) => {
                        //console.log(levelSelector)
                        if (levelSelector.selected
                            && this.hierarchy[moduleSelector.id].levels[levelSelector.name]
                            && Object.keys(this.hierarchy[moduleSelector.id].levels[levelSelector.name].categories).length > 0) {
                            Object.keys(this.hierarchy[moduleSelector.id].levels[levelSelector.name].categories).forEach(categoryId => {
                                categories[categoryId] = true;
                            });
                        }
                    });
                }
                return categories;
            }, {});


        this.categorySelectionList.length = 0;
        Object.keys(uniqueCategories).forEach(categoryId => {
            // insert selector from index
            if (this.categoryIndex[categoryId]) {
                this.categorySelectionList.push(this.categoryIndex[categoryId].selector);
            }
        });

        this.categorySelectionList.sort((catA: CategorySelector, catB: CategorySelector) => {
            return this.categoryIndex[catA.id].sortIndex - this.categoryIndex[catB.id].sortIndex;
        });

        // Update subscribers to this list
        this.categorySelectionListSubject.next(this.categorySelectionList);
    }

    updateLearnableSelectors(): void {
        // array of selected module IDs
        const selectedModules =
            this.moduleSelectionList.reduce((list, moduleSelector) => {
                const selectedLevels = moduleSelector.levels.reduce((levels, nextLevel) => {
                    if (nextLevel.selected) levels.push(nextLevel.name);
                    return levels;
                }, []);
                if (moduleSelector.selected) {
                    list.push({ id: moduleSelector.id, levels: selectedLevels });
                }
                return list;
            }, []);

        // array of UNIQUE selected category IDs needed to generate list of learnables
        const uniqueCategories =
            this.categorySelectionList.reduce((list, categorySelector) => {
                if (categorySelector.selected) list[categorySelector.id] = true;
                return list;
            }, {});

        const selectedCategoryIds = Object.keys(uniqueCategories);

        //this.learnableSelectionList.length = 0;
        this.categoryLearnableSelectorList = {};

        selectedModules.forEach(moduleData => {
            const moduleId = moduleData.id;
            moduleData.levels.forEach(levelName => {

                selectedCategoryIds.forEach(categoryId => {
                    // TODO: Need to check for existence of hierarchy[id].categories???
                    // console.log(moduleData)
                    // console.log(moduleId)
                    // console.log(this.hierarchy)
                    // console.log(this.hierarchy[moduleId])
                    if (this.hierarchy[moduleId].levels != undefined
                        && this.hierarchy[moduleId].levels[levelName] != undefined
                        && this.hierarchy[moduleId].levels[levelName].categories != undefined
                        && this.hierarchy[moduleId].levels[levelName].categories[categoryId] !== undefined) {
                        
                        // if no entry for this category exists in the categoryLearnableSelectorList, then add one
                        if (!this.categoryLearnableSelectorList[categoryId]){
                            this.categoryLearnableSelectorList[categoryId] = new CategoryLearnableSelector();
                            this.categoryLearnableSelectorList[categoryId].categorySelector =  this.hierarchy[moduleId].levels[levelName].categories[categoryId].categorySummary.selector;
                        }
                        
                        this.hierarchy[moduleId].levels[levelName].categories[categoryId].learnables.forEach((learnable: Learnable) => { 
                            //this.learnableSelectionList.push(this.learnableIndex[learnable._id].selector);
                            this.categoryLearnableSelectorList[categoryId].learnableList.push(this.learnableIndex[learnable._id].selector)

                        });
                    }

                });
            });

        });

        // put the learnable Selectors in the list in the correct order.
        this.learnableSelectionList.length = 0;

        this.learnableSelectionList = selectedCategoryIds.reduce((list, catId) => {
            if (this.categoryLearnableSelectorList[catId]){
                this.categoryLearnableSelectorList[catId].learnableList.forEach( ls => {
                    //console.log(ls.name);
                    list.push(ls)
                })
            }
            return list
        }, []);

        // Update subscribers to this list
        this.learnableSelectionListSubject.next(this.learnableSelectionList);
        this.categoryLearnableSelectionListSubject.next(this.categoryLearnableSelectorList);
        // TODO: Define a sorting algorithm here for sorting learnables

    }

    //change these to provide a persistent observable or Subject to return and hten update them when the lists change...
    getModuleSelectionList(): Observable<ModuleSelector[]> {
        return this.moduleSelectionListSubject.asObservable();
    }

    getCategorySelectionList(): Observable<CategorySelector[]> {
        return this.categorySelectionListSubject.asObservable();
        //return of(this.categorySelectionList);
    }

    getLearnableSelectionList(): Observable<LearnableSelector[]> {
        return this.learnableSelectionListSubject.asObservable();
        //return of(this.learnableSelectionList);
    }

    getCategoryLearnableSelectionList(): Observable<CategoryLearnableSelector[]>{
        return this.categoryLearnableSelectionListSubject.asObservable();
    }

    moduleSelectionChanged(): void {
        // update the learnable list based on the current selections
        this.updateCategorySelectors();
        this.updateLearnableSelectors();

        //emit a change event to listeners
        this.moduleSelectionListSubject.next(this.moduleSelectionList);
        // this.updateSelectors();
    }

    categorySelectionChanged(): void {
        this.updateLearnableSelectors();
        this.categorySelectionListSubject.next(this.categorySelectionList)
    }

    selectLearnable(id: string): Learnable{
        if (this.selectedLearnable)
            this.learnableIndex[this.selectedLearnable._id].selector.selected = false;
        this.learnableIndex[id].selector.selected = true;
        this.selectedLearnable = this.learnableIndex[id].learnable;
        this.learnableSubject.next(this.selectedLearnable);
        this.sendLearnActionActivity(this.selectedLearnable, "opened");
        return this.selectedLearnable;
    }

    sendLearnActionActivity(l: Learnable, action: string) {
        let lName: string = 
            this.moduleIndex[l.module].module.name + "; " +
            this.categoryIndex[l.category].category.name + "; " +
            l.name
        
        this.activityService.submitActivity(
            new LearnActionActivity(
                this.serverService.getUserId().toString(),
                lName,
                action
            )
        )
    }

    getLearnableSubject(): Observable<Learnable> {
        return this.learnableSubject.asObservable();
    }

    getSelectedLearnableIndex(): number {
        let index = 0;
        for (index = 0; index < this.learnableSelectionList.length && !this.learnableSelectionList[index].selected; index++) { }
        return index;
    }

    goToPreviousLearnable(): Learnable {
        return this.selectLearnable(this.getPreviousLearnableId());
    }

    getPreviousLearnableId(): string {
        if (this.learnableSelectionList.length < 1) return;
        
        const index = this.getSelectedLearnableIndex();
        const newIndex = index > 0 ? index - 1 : this.learnableSelectionList.length - 1;
        
        return this.learnableSelectionList[newIndex].id;
    }

    goToNextLearnable(): Learnable {
        return this.selectLearnable(this.getNextLearnableId());
    }

    getNextLearnableId(): string {
        if (this.learnableSelectionList.length < 1) return;

        const index = this.getSelectedLearnableIndex();
        const newIndex = (index < (this.learnableSelectionList.length - 1)) ? index + 1 : 0;
        // console.log("Index = "+index+"; newIndex = "+newIndex)
        return this.learnableSelectionList[newIndex].id;
    }

    selectAllCategories(): void {
        Object.keys(this.categoryIndex).forEach(catId => {
            this.categoryIndex[catId].selector.selected = true;
        })
        this.categorySelectionChanged();
    }

}
