<div class="container">
    <div class="row">
        <div class="col-sm-8">
            <h1> Forgot Your Password? </h1>
            <form #f="ngForm" (ngSubmit)="f.valid && requestReset(f.value.email)">

                <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !inputEmail.valid }">
                  <div class="col">
                    <label for="email">Enter your Email Address</label>
                    <input type="email" class="form-control" name="email" [(ngModel)]="email" #inputEmail="ngModel" required email/>
                    <div *ngIf="f.submitted && !inputEmail.valid" class="help-block">Email Address is required</div>
                  </div>
                </div>
                    
                <div class="row mt-2">
                  <div class="col">
                    <button class="btn btn-primary" [disabled]="waiting || !f.valid">Reset Password</button> 
                    <img *ngIf="waiting" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                </div>

                <div *ngIf="message" class="alert alert-secondary row mt-2">
                  <div class="col">{{message}}</div>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-2">
                  <div class="col">{{error}}</div>
                </div>
            </form>
        </div>
    </div>
</div>