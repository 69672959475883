interface File {
    fileName: String;
    fileHash: String;
    url: String;
}

export class Answer {
    _id: String;
    answer: String;
    correct: boolean;
    image: File;

    constructor(_id: String, answer: String, correct: boolean, image?: File){
        this._id = _id;
        this.answer = answer;
        this.correct = correct;
        this.image = image ? image : null
    }

    static fromJSON(json: any) {
        let newA = new Answer(json._id, json.answer, json.correct, json.image)
        return newA;
    }
}