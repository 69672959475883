import { WebLoginActivity } from './../data-model/activity';
import { ActivityService } from './services/activity.service';
import { UpdatesNotificationService } from './services/updates-notification.service';
import { PwaInstallService } from './services/pwa-install.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { ModSetService } from './mod-set.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { NavbarService } from './navbar.service';
import { ServerService } from './server.service';
import { ModSet } from 'data-model/mod-set';
import { take, catchError, tap, delay, switchMap } from 'rxjs/operators';
import { AppConstants as constants} from 'app-constants';
import { concat, Observable, of, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    modSet: ModSet;

    navbarStates;

    isNavbarCollapsed: boolean = true;

    loadingContent: boolean = false;

    cacheMessage: string = "";

    imageCacheUrl: string = "";
    fileCacheName: string = "";
    imageTestUrl: string = ""

    installPwaData: {event?: any, os: string} = null;

    activityTimerSubscription: Subscription;
    
    constructor(
        private serverService: ServerService, 
        private navbarService: NavbarService,
        private router: Router,
        private modSetService: ModSetService,
        private modalService: NgbModal,
        private pwaInstallService: PwaInstallService,
        private updates: UpdatesNotificationService,
        private activityService: ActivityService ) {}

    ngOnInit(){
        // Debug code only
        this.imageTestUrl = constants.apiUrl + '/files/18544c31c88f320c51709f6c4de2d55d.jpg?height=400';
        //
        
        this.navbarService.getNavbarStates().subscribe(
            states => this.navbarStates = states
        )
        
        // TODO: Reconsider the following section: whether the two subscriptions are actually required and how to sequence them...

        // Set up this subscription to be persistent (respond to all changes over time) and unsubscribe later...
        this.serverService.loginStateObservable()
        .subscribe(loggedIn => {

            // Every time the login state changes

            if (loggedIn){
                this.activityService.submitActivity(new WebLoginActivity(this.serverService.getUserId().toString()))
                // if the state is now logged in:

                // update the User Level and then, once it's updated, update the modSet (WHY???)
//                console.log('ServerService: logged in, about to update user level')
                // this.serverService.updateUserLevel(() => {
                //     console.log("inside Callback for userUpdateLevel");
                    this.modSetService.getModSet()
                    .pipe(take(1))
                    .subscribe(modSet => {
//                        console.log("Got ModSet inside ServerService - updateUserLevel callback")
                        //console.log("app.component.ngOnInit() received modSet")
                        this.modSet = modSet
                    });
                // });

                // TODO: check with serverService whether the user is confirmed and if not, trigger a MOdal reminder to confirm or re-send

            } else {
                this.modSetService.onLogout();
                this.modSet = undefined;
            }
        })
        
        // One time initialization in case the user is already logged in at initialization
        if (this.serverService.isLoggedIn()){
            // this.serverService.updateUserLevel(() => {
                this.modSetService.getModSet()
                .pipe(take(1))
                .subscribe(modSet => {
                    //console.log("app.component.ngOnInit() received modSet")
                    this.modSet = modSet
                });
            // })
        }

        this.pwaInstallService.pwaInstallAvailable.subscribe({
            next: installPwaData => {this.installPwaData = installPwaData}
        })

        this.activityTimerSubscription = timer(0, constants.activitySendInterval)
        .subscribe(() => this.activityService.processQueue())
    }

    isLoggedIn(): boolean {
        return this.serverService.isLoggedIn();
    }

    isGrader(): boolean {
        return this.serverService.isGrader();
    }

    logout(): void{
        this.serverService.logout();
    }

    isAnAudioFile(file): boolean {
        return (
            ( file.mimeType && file.mimeType.includes('audio/mpeg') ) 
            || file.fileName.toLowerCase().includes('.mp3')
        )
    }

    isAnMp3File(file): boolean {
        return file.fileName.toLowerCase().includes('.gif');
    }

    isAnImageFile(file): boolean {
        return file.mimeType && file.mimeType.includes('image/') ;
    }

    openCacheModal(content){
        this.cacheMessage = "Download all Content?";
        this.modalService.open(content, {centered: true, size: "lg"})
        //this.cacheAllFiles();
    }
    
    getFileObservables(soundFiles: Array<any>): Array<Observable<any>> {
        if (! soundFiles) {
            return [of({})]
        }

        return soundFiles.map(file => {
            return this.serverService.getFile(file)
                .pipe(tap(()=> {
                    console.log("Getting File: " + file.fileName);
                    this.fileCacheName = file.fileName;
                    this.imageCacheUrl = "";
                }))
        })
    }



    getLearnableImageFileObservables(learnableImageFiles : Array<any>): Array<Observable<any>> {
        if (! learnableImageFiles) {
            return [of({})];
        }
        return learnableImageFiles.map(file => {
            return of({}).pipe(
                tap(() => {
                    console.log("Getting File: " + file.fileName);
                    this.fileCacheName = file.fileName;
                    this.imageCacheUrl = constants.apiUrl + "/files/"+ file.fileHash + "?height=400";
                }),
                delay(1000),
                catchError(error => {
                    console.log("Error setting cache image: ", error)
                    return of({})
                })
            )
        })
    }

    cacheAllFiles(): void{
        this.loadingContent = true;
        this.cacheMessage = "Downloading Content, Please wait"
        //get list of files from server service or modset service
        this.serverService.getFileList()
        .subscribe({
            next: fileSet => {
                let fileObservables = []

                // add observables for soundFiles
                fileObservables = fileObservables.concat(this.getFileObservables(fileSet.soundFiles))
                // add observables for learnableImagefiles
                fileObservables = fileObservables.concat(this.getLearnableImageFileObservables(fileSet.learnableImageFiles))
                // add observables for gifImageFiles
                fileObservables = fileObservables.concat(this.getFileObservables(fileSet.gifImageFiles))
                // add observables for questionImageFiles
                fileObservables = fileObservables.concat(this.getFileObservables(fileSet.questionImageFiles))
                // add observables for moduleImagefiles
                fileObservables = fileObservables.concat(this.getFileObservables(fileSet.moduleImageFiles))
                // add observables for categoryImageFiles
                fileObservables = fileObservables.concat(this.getFileObservables(fileSet.categoryImageFiles))

                let extraFiles = [
                    {
                        url:        "https://use.fontawesome.com/releases/v5.6.3/webfonts/fa-solid-900.woff2",
                        fileName:   "fa-solid-900.woff2"
                    },
                    {
                        url:        "https://use.fontawesome.com/releases/v5.6.3/webfonts/fa-solid-900.woff",
                        fileName:   "fa-solid-900.woff"
                    },
                    {
                        url:        "https://use.fontawesome.com/releases/v5.6.3/webfonts/fa-solid-900.ttf",
                        fileName:   "fa-solid-900.ttf"
                    }
                ]

                fileObservables = fileObservables.concat(this.getFileObservables(extraFiles))

            
                concat(...fileObservables)
                    .pipe(
                        catchError((response: HttpErrorResponse) => {
                            console.log('cacheAllFiles error: ', response)
                            return of({})
                        }))
                    .subscribe()
            },
            error: error => {
                this.loadingContent = false;
                this.cacheMessage = "Download Failed with Errors"
                console.error("Error downloading content: ", error)
            },
            complete: () => {
                this.loadingContent = false;
                this.cacheMessage = "Download Complete"
                this.fileCacheName = ""
            }
        })

        
    }

    canInstall(): boolean{
        return this.installPwaData !== null;
    }

    installPwa(): void{
        this.installPwaData.event.prompt();
        this.pwaInstallService.clearPwaPropmpt();
    }

    showInstallModal(content): void {
        this.modalService.open(content, {centered: true, size: "lg"})
    }

    isUpdateAvailable(): boolean {
        return this.updates.isNavVisible()
    }

    updateApp() {
        this.updates.activateUpdate();
    }

}
