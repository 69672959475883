<p class="h3">New Test Configuration</p>

<div class="row border-top border-bottom mt-2 pt-2">
  <!-- <div class="col">

  </div>
  <div class="col">

  </div> -->
  <div class="col">
    <div class="input-group mt-2 mt-sm-0">
      <!-- <label for="numQuestions"># Questions (max {{availableQuestions()}}):</label> -->
      <div class="input-group-text"># Questions (max {{availableQuestions()}})</div>
      <input 
        [disabled]="availableQuestions() < 1" 
        (change)="updateNumQuestions()"
        type="number" 
        class="form-control" 
        id="numQuestions" 
        [max]="availableQuestions()" 
        min="0"
        [(ngModel)]=numQuestions>

      <button 
        class="btn btn-success me-2"
        (click)="beginTest()"
        [disabled]="numQuestions < 1 || numQuestions > availableQuestions()">
        Start Test
      </button>
      <button
        class="btn btn-danger me-2"
        (click)="cancelNewTest()">
        Cancel
      </button>

    </div>
  </div>
</div>

<!-- Module and Category selections -->
<div class="row mt-4">
    <div
        class="col-12 col-sm-auto col-md-4">
        <module-selector-checkbox2></module-selector-checkbox2>

    </div>
    <div class="border-top col-12 d-block d-sm-none"></div>
    <div class="border-right col-auto d-none d-sm-block"></div>
    <div 
        class="col-12 col-sm-auto col-md-4 mt-3 mt-sm-0">
        <category-selector-checkbox></category-selector-checkbox>
    </div>

    
</div>


