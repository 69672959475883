import { ActivityService } from './../services/activity.service';
import { LearnStartActivity } from './../../data-model/activity';
import { AppConstants } from './../../app-constants';
import { ServerService } from './../server.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { ModSet } from 'data-model/mod-set';
import { Learnable } from 'data-model/learnable';
import { ModSetSelection } from 'data-model/modset-selection'

import { NavbarService } from 'app/navbar.service';

import { LearnableSelectionService } from 'app/learnable-selection.service';
import { LearnableSelector } from 'app/learnable-selector';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ModuleSelector } from 'app/module-selector';
import { LevelSelector } from 'app/level-selector';
import { map, take, filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ModuleSelection } from 'data-model/module-selection';

@Component({
	selector: 'learn-form-checkbox',
	templateUrl: './learn-form-checkbox.component.html',
	styleUrls: ['./learn-form-checkbox.component.scss'],
	providers: [ LearnableSelectionService ]
})
export class LearnFormCheckboxComponent implements OnInit, OnDestroy{
	modSet: ModSet;

    learnableDetailsActive = false; // true when the learnable details are the main content shown
    haveSomeLearnables = false; // true when the learnables list is empty (So we can show instructions)

    learnableSelectionList: LearnableSelector[];
    
    // used to compare different versions of the Learn Form

    selectedLearnable: Learnable;

    states;
    
    showExplore: boolean;

    myLevelSelection: ModSetSelection; // myLevel as passed in from 
    isMyLevelSelected = false; // true when selected Levels equal saved "My Level"
    
    // Subscribed from LearnableSelectionService to evaluate MyLevelSelection against
    moduleSelectionList: ModuleSelector[] = [];

    fragment: string;
    subscriptions: Array<any>;  //save subscriptions for later unsubscribe (cleanup)
    dataNeedsUpdate: boolean;
    routeDataForUpdate: { modSetSelection: ModSetSelection; myLevelSelection: ModSetSelection; };
    debugObservables: boolean = false;

	constructor(
		private learnableSelectionService: LearnableSelectionService,
        private navbarService: NavbarService,
        private route: ActivatedRoute,
        private serverService: ServerService,
        private router: Router,
        private titleService: Title,
        private activityService: ActivityService
	) { }

	ngOnInit() {
        if (this.debugObservables) console.log("Starting onInit");
        this.titleService.setTitle("Learn | " + AppConstants.appTitle)
        this.subscriptions = [];

        //update the navbar to indicate the curent state is "learn"
        this.subscriptions.push(this.navbarService.getNavbarStates()
        //do this only once then close the stream of observables
        .pipe(take(1))
        .subscribe(
			newStates => {
                this.states = newStates;
                Object.keys(this.states).forEach(state => this.states[state] = false);
                this.states.learn = true;        
            }
        ));        

        // Init data only a single time from the route snapshot
        this.initFromRouteData(
            <{ modSetSelection: ModSetSelection, myLevelSelection: ModSetSelection }> this.route.snapshot.data)

        // subscription to watch for query parameter changes
        this.subscriptions.push(
            this.route.queryParamMap.subscribe((queryParamMap)=>{
                if (this.debugObservables) console.log("** Observed Query Params **", queryParamMap)
                this.showExplore = queryParamMap.get('showExplore') == 'true';

                // if (queryParamMap.get('selectMyLevel')){
                //     this.selectMyLevel();
                // }
                // when re-using the component, if the route says there's new data to load, then load it

                if (queryParamMap.get("initData")){
                    this.dataNeedsUpdate = true;
                }
            })
        )    
        
        this.subscriptions.push(
            this.route.data.subscribe(
                (data: { modSetSelection: ModSetSelection, myLevelSelection: ModSetSelection }) => {
                    if (this.debugObservables) console.log("*** Observed Route Data ***", data)
                    this.routeDataForUpdate = data;
                }
            )
        )

        this.subscriptions.push(
            this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe((event)=>{
                //navigation completed so check if data needs updating
                if (this.debugObservables) console.log("** Navigation End Event triggered **", event)
                if (this.dataNeedsUpdate){
                    this.initFromRouteData(this.routeDataForUpdate);
                    this.dataNeedsUpdate = false;
                }
            })
        )

        //Capture Fragment and use to navigate to a specific learnable (Ongoing)
        this.subscriptions.push(
            this.route.fragment
            .pipe(map(fragment => fragment || undefined))
            .subscribe(fragment => {
                if (this.debugObservables) console.log("** Observed Route Fragment **", fragment)
                if (fragment) {
                    var regexResult = /^lid=(.*)/.exec(fragment)
                    //console.log("Fragment Regex Result",regexResult);

                    if (regexResult && regexResult.length > 1){
                        var learnableId = regexResult[1] || undefined;
                        //console.log("Fragment Learnable ID: " + learnableId);
                        if (learnableId){
                            this.showExplore = false;
                            this.learnableSelectionService.selectLearnable(learnableId);
                            //console.log("Selecting Learnable from hash: " + learnableId);
                        }
            
                    } else {
                        this.learnableDetailsActive = false;
                    }
                } else {
                    this.learnableDetailsActive = false;
                }
            })
        )
        

        // handle Route Selection changes (Ongoing)
        // Subscribe to learnable Selection List changes
        this.subscriptions.push(
            this.learnableSelectionService.getLearnableSelectionList()
            .subscribe(lsList => {
                if (this.debugObservables) console.log("** Observed Learnable Selection List **", lsList)    
                this.learnableSelectionList = lsList;
                this.haveSomeLearnables = lsList.length < 1 ? false : true
            })
        )
        
        // Start subscribing to ModuleSelectionList changes to check for MyLevel being selected
        this.subscriptions.push(
            this.learnableSelectionService.getModuleSelectionList().subscribe(
                (modSelList: ModuleSelector[]) => {
                    if (this.debugObservables) console.log("** Observed Module Selection List **", modSelList)
                    this.moduleSelectionList = modSelList;

                    this.updateMyLevelSelected();
                }
            )
        )

        // Subscribe to the currently Selected Learnable
        this.subscriptions.push(
            this.learnableSelectionService.getLearnableSubject().subscribe(
                newLearnable => {
                    if (this.debugObservables) console.log("** Observed Selected Learnable **", newLearnable)
                    
                    this.selectedLearnable = newLearnable
                    this.toggleLearnableDetails(true);
                }
            )
        )
    }

    private sendLearnStartActivity(mss: ModSetSelection){
        let categories: string[] = []
        let modules:    string[] = []
        
        if (mss) {
            let cl = mss.getCategories()
            if (cl.size > 0){
                categories = Array.from(cl.keys())
            }
            let ml = mss.getModules()
            if (ml.size > 0){
                modules = Array.from(ml.keys())
            }
        }
        
        this.activityService.submitActivity(
            new LearnStartActivity(
                this.serverService.getUserId().toString(), 
                categories, 
                modules
            )
        )
    }

    initFromRouteData(data: { modSetSelection: ModSetSelection, myLevelSelection: ModSetSelection }){
        if (this.debugObservables) console.log("*** Init from Route Data ***")
        if (data.modSetSelection){
            //console.log("Received incoming modSetSelections as data");
            this.learnableSelectionService.setSelectedMods(data.modSetSelection)
            // this.sendLearnStartActivity(data.modSetSelection);
        } 
        // else {
        //     this.sendLearnStartActivity(null)
        // }

        if (data.myLevelSelection){
            this.myLevelSelection = data.myLevelSelection;
            //console.log("Received incoming myLevelSelections as data")
            this.updateMyLevelSelected();
        }
    }

    ngOnDestroy(){
        if (this.debugObservables) console.log("starting OnDestroy")
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe())
    }
    
    selectMyLevel(): void {
        this.learnableSelectionService.setSelectedMods(this.myLevelSelection);
        this.learnableSelectionService.selectAllCategories();
    }

    // check the moduleSelectionList against the user's MyList and update the local 
    //  flag to indicate whether the list is curently selected.
    updateMyLevelSelected():void{
        //console.log("Updating isMyLevelSelected");
        if (this.moduleSelectionList && this.myLevelSelection){
            this.isMyLevelSelected = this.isMyLevelCurrentlySelected();
        } else {
            console.error("Could not evaluate myLevelSelected: one of the lists wasn't initialized");
        }
    }

    isMyLevelCurrentlySelected(): boolean{

        // PSEUDOCODE LOGIC
        // my level is selected if  all levels in selection are in moduleSelectionList and no others are.
        // if Number of modules in each list is equal AND
        //   module is in the list AND
        //   levels length is equal AND
        //   each level in one list is in the other list

        var result: boolean = true;

        if (! this.myLevelSelection){
            result = false;
            return result;
        }

        let myLevelModules: Map<String, ModuleSelection> = this.myLevelSelection.getModules();
        let selectedModulesCount = this.moduleSelectionList.reduce(
            (count:number, selector: ModuleSelector) => count + (selector.selected ? 1 : 0),
            0
        )

        if (myLevelModules.size !== selectedModulesCount) {
            result = false;
            return result;
        }

        this.moduleSelectionList.forEach((moduleSelector: ModuleSelector) => {
            if (moduleSelector.selected){
                if (! myLevelModules.get(moduleSelector.id)) {
                    
                    result = false;
                    return result;
                }
                var numSelected = moduleSelector.levels.reduce((count: number, levelSelector: LevelSelector) => {
                    if (levelSelector.selected) {
                        count++;
                        //while we're iterating over these, if the level is selected, then it needs to also be in myLevelSelection
                        if (myLevelModules.get(moduleSelector.id).levels.indexOf(levelSelector.name) == -1) {
                            // myLevelSelection doesn't include this level, so even if the count adds up, the result is now false.
                            result = false;
                            return result;
                        }
                    }
                    return count;
                },0)
                if (numSelected !== myLevelModules.get(moduleSelector.id).levels.length){
                    result = false;
                    return result;
                }                
            }
        })

        return result;
    }

    
    toggleLearnableDetails(state?: boolean){
        // toggle details on
        this.learnableDetailsActive = state === undefined ? ! this.learnableDetailsActive : state;
    }

    onPreviousButtonClick(){
        //console.log("Previous Clicked");
        var prevLearnable = this.learnableSelectionService.getPreviousLearnableId();
        // var prevLearnable = this.learnableSelectionService.goToPreviousLearnable();
        this.router.navigate(['/learn'], {fragment: 'lid='+prevLearnable})
    }

    onNextButtonClick(){
        //console.log("Next Clicked");
        var nextLearnable = this.learnableSelectionService.getNextLearnableId();
        // var nextLearnable = this.learnableSelectionService.goToNextLearnable();
        this.router.navigate(['/learn'], {fragment: 'lid='+nextLearnable})
    }

    loadMyLevel(){
        this.selectMyLevel();
    }



    saveMyLevel(){
        // capture current selections as a UserLevel and store locally
        var newMyLevelSelection: ModSetSelection = new ModSetSelection();

        this.moduleSelectionList.forEach((modSelector: ModuleSelector) => {
            if (modSelector.selected){
                newMyLevelSelection.addModuleSelection(new ModuleSelection(
                    modSelector.id,
                    modSelector.name,
                    this.getLevelsListFromLevelSelectorList(modSelector.levels)
                ))

            }
        })

        this.myLevelSelection = newMyLevelSelection;
        this.isMyLevelSelected = true;

        // send updated MyLevel to Server service to store.
        this.serverService.putUserLevel(this.myLevelSelection).subscribe(result => {
            if (this.debugObservables) console.log("updated server service")

            // TODO: Add popup "toast" message indicating successful save of My Level
        });
    }

    private getLevelsListFromLevelSelectorList(levels: Array<LevelSelector>): Array<string>{
        return levels.reduce(
            (list: string[], levelSelector: LevelSelector) => {
                if (levelSelector.selected) {
                    list.push(levelSelector.name)
                } 
                return list;
            },[]
        )
    }

    onBackButtonClick() {
        this.router.navigate(['/learn'])
        // this.router.navigate(['/learn'], {queryParamsHandling: 'preserve'})
        //this.learnableDetailsActive = false;
    }

}
