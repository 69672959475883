export class Grade {
    complete: boolean;
    memory: boolean;
    technique: boolean;
    comments: String;

    constructor(){
        this.complete = false;
        this.memory = false;
        this.technique = false;
    }
}
