import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from '../server.service';
import { AppConstants } from 'app-constants';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    model: any          = {};
    loading             = false;
    error: string       = '';
    message: string;
    emailLogin: boolean = false

    constructor(
        public serverService: ServerService,
        public router: Router,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.titleService.setTitle("Login | " + AppConstants.appTitle)
    }

    setMessage() {
        this.message = 'Logged ' + (this.serverService.isLoggedIn()) ? 'in' : 'out';
    }

    login(email: String, loginName: String, userPassword: String) {
        this.message = 'Trying to log in ...';
        this.loading = true;

        // basically, every time the serverService Observable emits a value, set the message and redirct if logged in.
        this.serverService.login(email, loginName, userPassword).subscribe(
            () => {
                this.setMessage();
                if (this.serverService.isLoggedIn()){

                    // set redirect from Auth Service to a redirect URL if it exists, or the root of it doesn't
                    const redirect = this.serverService.redirectUrl ? this.serverService.redirectUrl : '/';

                    // redirect the user
                    this.router.navigateByUrl(redirect);
                } else {
                    // any time a new value comes and it is false (login session ended), redirect back to login screen
                    this.message = 'Session expired.  Please log in again';
                    this.router.navigate(['/login']);
                }
            },

            // error function
            (err) => {
                this.error      = 'Login failed with Error: ' + err;
                this.message    = '';
            }
        );

        this.loading = false;
    }

    logout() {
        this.serverService.logout();
        this.setMessage();
    }

}
