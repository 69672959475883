import { Grade } from './grade';
import { Learnable } from 'data-model/learnable';
import { Module } from './module';
import { Category } from './category';
export class Gradeable {
    learnable: Learnable;
    module: Module;
    category: Category;
    grade: Grade;

    constructor(grade?: Grade, learnable?: Learnable, category?: Category, module?: Module ) {
        this.grade = grade || undefined;
        this.learnable = learnable || undefined;
        this.category = category || undefined;
        this.module = module || undefined;
    }
}
