import { ActivityService } from './services/activity.service';
import { UpdatesNotificationService } from './services/updates-notification.service';
import { PwaInstallService } from './services/pwa-install.service';
import { MyLevelSelectionResolverService } from 'app/my-level-selection-resolver.service';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// temporary - diagnostic only)
import { Router } from '@angular/router';

import { AppComponent } from './app.component';
import { TrainFormComponent } from './train-form/train-form.component';
import { LearnFormCheckboxComponent} from './learn-form-checkbox/learn-form-checkbox.component';
import { ServerService } from './server.service';
import { ModSetService } from './mod-set.service';
import { NavbarService } from './navbar.service';
import { ModuleSelectorCheckboxComponent } from './module-selector-checkbox/module-selector-checkbox.component';
import { ModuleSelectorCheckboxComponent2 } from './module-selector-checkbox2/module-selector-checkbox2.component';
import { CategorySelectorCheckboxComponent } from './category-selector-checkbox/category-selector-checkbox.component';
import { LearnableSelectorComponent } from './learnable-selector/learnable-selector.component';
import { LearnableSelectorTiledComponent } from './learnable-selector-tiled/learnable-selector-tiled.component';
import { LearnableSelectorGroupedComponent } from './learnable-selector-grouped/learnable-selector-grouped.component';
import { LearnableDetailComponent } from './learnable-detail/learnable-detail.component';
import { SafeUrlPipe } from './safe-url.pipe';

import { ModuleSelectorComponent } from 'app/module-selector/module-selector.component';
import { CategorySelectorComponent } from 'app/category-selector/category-selector.component';
import { LearnFormComponent } from 'app/learn-form/learn-form.component';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { MainComponent } from './main/main.component';
import { LoginRoutingModule } from './login-routing/login-routing.module';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth-guard.service';
import { LoginNameGuard } from './login-name-guard.service';
import { NgbModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterComponent } from './register/register.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AppConstants as constants } from '../app-constants';
import { SelectionResolverService } from './selection-resolver.service';
import { UserProfileEditComponent } from './user-profile-edit/user-profile-edit.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileViewComponent } from './user-profile-view/user-profile-view.component';
import { GradingMenuComponent } from './grading-menu/grading-menu.component';
import { GradeGuardService } from './grade-guard.service';
import { ModuleSelectorDropdownComponent } from './module-selector-dropdown/module-selector-dropdown.component';
import { ViewGradingsComponent } from './view-gradings/view-gradings.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ImagePreloadDirective } from './image-preload.directive';
import { UpdatesNotificationComponent } from './updates-notification/updates-notification.component';
import { TestComponent } from './test/test.component';
import { TestLandingComponent } from './test-landing/test-landing.component';
import { TestResultComponent } from './test-result/test-result.component';
import { TestNewComponent } from './test-new/test-new.component';
import { TestSessionComponent } from './test-session/test-session.component';
import { TestStudyComponent } from './test-study/test-study.component';
import { CreateLoginNameComponent } from './create-login-name/create-login-name.component';

export function myTokenGetter() {
    return localStorage.getItem('access_token');
}

const initializer = (pwaService: PwaInstallService) => () => pwaService.initPwaPrompt();

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LoginRoutingModule,
    AppRoutingModule,
    NgbModule,
    NgbCarouselModule,
    JwtModule.forRoot({
        config: {
            tokenGetter: myTokenGetter,
            allowedDomains: [constants.apiHost]
        },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true /*environment.production*/ })
  ],
  declarations: [
    AppComponent,
    TrainFormComponent,
    LearnFormCheckboxComponent,
    ModuleSelectorCheckboxComponent,
    ModuleSelectorCheckboxComponent2,
    CategorySelectorCheckboxComponent,
    LearnFormComponent,
    ModuleSelectorComponent,
    CategorySelectorComponent,
    LearnableSelectorComponent,
    LearnableSelectorTiledComponent,
    LearnableSelectorGroupedComponent,
    LearnableDetailComponent,
    SafeUrlPipe,
    MainComponent,
    LoginComponent,
    PageNotFoundComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RegisterComponent,
    ConfirmComponent,
    UserProfileEditComponent,
    UserProfileComponent,
    UserProfileViewComponent,
    GradingMenuComponent,
    ModuleSelectorDropdownComponent,
    ViewGradingsComponent,
    ImagePreloadDirective,
    UpdatesNotificationComponent,
    TestComponent,
    TestLandingComponent,
    TestResultComponent,
    TestNewComponent,
    TestSessionComponent,
    TestStudyComponent,
    CreateLoginNameComponent
    
  ],
  providers: [
    ServerService,
    ModSetService,
    NavbarService,
    AuthGuard,
    GradeGuardService,
    LoginNameGuard,
    SelectionResolverService,
    MyLevelSelectionResolverService,
    {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaInstallService], multi: true},
    UpdatesNotificationService,
    ActivityService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}
