
<!-- Narow/Mobile screen layout -->
<div *ngIf="selectedLearnable != undefined" class="card d-flex d-lg-none mt-0 mt-sm-4"> 
	
	<div class="card-header pb-1">
        <div class="row card-title align-items-center">
            <div class="col-12 col-sm-auto">
                <h6 class="text-muted">{{extraData.moduleName}} ({{selectedLearnable.level}})</h6>
                <h6 class="text-muted">{{extraData.categoryName}}</h6>
            </div>
            <div class="col-12 col-sm-auto">
                <div class="row align-items-center">
                    <div class="col-2 col-sm-auto">
                        <button 
                            *ngIf="selectedLearnable.sound != undefined && selectedLearnable.sound != 'null'"
                            (click)="playAudio()"
                            type="button" class="btn btn-sm btn-success">
                            <i class="fas fa-volume-up"></i>
                        </button>
                    </div>
                    <div class="col-10 col-sm-auto">
                        <h3 class="mb-0">
                            {{selectedLearnable.name}}
                        </h3> 
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body pt-1">
        <!-- Description -->
        <div class="list-group list-group-flush justify-content-center">
            
            <!-- Description -->
            <div class="list-group-item" style="padding-left: 0; padding-right: 0;">
                <span >{{selectedLearnable.description}}</span>
            </div>

            <!-- GIF -->
            <div *ngIf="selectedLearnable.gifImages && selectedLearnable.gifImages.length > 0"
              class="list-group-item "
              style="padding-left: 0; padding-right: 0;">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="text-center" *ngFor="let gif of selectedLearnable.gifImages">
                
                          <img style="max-height: 100%; max-width: 100%" src="{{gif.file.url}}">
                          
                          <p>{{gif.caption}}</p>    
                      </div>
                  </div>
              </div>
            </div>
    
            <!-- Video -->
            <div *ngIf="selectedLearnable.videos && selectedLearnable.videos.length > 0"
                class="list-group-item"
                style="padding-left: 0; padding-right: 0;">
                <div class="row justify-content-center">
                    <div 
                        *ngFor="let video of selectedLearnable.videos">  

                        <div class="card" (click)="openVideoModal(videoModal, video)">
                            <img class="card-img-top" src="{{video.imgUrl}}" default="{{VIDEO_PLACEHOLDER_URL}}">
                            <div class="card-body">
                                <h4 class="card-title text-center">Video</h4>
                                <p class="card-text text-center">{{video.caption}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- <div class="row justify-content-center top-buffer">
                    <div class="col-sm-10 col-sm-offset-1"
                    *ngFor="let video of selectedLearnable.videos">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" 
                        [src]="video.url | safeUrl" 
                        frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
                    </div>
                    <div class="row">
                        <p>{{video.caption}}</p>
                    </div> 
                    </div>
                </div> -->
              </div>
            
            <!-- Images -->
            <div class="list-group-item mx-auto"
                *ngIf="selectedLearnable.images && selectedLearnable.images.length > 0"
                style="padding-left: 0; padding-right: 0;">
                <div class="row justify-content-center">
                    <div style="max-width: none;">
                        <ngb-carousel [interval]="3000" [animation]="false">
                            <ng-template ngbSlide
                                *ngFor="let image of selectedLearnable.images">
                                    <img [src]='apiUrl + "/files/"+ image.file.fileHash +"?height=400"' 
                                        alt="{{image.caption}}"
                                        style="max-width: 100%; max-height: 100%;">
                                <div class="carousel-caption">
                                    <p>{{image.caption}}</p>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div> <!-- container -->
              </div>
            </div>

    </div>

</div>

<!-- Wide/desktop layout -->

<div *ngIf="selectedLearnable != undefined" class="card-group d-none d-lg-flex mt-sm-4">
    <div class="card"> 
        
        <div class="card-header pb-1">
            <div class="row card-title align-items-center">
                <div class="col-12 col-sm-auto">
                    <h6 class="text-muted">{{extraData.moduleName}} ({{selectedLearnable.level}})</h6>
                    <h6 class="text-muted">{{extraData.categoryName}}</h6>
                </div>
                <div class="col-12 col-sm-auto">
                    <div class="row align-items-center">
                        <div class="col-2 col-sm-auto">
                            <button 
                                *ngIf="selectedLearnable.sound != undefined && selectedLearnable.sound != 'null'"
                                (click)="playAudio()"
                                type="button" class="btn btn-sm btn-success">
                                <i class="fas fa-volume-up"></i>
                            </button>
                        </div>
                        <div class="col-10 col-sm-auto">
                            <h3 class="mb-0">
                                {{selectedLearnable.name}}
                            </h3> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body pt-1">
            <!-- Description -->
            <ul class="list-group list-group-flush">

                <!-- Description -->
                <li class="list-group-item">
                    <span >{{selectedLearnable.description}}</span>
                </li>
            </ul>
        </div>
    </div>
    <div 
        *ngIf="(selectedLearnable.gifImages && selectedLearnable.gifImages.length > 0) 
                || (selectedLearnable.videos && selectedLearnable.videos.length > 0) 
                || (selectedLearnable.images && selectedLearnable.images.length > 0)"
        class="card"> 
        
        <div class="card-body pt-1">
            <div class="list-group list-group-flush">
                <!-- GIF -->
                <div *ngIf="selectedLearnable.gifImages && selectedLearnable.gifImages.length > 0"
                  class="list-group-item ">
                  <div class="container">
                      <div class="row justify-content-center">
                          <div class="text-center" *ngFor="let gif of selectedLearnable.gifImages">
                              
                              <img style="max-height: 400px;" src="{{gif.file.url}}">
                              
                              <p>{{gif.caption}}</p>    
                          </div>
                      </div>
                  </div>
                </div>

                <!-- Video -->
                <div *ngIf="selectedLearnable.videos && selectedLearnable.videos.length > 0"
                  class="list-group-item">
                  <div class="row justify-content-center">
                      <div 
                          *ngFor="let video of selectedLearnable.videos">  

                          <div class="card" (click)="openVideoModal(videoModal, video)">
                              <img class="card-img-top" src="{{video.imgUrl}}" default="{{VIDEO_PLACEHOLDER_URL}}">
                              <div class="card-body">
                                  <h4 class="card-title text-center">Video</h4>
                                  <p class="card-text text-center">{{video.caption}}</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  
                  <!-- <div class="row justify-content-center top-buffer">
                      <div class="col-sm-10 col-sm-offset-1"
                      *ngFor="let video of selectedLearnable.videos">
                      <div class="embed-responsive embed-responsive-16by9">
                          <iframe class="embed-responsive-item" 
                          [src]="video.url | safeUrl" 
                          frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
                      </div>
                      <div class="row">
                          <p>{{video.caption}}</p>
                      </div> 
                      </div>
                  </div> -->
                </div>
                
                <!-- Images -->
                <div class="list-group-item mx-auto"
                    *ngIf="selectedLearnable.images && selectedLearnable.images.length > 0">
                    <div class="row justify-content-center">
                        <div class="col-auto">
                            <ngb-carousel [interval]="3000" [animation]="false">
                                <ng-template ngbSlide
                                    *ngFor="let image of selectedLearnable.images">
                                    <div class="d-flex justify-content-center">
                                        <img [src]='apiUrl + "/files/"+ image.file.fileHash +"?height=400"'
                                          alt="{{image.caption}}"
                                          style="max-width: 100%; max-height: 100%;">
                                    </div>
                                    <div class="carousel-caption">
                                        <p>{{image.caption}}</p>
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div> <!-- container -->
                  </div>
                </div>

        </div>

    </div>
</div>

<ng-template #videoModal let-modal>
  <div class="modal-body">
      <div class="ratio ratio-16x9">
          <iframe [src]="addAutoplay(modalVideo.url) | safeUrl" 
          frameborder="0" allow="encrypted-media" allow="autoplay" allowfullscreen></iframe>
      </div>
  </div>
  <div class="modal-body">
      <div class="row">
          <p class="col text-center">{{modalVideo.caption}}</p>
          <div class="col-auto">
              <button type="button" class="btn btn-sm btn-outline-dark" (click)="modal.close()">Close</button>
          </div>
      </div>
  </div>
</ng-template>