import { Answer } from './answer';

interface File {
    fileName: String;
    fileHash: String;
    url: String;
}

export class Question {
    question: string;
    answerList: Array<Answer>;
    image: File

    constructor(question: string, answerList: Array<Answer>, image?: File) {
        this.question = question;
        this.answerList = answerList;
        this.image = image ? image : null;
    }

    // public hasImage(): Boolean {
    //     return this.image !== undefined && this.image !== null 
    //         && this.image.url !== null && this.image.url.length > 0;
    // }

    // public getImageUrl(): String {
    //     if (this.image) {
    //         return this.image.url
    //     }
    //     return null;
    // }

    static fromJSON(json: any): Question {
        let newAnswerList: Array<Answer> = json.answerList.map(ans => Answer.fromJSON(ans));
        let newQ = new Question(json.question, newAnswerList, json.image)
        return newQ;
    }
}