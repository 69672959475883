<div>
    <div *ngIf="landingVisible()">
        <test-landing></test-landing>
    </div>
    
    <div *ngIf="testResultsVisible()">
        <test-result></test-result>
    </div>

    <div *ngIf="newTestVisible()">
        <test-new></test-new>
    </div>

    <div *ngIf="testSessionActive()">
        <test-session></test-session>
    </div>

    <div *ngIf="studyLearnableVisible()">
        <test-study></test-study>
    </div>

</div>