import { UserProfile } from './../../data-model/user-profile';
import { ServerService } from '../server.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AppConstants } from 'app-constants';
import { Title } from '@angular/platform-browser';



@Component({
    selector: 'app-user-profile-edit',
    templateUrl: './user-profile-edit.component.html',
    styleUrls: ['./user-profile-edit.component.scss']
})
export class UserProfileEditComponent implements OnInit {
    user: UserProfile = new UserProfile();
    waiting: boolean = false;
    error: string = '';
    message: string = '';
    nameMinLength = AppConstants.nameMinLength;
    nameMaxLength = AppConstants.nameMaxLength;

    constructor(
      public serverService: ServerService,
      public router: Router,
      private titleService: Title
    ) { }

    ngOnInit() {
        this.titleService.setTitle("Profile | " + AppConstants.appTitle)

        this.serverService.getUserProfile().subscribe(data =>{
            this.user = data;
        });
    }

    updateProfile(){
        console.error('WARNING: No User input checking has been implementd!!!  Fix Me!')
        this.message = 'Please wait, Updating Profile';
        this.error = '';
        this.waiting = true;
        this.serverService.putUserProfile(this.user)
        .pipe(
            delay(1000)
        )
        .subscribe(
            _ => {
                // console.log(_);
                this.message = 'Profile Updated Successfully'
                this.error = '';
                this.waiting = false;
                this.router.navigate(['/profile/view']);

            },
            error => {
                //no longer waiting, but value was not successfully changed so we need to set an error message and not nvaigate away.
                this.waiting = false;
                this.error = "Unable to save due to error: " + error;
            }
        );
        
    }

}
