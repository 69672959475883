import { UpdatesNotificationService } from './../services/updates-notification.service';
import { Component } from '@angular/core';

@Component({
    selector: 'updates-notification',
    templateUrl: './updates-notification.component.html',
    styleUrls: ['./updates-notification.component.scss'],
})
export class UpdatesNotificationComponent {

    constructor(public updates: UpdatesNotificationService) { }

}