<div class="row">
    <div class="col col-auto me-auto"><h5>Categories</h5><small>(Pick all that apply)</small></div>
    <div class="col ms-auto container">
        <button 
            class="btn btn-sm" 
            role="button" 
            [class.btn-secondary]="!allSelected" 
            [class.btn-primary]="allSelected" 
            (click)="toggleAllCategories()">
            All
    </button>
    </div>
</div>

<div 
    *ngIf="catList != undefined && catList.length > 0"
    class="checklist">
    <div 
        *ngFor="let cat of catList">
        <div
            class="custom-control custom-checkbox">
            <input 
                [(ngModel)]="cat.selected"  
                (change)="onSelectionChanged();" 
                type="checkbox" class="custom-control-input" id="categoryCheckbox-{{cat.name}}">
            <label class="custom-control-label" for="categoryCheckbox-{{cat.name}}">{{cat.name}}</label>
        </div>
    </div>
</div>


<div 
    *ngIf="catList == undefined || catList.length < 1 ">
    <b style="color: red;">Select Belts and Levels</b>
    
</div>
