<!-- <label>
    Debug Content?
    <input type="checkbox" 
        [(ngModel)]="contentDebug" [ngModelOptions]="{standalone: true}">
</label> -->

<!-- TODO: Remove the "BG-Success" class condition - it's there for test troubleshooting -->
<div *ngIf="currentQuestion != null">
    <h4
        [ngClass]="{'bg-success' : contentDebug && currentQuestion.isAnsweredCorrectly()}">
    Question: {{currentQuestion.getQuestionText()}}
</h4>

<div *ngIf="currentQuestion.hasQuestionImage()"
    class="img-50">
    
    <img class="img-fluid img-50"
        src="{{currentQuestion.getQuestionImageUrl()}}">
</div>

<form>
    <!-- ** test with Koho-Kaiten question in orange belt Ukemi ** -->
    <!-- for multi-answer questions: -->
    <div *ngIf="isCurrentQuestionMultiAnswer()">
        <p>Choose {{currentQuestion.getNumCorrectAnswers()}} answers</p>
        
        <div *ngFor="let answer of currentQuestion.getAnswerList(); let i = index"
            class="form-check border border-3 rounded-lg my-1">
            <label 
                [for]="'cb_' + answer._id"
                class="form-check-label py-2 ms-1 w-100"
                [ngClass]="{'bg-success' : contentDebug && answer.correct}">
                
                <input 
                    type="checkbox"
                    [id]="'cb_' + answer._id"
                    class="form-check-input"
                    [value]="answer._id"
                    [(ngModel)]="cbArray[i]">
                <div *ngIf="answerHasAnImage(answer)" 
                    class="row img-50">
                    <span class="col-8"><img class="img-fluid img-50" src="{{getAnswerImageUrl(answer)}}"/></span>
                    <span class="col-4">{{answer.answer}}</span>
                </div>
                <div *ngIf="!answerHasAnImage(answer)" 
                    class="row">
                    <span class="col-auto">{{answer.answer}}</span>
                </div>

            </label>
        </div>
    </div>

    <!-- for single-answer questions: -->
    <div *ngIf="!isCurrentQuestionMultiAnswer()" class="mt-1 mb-5">
        <p> Pick the correct answer</p>
        
        <div *ngFor="let answer of currentQuestion.getAnswerList(); let i = index"
            class="form-check border border-3 rounded-lg my-1">
            <label 
                [for]="'radio_' + answer._id"
                class="form-check-label py-2 ms-1 w-100"
                [ngClass]="{'bg-success' : contentDebug && answer.correct}">
                
                <input  
                    type="radio"
                    [id]="'radio_' + answer._id"
                    class="form-check-input"
                    name="selectedRadioAnswer"
                    [value]="answer._id"
                    [(ngModel)]="selectedRadioAnswer">
                <div *ngIf="answerHasAnImage(answer)" 
                    class="row img-50">
                    <span class="col-8"><img class="img-fluid img-50" src="{{getAnswerImageUrl(answer)}}"/></span>
                    <span class="col-4">{{answer.answer}}</span>
                </div>
                <div *ngIf="!answerHasAnImage(answer)" 
                    class="row">
                    <span class="col-auto">{{answer.answer}}</span>
                </div>

            </label>
        </div>

    </div>

    <button 
    class="btn btn-warning"
    (click)="endTest()">
    End Test
    </button>

    <ng-container *ngIf="haveMoreQuestions()">
        <button
            class="btn btn-success float-right"
            (click)="nextQuestion()">
            Next Question
        </button>
    </ng-container>



</form>