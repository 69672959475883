import { environment } from './../../environments/environment.prod';
import { AppConstants } from './../../app-constants';
import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PwaInstallService {
    public pwaInstallAvailable: BehaviorSubject<{event?: any, os: string}>;

    constructor(private platform: Platform) { 
        this.pwaInstallAvailable = new BehaviorSubject<{event?: any, os: string}>(null);
    }

    initPwaPrompt() {
        if (this.platform.ANDROID) {
            window.addEventListener('beforeinstallprompt', (event: any) => {
                event.preventDefault();
                let data = {
                    event: event,
                    os: 'android'
                }
                this.pwaInstallAvailable.next(data);
            });
        }
        if (this.platform.IOS) {
            const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
            if (!isInStandaloneMode) {
                let data = {
                    os: 'ios'
                }
                this.pwaInstallAvailable.next(data);
            }
        }
    }

    clearPwaPropmpt(){
        this.pwaInstallAvailable.next(null);
    }
}
