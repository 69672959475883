import { ServerService } from 'app/server.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { ScheduleItem } from 'data-model/schedule-item';
import { ScheduleData } from 'data-model/schedule-data';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ScheduleDataService {

    private scheduleUrl = "https://karatenewmarket.com/pricing-and-schedules/"

    constructor(
        private serverService: ServerService
    ) { }

    getSchedule(): Observable<Array<Array<ScheduleItem>>> {
        //return an observable of a data structure with schedule information
        
        return this.serverService.getSchedule().pipe(
          map(scheduleData => {
            let schedule = new Array<Array<ScheduleItem>>();
            
            scheduleData.forEach(row => {
              let r = []
              row.forEach(d => {
                r.push({
                  time: `${d.location ? d.location+": " : ""}${timeString(d)}`,
                  description: d.description,
                  age: d.details,
                })
              })
              schedule.push(r)
            })
            return schedule;
          })
        )
    }
}

function timeString(schedule: ScheduleData): string {
  let classTime = ""

  // Add leading zeroes to minutes values
  if (schedule.start) {
    classTime = `${schedule.start.hour}:${String(schedule.start.min).padStart(2, '0')}-${schedule.end.hour}:${String(schedule.end.min).padStart(2, '0')}`
  } else if (schedule.location !== "") {
    classTime = "NO CLASSES"
  }
  return classTime;
}
