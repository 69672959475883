import { Component, OnInit } from '@angular/core';
import { LearnableSelectionService } from '../learnable-selection.service';

import { ModuleSelector } from '../module-selector';

@Component({
  selector: 'module-selector',
  template: ''
})
export class ModuleSelectorComponent implements OnInit {
    modList: ModuleSelector[];
    levelSelectorVisible = {};
    allSelected = false;

    constructor(
        private learnableSelectionService: LearnableSelectionService) { }

    ngOnInit() {
        this.subscribeToModuleList();
    }

    subscribeToModuleList(): void {
    this.learnableSelectionService.getModuleSelectionList()
    .subscribe(modList => {
        this.modList = modList;
    });
    }

    onSelectionChanged():void {
    //todo: handle levels: if a module was toggled, adjust all levels.  
    // if a level was selected then make sure the module reflects the levels
        this.learnableSelectionService.moduleSelectionChanged();
    }

    onModuleSelectionChanged(mod: ModuleSelector, toggleAllLevels?: boolean):void {

    // if toggleAllLevels and module is selected then select all levels.  if deselected, de-select all levels
    if (toggleAllLevels){
        mod.levels.forEach(level => level.selected = mod.selected)
    }

    this.onSelectionChanged();
    }

    onLevelSelectionChanged(mod: ModuleSelector, event: Event):void {

    //disabling levels affecting modules
    //    var levelsSelected = mod.levels.reduce((count, level) => { return count + (level.selected ? 1 : 0) }, 0);

    //if any levels are selected, make sure module is selected  
    //if no levels are selected now, make sure module is deselected
    //    mod.selected = (levelsSelected > 0);

    // count the selected levels
    let levelsCount = mod.levels.reduce((prev,level) => {
        return prev + (level.selected ? 1 : 0);
    },0)

    if (levelsCount == mod.levels.length){
        // All are selected so set module to selected
        mod.selected = true;
    } else if (levelsCount > 0) {
        mod.selected = true;
    } else {
        mod.selected = false;
    }
    
    this.onSelectionChanged();
    event.stopPropagation();
    }

    mouseLeave(mod: ModuleSelector):void {
    this.levelSelectorVisible[mod.id] = false; 
    //console.log('Leaving Element '+mod.name);
    }

    mouseEnter(mod: ModuleSelector):void {
    this.levelSelectorVisible[mod.id] = true; 
    //console.log('Entering Element '+mod.name);
    }

    getLevelsListDisplayStyle(mod: ModuleSelector):string{
    return !this.levelSelectorVisible[mod.id] ? "inline" : "none";
    }

    getLevelSelectorDisplayStyle(mod: ModuleSelector): string {
    return this.levelSelectorVisible[mod.id] ? "inline" : "none";
    }

    logClickEvent(event):void{
    console.log(event);
    }

    toggleAllModules() {
    this.allSelected = !this.allSelected
    this.modList.forEach(modSelector => {
        if (modSelector.selected !== this.allSelected) {
        modSelector.selected = this.allSelected;
        this.onModuleSelectionChanged(modSelector, true);

        // todo: Select all levels within modules selected by this command since onModuleSelectionChanged does not
        }
    });
    }

}
