import { ResetPasswordComponent } from 'app/reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth-guard.service';
import { ServerService } from '../server.service';
import { LoginComponent } from '../login/login.component'
import { ForgotPasswordComponent } from 'app/forgot-password/forgot-password.component';
import { RegisterComponent } from 'app/register/register.component';
import { ConfirmComponent } from 'app/confirm/confirm.component';


const loginRoutes: Routes = [
    { 
        path: 'login', 
        component: LoginComponent
    },
    {
        path: 'forgot',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset/:token',
        component: ResetPasswordComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'confirm/:token',
        component: ConfirmComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(loginRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard,
        ServerService
    ]
})
export class LoginRoutingModule {}
