import { AppConstants as constants } from '../app-constants';

const appVersion: string    = "web v" + constants.version;
const curriculumId: string  = constants.curriculum;

export class Activity {
    activityType:	string;
    userID:         string;
    curriculumID:   string;
    timestamp:	    string;
    appVersion?:    string;

    constructor(at: string, uid: string) {
        this.activityType   = at;
        this.userID         = uid;
        this.curriculumID   = curriculumId;
        this.timestamp      = new Date().toISOString();
        this.appVersion     = appVersion;
    }
}

/* activity types previously supported and their required fields:
    all types:      "activityType","userID","curriculumID","timestamp","appVersion?"
    webLogin:       minFieldsRequired.concat([]),
    testStart:      minFieldsRequired.concat(["categories", "modules", "numQuestions"]),
    testEnd:        minFieldsRequired.concat(["categories", "modules", "numQuestions", "numQuestionsIncorrect", "incorrectQuestions"]),
    learnStart:     minFieldsRequired.concat(["categories", "modules"]),
    learnAction:    minFieldsRequired.concat(["learnable", "action"])
        actions included: opened, zoomImage, playVideo, zoomImage, playSound
*/

export class WebLoginActivity extends Activity {
    constructor(uid: string) {
        super("webLogin", uid);
    }
}

export class LearnStartActivity extends Activity {
    categories: string[];
    modules:    string[];
    constructor(uid: string, categories: string[], modules: string[]) {
        super("learnStart", uid)
        this.categories = categories;
        this.modules    = modules;
    }
}

export class LearnActionActivity extends Activity {
    learnable:  string;  // includes full path to Learnable: "Module name; Category name; Learnable name"
    action:     string;
    vidCaption: string;
    constructor(uid: string, learnable: string, action: string, vidCaption?: string) {
        super("learnAction", uid);
        this.learnable  = learnable;
        this.action     = action;
        this.vidCaption = vidCaption;
    }
}

export class TestStartActivity extends Activity {
    categories:     string[];
    modules:        string[];
    numQuestions:   number;
    constructor(uid: string, categories: string[], modules: string[], numQuestions: number) {
        super("testStart", uid)
        this.categories     = categories;
        this.modules        = modules;
        this.numQuestions   = numQuestions;
    }
}

export class TestEndActivity extends TestStartActivity {
    numQuestionsIncorrect:  number;
    incorrectQuestions:     string[];
    constructor(uid: string, categories: string[], modules: string[], numQuestions: number, 
        numQuestionsIncorrect: number, incorrectQuestions: string[]) {
            
        super(uid, categories, modules, numQuestions)
        this.activityType           = "testEnd";
        this.numQuestionsIncorrect  = numQuestionsIncorrect;
        this.incorrectQuestions     = incorrectQuestions;
    }
}