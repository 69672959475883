import { ModSetService } from './../mod-set.service';
import { Component, OnInit } from '@angular/core';
import { NavbarService } from 'app/navbar.service';
import { ServerService } from 'app/server.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { take, map, tap } from 'rxjs/operators';
import { Grading } from 'data-model/grading';
import { ModSet } from 'data-model/mod-set';
import { GradingSession } from 'data-model/gradingSession';
import { GradingSessionService } from 'app/grading-session.service';
import { Gradeable } from 'data-model/gradeable';
import { AppConstants as constants } from 'app-constants';

@Component({
  selector: 'app-view-gradings',
  templateUrl: './view-gradings.component.html',
  styleUrls: ['./view-gradings.component.scss']
})
export class ViewGradingsComponent implements OnInit {

    debugObservables: boolean = false;
    subscriptions: Array<any>;
    states;
    gradingList: Array<Grading> = []
    currentGrading: Grading = null;
    hover: boolean[] = []
    modSet: ModSet;
    gradingSession: GradingSession;
    gradeableDisplayList: Gradeable[][];
    

    constructor(
        private navbarService: NavbarService,
        private serverService: ServerService,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private modSetService: ModSetService

    ) { }

    ngOnInit() {
        this.titleService.setTitle("View Gradings | " + constants.appTitle);
        this.subscriptions = [];
        //update the navbar to indicate the curent state is "learn"
        this.subscriptions.push(this.navbarService.getNavbarStates()
        //do this only once then close the stream of observables
        .pipe(take(1))
        .subscribe(
            newStates => {
                this.states = newStates;
                Object.keys(this.states).forEach(state => this.states[state] = false);
                this.states.gradings = true;        
            }
        )); 

        this.subscriptions.push(
            this.serverService.getGradingListSubjectForStudent().subscribe(list =>{
                this.gradingList = list;
                this.subscriptions.push(
                    this.route.fragment
                    .pipe(map(fragment => fragment || undefined))
                    .subscribe(fragment => {
                        if (this.debugObservables) console.log("** Observed Route Fragment **", fragment)
                        if (fragment) {
                            var regexResult = /^gid=(.*)/.exec(fragment)
                            //console.log("Fragment Regex Result",regexResult);
        
                            if (regexResult && regexResult.length > 1){
                                var gradingId = regexResult[1] || undefined;
                                if (this.debugObservables) console.log("Fragment grading ID: " + gradingId);
                                if (gradingId){
                                    this.selectGradingById(gradingId);
                                }
                            } 
                        } else {
                            this.exitGrading();
                        }
                    })
                )
            })
        )
        this.serverService.updateGradingListForStudent();
    


    }

    selectGradingById(gId){
        let foundGrading: Grading = this.gradingList.find(grading => grading._id == gId)
        if (foundGrading){
            this.selectGrading(foundGrading);
        }
    }

    selectGrading(grading: Grading){
        this.currentGrading = grading;
        //update GradeableDisplayList
        this.modSetService.getModSet()
        .pipe(take(1))
        .subscribe(modSet => { 
            this.gradingSession = new GradingSession(grading, modSet);
            this.gradeableDisplayList = this.gradingSession.getDisplayList();
        })

    }

    navigateToGradingById(gId){
        this.router.navigate(['/gradings'],{fragment:"gid="+gId, queryParamsHandling: 'preserve'});
    }

    exitGrading(){
        this.currentGrading = null;
        this.gradingSession = null;
        this.gradeableDisplayList = null;
        this.router.navigate(['/gradings'])
    }

}
