<div class="container">
    <div class="row">
        <div class="col-sm-8">
            <h1> Reset Your Password </h1>
            <form #f="ngForm" (ngSubmit)="f.valid && inputConfirmation.value == inputPassword.value && setPassword(f.value.password)">

                <div class="row mt-2" >
                  <div class="col">
                    <label for="password">New password</label>
                    <input 
                      type="password" 
                      password
                      class="form-control" 
                      name="password" 
                      [(ngModel)]="password" 
                      #inputPassword="ngModel" 
                      required
                      minlength="{{passwordMinLength}}"
                      maxlength="{{passwordMaxLength}}"/>                    
                        
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col" >
                    <label for="confirmation">Confirm password</label>
                    <input 
                        type="password" 
                        password
                        class="form-control" 
                        name="confirmation" 
                        [(ngModel)]="confirmation" 
                        #inputConfirmation="ngModel" 
                        required
                        maxlength="{{passwordMaxLength}}"/>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col" >
                    <button 
                        class="btn btn-primary" 
                        [disabled]="waiting || !f.valid || inputConfirmation.value != inputPassword.value">
                            Change Password
                        </button> 
                    <img *ngIf="waiting" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>  
                </div>
                <div class="row, mt-1"
                  [ngClass]="{'has-error': inputPassword.errors && inputPassword.errors.minlength}">

                  <div class="col help-block">
                    Password must be between {{passwordMinLength}} and {{passwordMaxLength}} characters long.
                  </div>
                </div>
                <div class="row mt-1"
                    [ngClass]="{'has-error': inputConfirmation.value != inputPassword.value}" >
                    <div class="col help-block">
                      Passwords must match
                    </div>
                </div>
                <div *ngIf="message" class="alert alert-secondary row mt-2">
                  <div class="col">{{message}}</div>
                </div>
                <div *ngIf="error" class="alert alert-danger">
                  <div class="col">{{error}}</div>
                </div>
            </form>
        </div>
    </div>
</div>
