import { LearnableDetailComponent } from './../learnable-detail/learnable-detail.component';
import { LevelSelector } from 'app/level-selector';
import { ModuleSelectorComponent } from './../module-selector/module-selector.component';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ModuleSelector } from 'app/module-selector';
import { LearnableSelectionService } from 'app/learnable-selection.service';

@Component({
  selector: 'module-selector-dropdown,[module-selector-dropdown]',
  templateUrl: './module-selector-dropdown.component.html',
  styleUrls: ['./module-selector-dropdown.component.scss']
})
export class ModuleSelectorDropdownComponent extends ModuleSelectorComponent implements OnInit {
    @Output() moduleSelected = new EventEmitter<String>();
    
    constructor(private learnableSelectionService2: LearnableSelectionService){
        super(learnableSelectionService2)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selectModAndLower(0)
    }

    selectModAndLower(index: number){
      //iterate down the list
      this.modList.forEach((mod: ModuleSelector, i: number) => {
          // this.setModAndLevelState(mod,i <= index)
          mod.selected = i <= index;
          this.onModuleSelectionChanged(mod, true);
      })
      if (this.modList[index]) {
        this.moduleSelected.emit(this.modList[index].name);
      }
    }
    
    // setModAndLevelState(mod: ModuleSelector, state: boolean) {
    //     if (mod){
    //         mod.selected = state;
    //         mod.levels.forEach((level: LevelSelector) => {
    //             level.selected = state;
    //         })
    //     }
    // }
}
