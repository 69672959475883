import { TestQuestion } from 'data-model/test-question';
import { ModSetSelection } from 'data-model/modset-selection';
import { ModSet } from './mod-set';
import { Question } from './question';
import { Answer } from './answer';
import { Learnable } from './learnable';


export class TestConfiguration {


    //modules and categories selected
    private _modSetSelection: ModSetSelection;
    private _numQuestions: number;

    // constructor() {
    //     this.modSetSelection = new ModSetSelection();
    // }

    constructor(modSetSelection?: ModSetSelection, numQuestions?: number){
        this._modSetSelection = modSetSelection || new ModSetSelection();
        this._numQuestions = numQuestions || 0;
    }

    static fromJSON(json: any): TestConfiguration {
        let newMSS = ModSetSelection.fromJSON(json._modSetSelection);
        
        let newTC = new TestConfiguration(newMSS, json._numQuestions);

        return newTC;

    }

    getNumQuestions(): number {
        return this._numQuestions
    }

    setNumQuestions(numQuestions: number) {
        this._numQuestions = numQuestions;
    }

    getModSetSelection(): ModSetSelection {
        return this._modSetSelection;
    }

    setModSetSelection(updatedModSetSelection: ModSetSelection) {
        this._modSetSelection = updatedModSetSelection;
    }

}
