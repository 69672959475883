<!-- Delete Confirmation Modal -->
<ng-template #deleteGradingModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Grading?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>You are about to delete the grading: </p>
        <p><b>{{deleteGradingConfirmName}}</b></p>
        <p class="text-danger">WARNING: Deleting is permanent!</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger" (click)="deleteGrading(deleteGradingIndex); modal.close()">DELETE</button>
        <button type="button" class="btn btn-sm btn-warning" ngbAutofocus (click)="modal.close()">Cancel</button>
    </div>
</ng-template>

<!-- Grading Menu -->
<div
    *ngIf="!newGrading && !currentGradingSession">
    
    <div class="container-fluid ps-0 pe-0">    
        <nav class="navbar navbar-expand navbar-dark bg-primary">
            
            <div class="container col-9 d-inline-block d-md-flex">
                <div class="col-12 col-md-4">
                    <select  
                        class="form-control" 
                        [disabled]="studentList == null" 
                        [(ngModel)]="studentToGrade" 
                        (change)="studentNameOverride = studentToGrade.name || null" >
                        <option *ngIf="studentList == null" [ngValue]="null">-- Loading... --</option>
                        <option *ngIf="studentList !== null" [ngValue]="null">-- Select a Student --</option>
                        <option *ngFor="let s of studentList" [ngValue]="s">{{s.name}} - {{s.email}}</option>
                    </select>
                </div>
            
                <!-- <input type="text" class="form-control" [(ngModel)]="description" placeholder="--Description (required)--"> -->

                <div
                    class="col-12 col-md-4">
                    <input
                        class="form-control" 
                        type="text"
                        [disabled]="studentToGrade == null"
                        [(ngModel)]="studentNameOverride"
                        placeholder="Student Name"/>

                </div>

                <div 
                    class="col-12 col-md-4"
                    module-selector-dropdown
                    (moduleSelected)="onModuleSelected($event)">
                </div>
            </div>

            <div class="container col-3">
                <div class="col">
                    <button 
                        [disabled]="studentToGrade == null || !moduleSelected || newGrading"
                        class="btn btn-success" 
                        (click)="createAndSaveNewGrading()">
                        New Grading
                    </button>
                </div>
            </div>
        </nav>
    </div>
    <div class="container">
        <div class="row">
            <label 
                for="showActiveOnly"
                class="col-auto my-1 ms-0">
                <input 
                    name="showActiveOnly" 
                    id="showActiveOnly"
                    type="checkbox" 
                    [(ngModel)]="showActiveOnly">
                Active Users Only
            </label>
            <label 
                for="showComplete"
                class="col my-1 ms-4">
                <input 
                    name="showComplete"
                    id="showComplete" 
                    type="checkbox" 
                    [(ngModel)]="showComplete">
                Show Completed Gradings
            </label>
        </div>
    </div>
    <div class="container">
        <div 
            *ngIf="!gradingList || gradingList.length ==0"
            class="mt-1 align-items-center">
            <div class="grading-list-entry row align-items-center border rounded bg-light text-danger">
                <b class="ms-auto me-auto">You have no online gradings</b>
            </div>

        </div>
        <ng-container *ngFor="let grading of gradingList; let i = index">
            <div 
                *ngIf="showComplete === true || grading.complete === false"
                class="row mt-1 align-items-center">
                <div
                    class="grading-list-entry row col-10 col-sm-11 align-items-center border rounded bg-light"
                    [class.text-muted]="grading.grader != grader"
                    (mouseover)="hover[i]=true" (mouseleave)="hover[i]=false"
                    [class.hovering]="hover[i]"
                    (click)="!!navigateToGradingById(gradingList[i]._id); hover[i]=false">
                    <div class="col">
                        <p class="my-2">
                            <b>{{grading.studentName}}</b> - <b>{{grading.description}}</b> 
                        </p>
                    </div>

                    <div class="d-flex col-12 col-md-5 col-lg-5">
                        <span *ngIf="grading.grader == grader"
                            class="ms-auto my-2 me-2" [class.text-warning]="!grading.shared" [class.text-success]="grading.shared" >
                            <small><em>
                                {{grading.shared ? "Shared" : "Unshared"}}
                            </em></small>
                        </span>
                        <span class="my-2 me-2" [class.ms-auto]="grading.grader != grader">
                          <small><em>
                            {{grading.complete ? "Completed" : "In Progress"}}
                          </em></small>
                        </span>
                        <span class="my-2">{{grading.date ? grading.date.toLocaleDateString() : '-'}}</span>
                    </div>
                        
        
                </div>

                <!-- <div class="col-3">

                    <button class="btn btn-danger btn-sm float-right" (click)="!!deleteGrading(i)">
                        Del
                    </button>
                    <button class="btn btn-secondary btn-sm float-right" (click)="!!selectGrading(gradingList[i])">
                        Edit
                    </button>
                </div> -->
                <div class="col">
                    <button 
                        class="btn btn-danger btn-sm float-right" 
                        (click)="!!openDeleteConfirmModal(deleteGradingModal, grading.studentName + ' - ' + grading.description,i) ">
                        Del
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

</div>


<!-- New Grading -->
<!-- <div
    *ngIf="newGrading">

    
    <div class="container">
        <div class="row">
            <div class="col-auto">
                <button 
                    class="btn btn-success" 
                    [disabled]="learnablesSelected < 1"
                    (click)="saveNewGrading()">
                    Save & Create Grading
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                    <module-selector-checkbox2></module-selector-checkbox2>
            </div>
        </div>
    </div>


</div> -->



<!-- Active Grading Session -->
<div
    *ngIf="currentGradingSession">
    
    
    <div class="sticky-top grading-header">    
        <nav data-bs-theme="dark" class="navbar navbar-expand bg-primary">
            
            <span class="b text-light navbar-text mx-1">
                <b>{{currentGrading.studentName}} - {{currentGrading.description}}</b>
            </span>
        
    <!-- 
            <form class="inline-form">
                <label for="grading.comments">
                    Overall comments
                    <textarea 
                        name="grading.comments" 
                        type="text" 
                        class="form-control"
                        (input)="startSaveTimer()"
                        [(ngModel)]="currentGrading.comments"></textarea>
                </label>
            </form> -->
                
                
            <div class="ms-1 me-auto">
              <div class="btn-group btn-group-toggle ms-2 ms-sm-3 ms-md-5">
                <label class="mb-0 ms-3 navbar-text text-light" ngbButtonLabel>
                  <input type="checkbox" (input)="notesExpanded = !notesExpanded" ngbButton [(ngModel)]="notesExpanded">
                  Notes
                </label>
            
            
                <!-- Complete Checkbox -->
                <label class="mb-0 ms-3 text-light navbar-text">
                  <input name="grading.complete" type="checkbox" (input)="startSaveTimer()" [(ngModel)]="currentGrading.complete">
                  Complete
                </label>
            
                <!-- Shared Checkbox -->
                <label class="mb-0 ms-3 text-light navbar-text">
                  <input name="grading.shared" type="checkbox" [disabled]="!isAdmin && grader != currentGrading.grader"
                    (input)="startSaveTimer()" [(ngModel)]="currentGrading.shared">
                    Shared
                </label>
              </div>
              <!-- <button class="btn btn-sm ms-1 ms-sm-3 ms-md-5">Notes</button> -->
            </div>
                        <!-- </div>
                    </div>
                    <span class="input-group-text">Grading Complete</span>    
                </div>
            </form> -->

            <!-- Saving indicator -->
            <span 
                *ngIf="saving || needsSave"
                class="navbar-text text-danger">
                Saving...
            </span>
            <span 
                *ngIf="!saving && !needsSave"
                class="navbar-text">
                Saved
            </span>

            <!-- Exit Button -->
            <div class="ms-2 me-2">
              <button type="button"
                  class="btn btn-sm btn-warning" 
                  [disabled]="saving || needsSave" 
                  (click)="exitGrading()">
                  Exit
              </button>
            </div>
            <!-- <button (click)="printGrading()">print</button> -->

        </nav>
        <nav *ngIf="notesExpanded" class="navbar navbar-dark bg-primary">

            <textarea 
                [disabled]="currentGrading.complete"
                [name]="'grading.comments'" 
                type="text" 
                class="form-control form-control-sm"
                (input)="startSaveTimer();"
                rows="5"
                cols="50"
                placeholder="Comments on the overall grading"
                [(ngModel)]="currentGrading.comments"></textarea>            


        </nav>
    </div>

    <!-- <div class="list">
        <div *ngFor="outer array" class="section">
                <div class="header sticky-top" style="top: 56px;">header</div>
                <div *ngFor="inner array" class="element">{{}}</div>
            </div>
    </div> -->

    <div class="container-fluid">
        <div
            *ngFor='let module of gradeableDisplayList'>
            
            <div 
                *ngIf="module.length > 0" 
                class="row mb-1 p-1 bg-secondary text-light sticky-top grading-divider" 
                [class.notes-expanded]="notesExpanded">
                <p class="col h5">{{module[0].module.name}}</p>
            </div>

            <div 
                *ngFor="let gradeable of module; let i = index;"
                class="row mb-1 border">
                
                <div class="col-2 align-self-center small">
                    {{gradeable.category.name}}
                </div>
                <div class="col-5 self-align-center">
                    <strong>
                        <div class="btn-group btn-group-toggle">
                            <label 
                                [class.btn-dark]="!gradeable.grade.complete"
                                [class.btn-success]="gradeable.grade.complete"
                                ngbButtonLabel>
                                <input 
                                    [name]="'complete-'+i" 
                                    [disabled]="currentGrading.complete"
                                    type="checkbox" 
                                    (input)="(!currentGrading.complete) && startSaveTimer()"
                                    ngbButton
                                    [(ngModel)]="gradeable.grade.complete">
                                {{gradeable.learnable.name}}
                            </label>
                        </div>
                    </strong>
                </div>
                <div class="col-2">
                    <div class="btn-group btn-group-toggle">

                        <input
                            id="gradeable-{{gradeable.learnable._id}}-memory"
                            class="btn-check"
                            [disabled]="currentGrading.complete"
                            type="checkbox" 
                            autocomplete="off"
                            (input)="startSaveTimer()"
                            
                            [(ngModel)]="gradeable.grade.memory"> 
                        <label for="gradeable-{{gradeable.learnable._id}}-memory"
                            class="btn btn-sm btn-outline-danger">
                                M
                        </label>
                        <input 
                            id="gradeable-{{gradeable.learnable._id}}-technique"
                            class="btn-check"
                            [disabled]="currentGrading.complete"
                            type="checkbox" 
                            autocomplete="off"
                            (input)="startSaveTimer()"
                            [(ngModel)]="gradeable.grade.technique"> 
                        <label for="gradeable-{{gradeable.learnable._id}}-technique"
                            class="btn btn-sm btn-outline-danger">
                            T
                        </label>
                    </div>
                </div>
                <div class="col-3">
                <textarea 
                    [disabled]="currentGrading.complete"
                    [name]="'comment-'+i" 
                    type="text" 
                    class="form-control form-control-sm" 
                    (input)="startSaveTimer();"
                    rows="1"
                    placeholder="Add Comments"
                    [(ngModel)]="gradeable.grade.comments"></textarea>
                </div>
                
            </div>
        </div>
    </div>  
</div>