<div class="container">
  <div class="row pt-2">
    <div class="col">
      <h2>Create a Login Name</h2>
      <p class="alert alert-danger">Before you can do anything else in the app, you must create a login name for your account.</p>
      <p>A <b>Login Name</b> is your new username in the app and for Check-Ins at the Dojo.</p>
      <p>The format of a login name is &lt;firstname&gt;.&lt;last initial&gt; so for example, John Smith would be <b>john.s</b>.  It's important that you <b>use your real name</b> so that you can check-in and get gradings when you're eligible.</p>
      <p>If there is already a <b>john.s</b> in the dojo (maybe John Simpson) then there may be a number at the end like <b>john.s123</b></p>
    </div>
  </div>
  <div class="row, mt-2">
    <p><b>Please enter your first and last names and we will generate a loginName that is unique for you:</b></p>
    <form #f="ngForm" (ngSubmit)="f.valid && addLoginName(model)" novalidate>

      <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !fName.valid }">
        <div class="col">
          <label for="fName">First Name</label>
          <input type="text" class="form-control" name="fName" 
            [(ngModel)]="model.fName" #fName="ngModel" required 
            minlength="{{nameMinLength}}"
            maxlength="{{nameMaxLength}}"/>
        </div>
      </div>

      <div class="row mt-2" [ngClass]="{ 'has-error': f.submitted && !lName.valid }">
        <div class="col">
          <label for="lName">Last Name (or just initial)</label>
          <input type="text" class="form-control" name="lName" 
            [(ngModel)]="model.lName" #lName="ngModel" required 
            minlength="1"
            maxlength="{{nameMaxLength}}"/>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button [disabled]="loading || newLoginName || !(f.valid)" class="btn btn-primary">Create Login Name</button>    
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
      </div>
    </form>
  </div>

  <div [hidden]="!error">
    <div class="row mt-2 alert alert-danger">
      <div class="col">
        <span>
          I'm sorry, we could not create your Login Name. Please contact support and share the following details:
        </span>
        <span>{{error}}</span>
      </div>
    </div>
  </div>

  <div [hidden]="!newLoginName">
    <div class="row mt-2">
      <div class="col">
        <div class="alert alert-success">
          Your new Login Name is: <b>{{newLoginName}}</b>
        </div>
        <p>Remember this new Login Name and use it instead of your email to sign in to the app from now on.  If you have a password keeper, update your record with this as the new username.</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <button (click)="returnToApp()" class="btn btn-primary">Return to app</button>
      </div>
    </div>
  </div>
</div>