<div class="container">
    <div class="row">
        <span class="col col-md-4 align-self-center h2">Test Results</span>
        <span class="col-auto col-md-4 align-self-center">{{getTestCompletedDate()}}</span>

        <div class="col-auto col-md-4 text-right">
            <button
                class="btn btn-danger me-2"
                (click)="closeTestResults()">
                Close
            </button>
             <button
                class="btn btn-primary"
                (click)="startNewTest()">
                Re-Test
            </button>
        </div>

    </div>

    <div class="row">
        <span class="col col-md-4 h4">Questions Review</span>
        <span class="col-auto col-md-4 align-self-center">{{getPercentCorrect()}}% ({{getNumCorrectQuestions()}}/{{getNumQuestions()}})</span>
    </div>

    <div class="container">
        <div *ngFor="let question of getQuestions(); let i = index">
            <div class="row p-2 rounded"
                [ngClass]="{'correct-question': question.isAnsweredCorrectly(), 'incorrect-question': !question.isAnsweredCorrectly()}">
                <span class="col-6 col-md-8 col-lg-9 px-0">{{question.getQuestionText()}}</span>
                <small class="col-3 col-md-2 text-center text-sm px-0">{{question.isAnsweredCorrectly() ? "Correct" : "Incorrect"}}</small>
                <span class="col-3 col-md-2 col-lg-1 text-right px-0"><button class="btn btn-light btn-sm" (click)="setStudyLearnable(question.learnableId)" >Study</button></span>
                
            </div>
            <hr *ngIf="(i + 1) < getQuestions().length"
                class="my-1">
        </div>
    </div>
</div>