import { ModSetService } from './mod-set.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModSetSelection } from 'data-model/modset-selection';
import { Observable, of, EMPTY, combineLatest }  from 'rxjs';
import { map } from 'rxjs/operators';
import { ModSet } from 'data-model/mod-set';
import { ServerService } from './server.service';
import { ModuleSelection } from 'data-model/module-selection';
import { CategorySelection } from 'data-model/category-selection';

@Injectable({
  providedIn: 'root'
})
export class SelectionResolverService  {

    constructor(
        private modSetService: ModSetService,
        private router: Router,
        private serverService: ServerService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ModSetSelection>{

        // if the parameter "selectMyLevel" is present (and true) then we should return theeir specific module and category selections
        let requestedMyLevel = route.queryParamMap.get('selectMyLevel');

        if (requestedMyLevel) {
            // retrieve user level from server and return it (Ignores the requestedMods and requestedCats)
            return combineLatest([
                this.serverService.getUserLevel(),
                this.modSetService.getModSet()
            ])
            .pipe(
                map<[ModSetSelection, ModSet],ModSetSelection>(results => {
                    let [userLevelSelection, modSet] = results;
                    
                    modSet.categories.forEach(category => {
                        //add the category to the selection list
                        userLevelSelection.addCategorySelection({
                            _id: category._id,
                            name: category.name
                        })
                    })

                    return userLevelSelection;
                })
            )
        }

        // check for parameters and reject error conditions

        // receive requestedMods.  If it is not defined, set empty array as result.
        // an empty array should be interpreted as none specified, so select all        
        let requestedMods = route.queryParamMap.getAll('requestedMods') || [];


        // receive requestedCats.  If it is not definde, set empty array as result.
        // an empty array should be interpreted as none specified, so select all (like filtering)
        let requestedCats = route.queryParamMap.getAll('requestedCats') || [];


        return this.modSetService.getModSet().pipe(
            map<ModSet, ModSetSelection>(modSet => {
                let mss = new ModSetSelection();
                
                modSet.modules.forEach(module => {
                    if (requestedMods.length == 0 || requestedMods.indexOf(module._id) >= 0 ){
                        mss.addModuleSelection(new ModuleSelection(module._id, module.moduleID, module.levels))
                    }
                })

                modSet.categories.forEach(category => {
                    if (requestedCats.length == 0 || requestedCats.indexOf(category._id) >= 0){
                        mss.addCategorySelection(new CategorySelection(category._id, category.name))
                    }
                })

                return mss;
            })
        )
    }


}
