import { Title } from '@angular/platform-browser';
import { AppConstants } from './../../app-constants';
import { Component, OnInit } from '@angular/core';
import { ServerService } from 'app/server.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    passwordMinLength = AppConstants.passwordMinLength;
    passwordMaxLength = AppConstants.passwordMaxLength;
  
    waiting: boolean = false;
    error : string  = '';
    message: string = '';
    token: string = '';
    password: string;
    confirmation: string;

    email: string;
    loginName: string;

    constructor(
        private serverService: ServerService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title
        ) { }

    ngOnInit() {
        this.titleService.setTitle("Reset Password | " + AppConstants.appTitle)
        // confirm the token on the server before rendering the page?
        this.message = "Please wait - verifying token..."
        this.waiting = true;

        //retrieve the token from the route data
        this.route.paramMap
        .pipe(
            map((params: ParamMap) => params.get('token'))
        ).subscribe(token => {
            this.token = token;
            this.serverService.verifyResetToken(this.token)
            .subscribe(data => {
              if (data) {
                this.message = ''
                this.waiting = false;
                if (data.loginName){
                  this.loginName = data.loginName
                } else if (data.email) {
                  this.email = data.email
                }
              } 
            }, error => {
                this.message = ''
                this.error = 'Error: Token invalid or expired.'
                this.router.navigate(['/forgot'])   
            })
        });

    }

    setPassword(newPassword){
        this.waiting = true;
        this.serverService.setPassword(this.token, newPassword)
        .subscribe(result => {
            this.message = "Successfully changed password.  Redirecting to home page"
            //if successful, log in the user with their new password

            //delay a second to show the "Success" message before redirecting after login.
            new Promise(resolve => setTimeout(resolve, 2000))
            .then(() => {
                this.serverService.login(this.email, this.loginName, newPassword)
                .subscribe(result => {
                    this.router.navigate(['/'])
                })
    
            })
            //redirect to homepage

        })
    }

}
