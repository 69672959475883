import { LearnableSelectionService } from 'app/learnable-selection.service';
import { TestService } from './../services/test.service';
import { Component, OnInit } from '@angular/core';
import { TestQuestion } from 'data-model/test-question';
import { TestResult } from 'data-model/test-result';


@Component({
    selector: 'test-result',
    templateUrl: './test-result.component.html',
    styleUrls: ['./test-result.component.scss']
})
export class TestResultComponent implements OnInit {

    constructor(private testService: TestService) { }

    ngOnInit(): void {
    }

    getTestCompletedDate(): String {
        let dateCompleted: Date = this.testService.getSelectedTestResultDateCompleted();
        return  dateCompleted.toDateString();
    }
    
    getNumCorrectQuestions(): number {
        return this.testService.getSelectedTestResultsNumCorrect();
    }
        
    getNumQuestions():number {
        return this.testService.getSelectedTestResultsNumQuestions()
    }

    getPercentCorrect(): number {        
        return Math.round(this.testService.getSelectedTestResultsPercentCorrect());
    }

    closeTestResults(): void {
        this.testService.selectTestResults(null);

    }

    setStudyLearnable(learnableId: string): void {
        this.testService.setStudyLearnable(learnableId)
    }

    getQuestions(): Array<TestQuestion> {
        return this.testService.getSelectedTestResultsQuestions();
    }

    startNewTest(): void {
        this.testService.startNewTestWithSelectedResult();
    }

}
