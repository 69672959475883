import packageJson from '../package.json'
import { environment } from './environments/environment'

export class AppConstants{
    public static get passwordMinLength(): number       { return 4; }
    public static get passwordMaxLength(): number       { return 50; }
    public static get nameMinLength(): number           { return 2; }
    public static get nameMaxLength(): number           { return 50; }
    public static get version(): string                 { return packageJson.version; }
    public static get appTitle(): string                { return environment.appTitle; }
    public static get activitySendInterval(): number    { return 60000; }
    public static get production(): boolean             { return environment.production; }
    public static get apiHost(): string                 { return environment.apiHost; }
    public static get apiUrl(): string                  { return environment.apiUrl; }
    public static get curriculum(): string              { return environment.curriculum; }
    public static get curriculumName(): string          { return environment.curriculumName; }
    public static get envName(): string                 { return environment.envName; }
}