import { IndexedModSet } from './../../data-model/indexedModSet';
import { Observable } from 'rxjs';
import { LearnableSelector } from './../learnable-selector';
import { LearnableSelectionService } from './../learnable-selection.service';
import { TestService } from './../services/test.service';
import { Component, OnInit } from '@angular/core';
import { TestQuestion } from 'data-model/test-question';
import { ModSetService } from 'app/mod-set.service';
import { ModSet } from 'data-model/mod-set';
import { ModuleSelector } from 'app/module-selector';
import { ModSetSelection } from 'data-model/modset-selection';
import { CategorySelector } from 'app/category-selector';

@Component({
    selector: 'test-new',
    templateUrl: './test-new.component.html',
    styleUrls: ['./test-new.component.scss'],
    providers: [ LearnableSelectionService ]
})
export class TestNewComponent implements OnInit {

    learnableList: LearnableSelector[] = [];
    questionList: TestQuestion[] = [];
    indexedModSet: IndexedModSet;
    learnableSelectionServiceObservable: Observable<LearnableSelector[]> = null;
    moduleSelectionServiceObservable: Observable<ModuleSelector[]> = null;
    categorySelectionServiceObservable: Observable<CategorySelector[]> = null;
    numQuestions: number = 0;
    untouchedNumQuestions: boolean = true;

    constructor(
        private testService: TestService,
        private selectionService: LearnableSelectionService) { }

    ngOnInit(): void {
        let importedModSetSelection = this.testService.getNewTestModSetSelection();
        // if no categories are selected then select them all
        this.testService.ensureCategoriesAreSelected(importedModSetSelection);

        this.selectionService.setSelectedMods(importedModSetSelection);
        this.numQuestions = this.testService.getNewTestNumQuestions();

        if (this.learnableSelectionServiceObservable === null) {
            this.observeLearnableSelections();
        }

        if (this.moduleSelectionServiceObservable === null) {
            this.observeModuleSelections();
        }

        if (this.categorySelectionServiceObservable === null) {
            this.observeCategorySelections();
        }

    }

    observeLearnableSelections(): void {
        this.learnableSelectionServiceObservable = this.selectionService.getLearnableSelectionList();

        this.learnableSelectionServiceObservable
        .subscribe(learnableList => {
            this.learnableList = learnableList;
            this.testService.updateCandidateQuestionsFromLearnableList(learnableList);

            if (this.untouchedNumQuestions){
                this.numQuestions = this.availableQuestions();
            } else {
                let maxQuestions = this.availableQuestions();   
                if (this.numQuestions > maxQuestions) {
                    this.numQuestions = maxQuestions;
                    this.untouchedNumQuestions = true;
                }
            }
            this.testService.setNumQuestions(this.numQuestions);
        })
    }

    observeModuleSelections(): void {
        this.moduleSelectionServiceObservable = this.selectionService.getModuleSelectionList();

        this.moduleSelectionServiceObservable
        .subscribe(moduleList => {

            // let updatedModSetSelection = ModSetSelection.newModSetSelectorFromModuleSelections(moduleList);
            // this.testService.updateNewTestModSetSelection(updatedModSetSelection);
            this.testService.updateNewTestModules(moduleList);
        })
    }

    observeCategorySelections(): void {
        this.categorySelectionServiceObservable = this.selectionService.getCategorySelectionList();

        this.categorySelectionServiceObservable
        .subscribe(categoryList => {
            // console.log("test-new Updating category list to: " + JSON.stringify(categoryList))
            this.testService.updateNewTestCategories(categoryList);
        })
    }
    
    availableQuestions(): number {
        return this.testService.availableQuestions();
    }

    cancelNewTest(): void {
        this.testService.cleanNewTest();
    }

    updateNumQuestions(): void {
        this.untouchedNumQuestions = false;
        this.testService.setNumQuestions(this.numQuestions);
    }

    beginTest(): void {
        this.testService.startTestSession();
    }

}
