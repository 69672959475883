import { LearnableDetailComponent } from './../app/learnable-detail/learnable-detail.component';
import { Category } from './category';
import { Learnable } from './learnable';
import { ModSet } from './mod-set';
import { Module } from './module';


export class IndexedModSet {

    modSet: ModSet;

    learnableMap: Map<String, Learnable>;
    moduleMap: Map<String, Module>
    categoryMap: Map<String, Category>

    constructor(modSet: ModSet){
        this.modSet = modSet;
        this.updateIndex();
    }

    updateIndex(){
        this.learnableMap = new Map(this.modSet.learnables.map(learnable => [learnable._id, learnable]))
        this.moduleMap = new Map(this.modSet.modules.map(module => [module._id, module]))
        this.categoryMap = new Map(this.modSet.categories.map(category => [category._id, category]))
    }

    getLearnable(id: String){
        return this.learnableMap.get(id);
    }
    
}