import { Component, OnInit } from '@angular/core';
import { LearnableSelectionService } from '../learnable-selection.service';

import { ModuleSelector } from '../module-selector';
import { ModuleSelectorComponent } from 'app/module-selector/module-selector.component';

@Component({
  selector: 'module-selector-checkbox2',
  templateUrl: './module-selector-checkbox2.component.html',
  styleUrls: ['./module-selector-checkbox2.component.scss']
})
export class ModuleSelectorCheckboxComponent2 extends ModuleSelectorComponent implements OnInit {}
