<select
    class="form-control"
    (change)="selectModAndLower($event.currentTarget.value)">
    <option
        disabled
        value="-1"
    >
        --Select a Level--
    </option>
    <option
        *ngFor="let mod of modList; let i = index"
        value="{{i}}"
    >
    {{mod.name}}
    </option>
</select>
